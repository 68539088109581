import {OrganizationPositions, OrganizationRole, UsersOnOrganizationEntity} from './organizations';

export type User = {
  id: string;
  name: string;
  picture?: string;
  badgeColor: string;
  email: string;
  phone: string;
  password?: string;
  birthday?: number;
  orgRole?: string;
  deleted?: boolean;
  organizations?: {
    id: string,
    name: string,
    type: string
  }[],
  superAdmin: boolean,
  organizationRelationship?: UsersOnOrganizationEntity
}

export type UserRef = {
  userId: string;
  role: string;
}

export type NewUser = {
  name: string;
  email: string;
  phone: string;
  password: string;
  badgeColor: string;
  birthday: number;
  role: string;
  position: string;
}

export const badgeColors: string[] = [
  '#4169E1', // - azul
  '#E5EB75', // - amarelo claro
  '#800080', // - roxo escuro
  '#FF1493', // - rosa
  '#000080', // - azul marinho
  '#696969', // - cinza escuro
  '#D2691E', // - laranja
  '#008B8B', // - azul petroleo
  '#8A2BE2', // - lilas
  '#EB7D75', // - vermelho claro
  '#851236', // - burgundy
  '#87CEEB', // - azul claro
]

import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Output, signal,
  ViewChild
} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {OrganizationEntity} from '../../../../models/organizations';
import {OrganizationService} from '../../../services/organization.service';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-organizations-table',
  styleUrl: 'organizations-table.component.scss',
  templateUrl: 'organizations-table.component.html',
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, MatIcon, MatIconButton, MatTooltip],
  standalone: true
})
export class OrganizationsTable implements AfterViewInit{
  displayedColumns: string[] = ['name', 'description', 'type', 'domain', 'impersonate', 'open'];
  dataSource: MatTableDataSource<OrganizationEntity> = new MatTableDataSource();

  organizationService = inject(OrganizationService);

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  @Output() openDetails: EventEmitter<string> = new EventEmitter();

  searchValue = input.required<string>();
  tableRows = 10;
  extraRows = signal(0)

  organizationsService = inject(OrganizationService);

  constructor() {
    effect(() => {
      this.dataSource.data = this.organizationsService.organizations();
      this.applyFilter();
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = ((data: OrganizationEntity): boolean => {
      let r = true;
      if (this.searchValue() != '') {
        if ((data.name.toLowerCase().indexOf(this.searchValue()) === -1) &&
            (data.id.toLowerCase().indexOf(this.searchValue()) === -1)) {
          r = false;
        }
      }
      return r;
    });

    if (this.paginator != null && this.sort != null) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataSource.paginator.page.subscribe((page) => {
        const needExtra = (this.tableRows * (page.pageIndex + 1)) > page.length;
        this.extraRows.set(needExtra ? this.tableRows - (page.length % this.tableRows) : 0);
      })
    }
  }

  readonly dialog = inject(MatDialog);

  impersonateOrg(orgId: string) {
    this.organizationService.setIsImpersonating(orgId);
  }

  applyFilter() {
    this.dataSource.filter = this.searchValue();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
}


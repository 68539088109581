<div class="main">
  <app-person-badge [user]="history().user" [selected]="false"/>
  <div>
    <div class="who">
      <b>{{ history().user?.name ?? 'Sistema' }}</b>
      <div class="timestamp">{{ history().id | ulid | gm_date:'timestamp' }}</div>
    </div>
    @switch (type){
      @case('created') {
        <div class="data">Criou {{ history().data.parsedNew }}.</div>
      }
      @case('description'){
        @switch (changeType){
          @case('added') {
            <div class="data">Descrição definida para: <b class="description-history"
                      [innerHTML]="String(history().data.parsedNew)"></b></div>
          }
          @case('change') {
            <div class="data"> Descrição alterada de:
              <b class="description-history"
                 [innerHTML]="String(history().data.parsedOld)"></b>
              para <b class="description-history"
                      [innerHTML]="String(history().data.parsedNew)"></b></div>          }
          @case('removed') {
            <div class="data">Descrição <b class="description-history"
                                           [innerHTML]="String(history().data.parsedOld)"></b> removida.</div>
          }
        }
      }
      @case('delta'){
        @switch (changeType){
          @case('added') {
            <div class="data">Descrição definida para:
              <quill-view class="view" format="object" [content]="history().data.newData"></quill-view>
            </div>
          }
          @case('change') {
            <div class="data"> Descrição alterada de:
              <quill-view class="view" format="object" [content]="history().data.oldData"></quill-view>
              para
              <quill-view class="view" format="object" [content]="history().data.newData"></quill-view>
            </div>          }
          @case('removed') {
            <div class="data">Descrição
              <quill-view class="view" format="object" [content]="history().data.oldData"></quill-view>
              removida.</div>
          }
        }
      }
      @case('splInsert'){
        <div class="data">Inserir no SPL? definido para <b>{{ history().data.newData ? 'Sim' : 'Não' }}</b></div>
      }
      @case('document'){
        @if (history().data.oldData == null) {
          <div class="data">Documento <b #doc1 class="text-overflow" style="max-width: 400px"
                                         [matTooltip]="history().data.newData + ''" [matTooltipDisabled]="doc1.scrollWidth <= doc1.offsetWidth "
          >{{ history().data.newData }}</b> adicionado</div>
        } @else {
          <div class="data text-overflow" >Documento <b class="text-overflow" style="max-width: 400px">{{ history().data.oldData }}</b> removido.</div>
        }      }
      @case('time'){
        @switch (changeType){
          @case('added') {
            <div class="data">{{timeFields[history().data.dataChanged]}} definid{{oa}} para: <b>{{ String(history().assigneeChange?.newAssignee?.name | gm_date : 'hour-minute') }}</b></div>
          }
          @case('change') {
            <div class="data">{{timeFields[history().data.dataChanged]}} alterad{{oa}} de: <b>"{{ history().data.oldData! | gm_date : 'hour-minute'}}"</b> para <b>"{{ history().data.newData | gm_date : 'hour-minute'}}"</b></div>
          }
          @case('removed') {
            <div class="data">{{timeFields[history().data.dataChanged]}}: <b>"{{ history().data.oldData! | gm_date : 'hour-minute'}}"</b> removid{{oa}}.</div>
          }
        }
      }
      @case('status'){
        <div class="data">Status alterado de: <app-status-badge [nameId]="String(history().data.oldData!)" type="task" /> para <app-status-badge [nameId]="String(history().data.newData!)" type="task"/></div>

      }
      @case('date'){
        @switch (changeType){
          @case('added') {
            <div class="data">{{dateFields[history().data.dataChanged]}} definid{{oa}} para: <b>{{ String(history().data.newData | gm_date) }}</b></div>
          }
          @case('change') {
            <div class="data">{{dateFields[history().data.dataChanged]}} alterad{{oa}} de: <b>"{{ history().data.oldData! | gm_date}}"</b> para <b>"{{ history().data.newData | gm_date}}"</b></div>
          }
          @case('removed') {
            <div class="data">{{dateFields[history().data.dataChanged]}}: <b>"{{ history().data.oldData! | gm_date}}"</b> removid{{oa}}.</div>
          }
        }
      }
      @case('task_relationship'){
        @switch (changeType){
          @case('added') {
            <div class="data">Relacionado definido para: <app-related-contact-cs-la style="max-width: 380px;" [editable]="false" [selected]="String(history().data.newData)"/></div>
          }
          @case('change') {
            <div class="data">Relacionado alterado de: <app-related-contact-cs-la style="max-width: 170px;" [editable]="false" [selected]="String(history().data.oldData)"/> para <app-related-contact-cs-la style="max-width: 170px;" [editable]="false" [selected]="String(history().data.newData)"/></div>
          }
          @case('removed') {
            <div class="data">Relacionado: <app-related-contact-cs-la style="max-width: 380px;" [editable]="false" [selected]="String(history().data.oldData)"/> removid{{oa}}.</div>
          }
        }
      }
      @default {
        @switch (changeType){
          @case('added') {
            <div class="data">{{normalFields[history().data.dataChanged]}} definido para: <b>{{ String(history().data.parsedNew ?? history().data.newData ) }}</b></div>
          }
          @case('change') {
            <div class="data">{{normalFields[history().data.dataChanged]}} alterado de: <b>"{{ String(history().data.parsedOld ?? history().data.oldData!) }}"</b> para <b>"{{ String(history().data.parsedNew ?? history().data.newData) }}"</b></div>
          }
          @case('removed') {
            <div class="data">{{normalFields[history().data.dataChanged]}}: <b>"{{ String(history().data.parsedOld ?? history().data.oldData!) }}"</b> removido.</div>
          }
        }
      }
    }

<!--    @if (history().data.dataChanged === 'created') {-->
<!--      <div class="data">{{ history().data.entityType }} criado.</div>-->
<!--    }-->
<!--    @if (history().data.dataChanged === 'description') {-->
<!--      @if (history().data.oldData == null) {-->
<!--        <div class="data">Descrição definida para <b>{{ String(history().data.newData) }}</b></div>-->
<!--      } @else {-->
<!--        <div class="data">de:<b class="description-history" #tooltip="matTooltip"-->
<!--                                                   [matTooltip]="String(history().data.oldData)">{{ history().data.oldData }}</b>-->
<!--          para <b class="description-history"-->
<!--          [innerHTML]="String(history().data.newData)"></b></div>-->
<!--      }-->
<!--    }-->
  </div>
</div>

import { Directive, Input, OnDestroy, OnInit, Renderer2, ElementRef, Optional, Host, SkipSelf } from '@angular/core';
import { FormGroupDirective, AbstractControl } from '@angular/forms';
import {merge, Subscription} from 'rxjs';

@Directive({
  standalone: true,
  selector: '[highlightError]'
})
export class HighlightErrorDirective implements OnInit, OnDestroy {
  @Input('highlightError') controlName!: string | AbstractControl; // Name of the control to check
  @Input() errorClass: string = 'error-label';

  private control!: AbstractControl;
  private statusChangesSubscription!: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() @Host() @SkipSelf() private formGroupDirective: FormGroupDirective // Access the parent FormGroup
  ) {}

  ngOnInit(): void {
    if(typeof this.controlName !== 'string') {
      this.control = this.controlName;
    } else {
      if (!this.formGroupDirective) {
        throw new Error('highlightError directive must be used inside a formGroup directive context.');
      }

      const formGroup = this.formGroupDirective.form;

      if (!formGroup || !this.controlName) {
        throw new Error('highlightError directive requires a controlName within the FormGroup.');
      }

      this.control = formGroup.get(this.controlName)!;

      if (!this.control) {
        throw new Error(`Control with name ${this.controlName} not found in formGroup.`);
      }
    }
    this.statusChangesSubscription = merge(
      this.control.events,
      this.control.statusChanges
    )
      .subscribe(() => {
      this.updateHighlight();
    });

    this.updateHighlight(); // Initialize highlight state
  }

  private updateHighlight(): void {
    if (this.control.errors && this.control.touched && !this.control.disabled) {
      this.renderer.addClass(this.el.nativeElement, this.errorClass);
    } else {
      this.renderer.removeClass(this.el.nativeElement, this.errorClass);
    }
  }

  ngOnDestroy(): void {
    if (this.statusChangesSubscription) {
      this.statusChangesSubscription.unsubscribe();
    }
  }
}

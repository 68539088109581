import {inject, Injectable, signal} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {OrganizationEntity} from '../../models/organizations';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private url = environment.backendUrl;
  public organizations = signal<OrganizationEntity[]>([]);
  public currentOrganization = signal<OrganizationEntity | null>(null);
  currentOrganizationId: string = '01JDBB3NV8RCPJFQ11W6FXER1N';
  isImpersonating = false;

  http = inject(HttpClient);

  setIsImpersonating(orgId: string | null) {
    if (orgId == null) {
      this.isImpersonating = false;
      localStorage.removeItem('impersonatingOrgId');
    } else {
      this.isImpersonating = true;
      localStorage.setItem('impersonatingOrgId', orgId);
    }
    location.reload();
  }

  async setCurrentOrganizationId(id: string) {
    const impersonatingOrgId = localStorage.getItem('impersonatingOrgId');
    if (impersonatingOrgId != null) {
      this.currentOrganizationId = impersonatingOrgId;
      await this.getCurrentOrganization();
      this.isImpersonating = true;
      return;
    }
    this.currentOrganizationId = id;
    await this.getCurrentOrganization();
  }

  async getAllOrganizations() {
    const organizations = await lastValueFrom(this.http.get<OrganizationEntity[]>(this.url+`/organizations`));
    this.organizations.set(organizations);
    return organizations;
  }

  async getCurrentOrganization() {
    const org = await lastValueFrom(this.http.get<OrganizationEntity>(this.url+`/organizations/${this.currentOrganizationId}`));
    this.currentOrganization.set(org);
    return org;
  }

}

import {Pipe, PipeTransform} from '@angular/core';
import {decodeTime} from 'ulid';
import {OrganizationPositions, OrganizationRoles} from '../../models/organizations';

@Pipe({
  name: 'roles_positions'
})
export class RolePositionsPipe implements PipeTransform {

  transform(value?: string | null, ...args: any[]): string {
    switch (value) {
      case OrganizationRoles['ADMIN'].id:
        return OrganizationRoles['ADMIN'].name;
      case OrganizationRoles['NORMAL'].id:
        return OrganizationRoles['NORMAL'].name;
      case OrganizationPositions['VEREADOR'].id:
        return OrganizationPositions['VEREADOR'].name;
      case OrganizationPositions['SECRETARIO'].id:
        return OrganizationPositions['SECRETARIO'].name;
      case OrganizationPositions['CHEFE_DE_GABINETE'].id:
        return OrganizationPositions['CHEFE_DE_GABINETE'].name;
      case OrganizationPositions['ASSESSOR'].id:
        return OrganizationPositions['ASSESSOR'].name;
      case OrganizationPositions['ESTAGIARIO'].id:
        return OrganizationPositions['ESTAGIARIO'].name;
    }
    return OrganizationPositions['ASSESSOR'].name;
  }

}

import {Component, Input} from '@angular/core';
import {MatDivider} from '@angular/material/divider';
import {NgStyle} from '@angular/common';

@Component({
  selector: 'app-item-counter',
  imports: [
    NgStyle
  ],
  templateUrl: './item-counter.component.html',
  standalone: true,
  styleUrl: './item-counter.component.scss'
})
export class ItemCounterComponent {
  @Input({required: true}) title!: string;
  @Input({required: true}) value!: number;
  @Input({required: true}) titleColor!: string;
  @Input() cardColor!: string | null;
  @Input() textColor: string = '#000';

  parseWashedColor(color: string) {
    return color.slice(0,7)+'20';
  }

}

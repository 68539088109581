import {Component, effect, ElementRef, inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef,} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {StatusBadgeComponent} from '../../../common/status-badge/status-badge.component';
import {Overlay, OverlayModule} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {StatusBadgeListComponent} from '../../../common/status-badge-list/status-badge-list.component';
import {TaskService} from '../../../services/task.service';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRipple, NativeDateAdapter,} from '@angular/material/core';
import dayjs from 'dayjs';
import {Platform} from '@angular/cdk/platform';
import {CommentsComponent} from './comments/comments.component';
import {NgIf} from '@angular/common';
import {GmFile} from '../../../../models/gm-file';
import {FileHandlerComponent} from '../../../common/file-handler/file-handler.component';
import {DUDU_DATE_FORMATS} from '../../../utils';
import {MatSelectModule} from '@angular/material/select';
import {User} from '../../../../models/user';
import {Task} from '../../../../models/task';
import {HistoryService} from '../../../services/history.service';
import {HistoryComponent, HistoryData} from '../../../common/history/history.component';
import {DeleteConfirmationComponent} from '../../../common/delete-confirmation/delete-confirmation.component';
import {environment} from '../../../../environments/environment';
import {ContactService} from '../../../services/contact.service';
import {TaskRelation} from '../task-table/task-table.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {RelateContactCsComponent, RelatedEntity} from '../../../common/relate-contact-cs/relate-contact-cs.component';
import {CSService} from '../../../services/cs.service';
import {ButtonWLoaderComponent} from '../../../common/button-w-loader/button-w-loader.component';
import {HighlightErrorDirective} from '../../../common/error-label.directive';
import {FormFieldParserDirective} from '../../../common/formFieldParser.directive';
import {AssigneeSelectorComponent} from '../../../common/assignee-selector/assignee-selector.component';
import {AuthService} from '../../../services/auth.service';
import {ContentChange, QuillEditorComponent, QuillViewComponent} from 'ngx-quill';
import {convertDeltaToHtml, convertHtmlToDelta} from 'quill-converter';

export class CustomDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: any): string {
    const dayJsDate = dayjs(date);
    if ('hour' in displayFormat) {
      return dayJsDate.format('HH:mm')
    }
    return dayJsDate.format('DD/MM/YYYY')
  }

  override parse(value: any, parseFormat?: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
        return null;
      }
      return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    const a = isNaN(timestamp) ? null : new Date(timestamp);
    return a;
  }
}

export type CreateTaskData = {
  taskData?: Task,
  users: User[],
}

@Component({
  selector: 'app-create-task',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogContent,
    MatIcon,
    StatusBadgeComponent,
    ReactiveFormsModule,
    OverlayModule,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    ReactiveFormsModule,
    CommentsComponent,
    NgIf,
    MatRipple,
    FileHandlerComponent,
    MatSelectModule,
    HistoryComponent,
    DeleteConfirmationComponent,
    MatExpansionModule,
    RelateContactCsComponent,
    ButtonWLoaderComponent,
    HighlightErrorDirective,
    FormFieldParserDirective,
    AssigneeSelectorComponent,
    QuillEditorComponent,
    QuillViewComponent
  ],
  templateUrl: './create-task.component.html',
  styleUrl: './create-task.component.scss',
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    },
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class CreateTaskComponent implements OnInit {
  isEditingDescription = false;
  taskStatus: string = '';
  taskId?: string;
  taskAlias: string = 'TA-';
  taskDeadline?: number;
  taskName: string = '';
  taskAssignee?: User;
  isEditingName = false;
  isEditingRelated = false;
  isNew = false;

  relationships: TaskRelation[] = [];

  authService = inject(AuthService);

  readonly dateControl: FormControl<Date | undefined> = new FormControl();

  readonly dialogRef = inject(MatDialogRef<CreateTaskComponent, { changed: boolean, isTemp: boolean }>);
  readonly data = inject<CreateTaskData>(MAT_DIALOG_DATA);
  readonly taskService = inject(TaskService);
  readonly historyService = inject(HistoryService);
  readonly contactService = inject(ContactService);
  readonly csService = inject(CSService);

  historyList: HistoryData[] = [];

  files: GmFile[] = [];

  constructor(private overlay: Overlay,) {
    effect(() => {
      this.updateHistory();
    });
  }

  @ViewChild('status') status!: ElementRef;

  uploadFileList?: File[];

  html = '';
  delta: any;

  async selectAssignee(event: string) {
    this.taskAssignee = this.data.users.find(user => user.id === event);
    if (this.data.taskData == null) {
      return;
    }
    if (!this.isNew) {
      await this.taskService.updateTaskAssignee(this.data.taskData.id, event);
    }
  }

  fb = inject(FormBuilder);

  formGroup = this.fb.group({
    nameControl: new FormControl('', [Validators.required]),
  })

  richControl: FormControl = new FormControl([]);

  commentTextChanges(event: ContentChange) {
    this.html = event.text ?? '';
    this.delta = event.content;
  }

  showOverlay() {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.status)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: 146,
      }]);
    const overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
    const ref = overlayRef.attach(new ComponentPortal(StatusBadgeListComponent));
    ref.instance.statusList.set(['open', 'inProgress', 'done']);
    ref.instance.closePanel.subscribe(async (value) => {
      overlayRef.detach();
      if (this.taskStatus !== value) {
        this.taskStatus = value;
        if (this.data.taskData == null) {
          return;
        }
        await this.taskService.updateTaskStatus(this.data.taskData.id, value);
        this.updateHistory();
      }
    });
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }

  ngOnInit() {
    this.dateControl.valueChanges.subscribe(async (value) => {
      this.taskDeadline = dayjs(value).unix();
      if (this.data.taskData == null) {
        return;
      }
      if (this.data.taskData.deadline !== dayjs(value).unix()) {
        await this.taskService.updateTaskDeadline(this.data.taskData.id, value ? dayjs(value).unix() : null);
      }
    });
    if (this.data?.taskData?.id != null) {
      this.isNew = false;
      this.taskId = this.data.taskData.id;
      this.historyService.getHistoryForRelation(this.data.taskData.id);

      if (this.data?.taskData.name != null) {
        this.formGroup.get('nameControl')?.setValue(this.data.taskData.name);
        this.taskName = this.data.taskData.name;
      }
      if (this.data?.taskData.alias != null) {
        this.taskAlias = this.data.taskData.alias;
      }
      if (this.data?.taskData.description != null) {
        this.html = this.data.taskData.description;
        if (this.data.taskData.delta == null) {
          this.richControl.setValue(convertHtmlToDelta(this.data.taskData.description));
        }
      }
      if (this.data?.taskData.delta != null) {
        this.delta = this.data.taskData.delta;
        this.richControl.setValue(this.data.taskData.delta);
      }
      if (this.data?.taskData.status != null) {
        this.taskStatus = this.data.taskData.status;
      }
      if (this.data?.taskData.deadline != null) {
        this.dateControl.setValue(dayjs.unix(this.data.taskData.deadline).toDate());
      }
      if (this.data?.taskData.assigneeUserId != null) {
        this.taskAssignee = this.data.users.find(user => user.id === this.data.taskData?.assigneeUserId);
      }
      this.relationships = this.data.taskData?.relationships?.map((rel) => {
        if (rel.relationshipType === 'contacts') {
          return {
            ...rel,
            contact: this.contactService.contactList().find((contact) => contact.id === rel.relationshipId)
          };
        } else {
          return {
            ...rel,
            cs: this.csService.csList().find((cs) => cs.id === rel.relationshipId)
          };
        }
      }) ?? [];
    } else {
      this.isNew = true;
      this.taskStatus = 'open';
      this.isEditingDescription = true;
      this.isEditingName = true;
    }
    this.dialogRef.disableClose = this.isNew;
  }

  updateHistory() {
    if (this.data.taskData?.id == null) {
      return;
    }
    this.historyList = [];
    this.historyService.historyList().get(this.data.taskData!.id!)?.forEach(value => {
      let assigneeChange;
      if (value.data.dataChanged === 'assigneeUserId') {
        assigneeChange = {
          oldAssignee: this.data.users.find(user => user.id === value.data.oldData),
          newAssignee: this.data.users.find(user => user.id === value.data.newData)
        }
      }
      this.historyList.push({
        ...value,
        user: this.data.users.find(user => user.id === value.data.callerUserId),
        assigneeChange
      });
    });
  }

  deleteTask() {
    if (this.taskId) {
      this.taskService.deleteTask(this.taskId);
    }
    this.dialogRef.close({deleteId: this.taskId});
  }

  isLoading = false;

  cancelDescriptionEdit() {
    this.isEditingDescription = false;
    this.html = this.data.taskData?.description ?? '';
    this.delta = this.data.taskData?.delta;
  }

  async save() {
    if (this.isLoading) {
      return
    }
    this.isLoading = true;
    if (this.isNew) {
      this.formGroup.markAllAsTouched();
      if (!this.formGroup.valid) {
        return;
      }
      const task = await this.taskService.createTask(
        this.formGroup.get('nameControl')?.value ?? '',
        this.html,
        this.taskStatus,
        this.taskAssignee?.id,
        this.relationships.length ? this.relationships[0].relationshipId : undefined,
        this.relationships.length ? this.relationships[0].relationshipType : undefined,
        this.taskDeadline,
        this.delta);

      this.taskId = task.id;
      this.taskAlias = task.alias;
      this.taskName = this.formGroup.get('nameControl')?.value ?? '';
      this.isNew = false;
      this.isEditingName = false;
      this.isEditingDescription = false;
      this.isEditingRelated = false;
      this.data.taskData = {
        id: task.id,
        name: this.formGroup.get('nameControl')?.value ?? '',
        description: convertDeltaToHtml(this.delta),
        status: this.taskStatus,
        assigneeUserId: this.taskAssignee?.id,
        deadline: this.taskDeadline,
        alias: task.alias,
        relationships: this.relationships
      };
    } else {
      if (this.taskId) {
        this.isEditingDescription = false;
        await this.taskService.updateTaskDelta(this.taskId, this.delta);
        this.historyService.getHistoryForRelation(this.taskId);
        this.isLoading = false;
      }
    }
  }

  async saveName() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      return;
    }
    this.isEditingName = false;
    this.taskName = this.formGroup.get('nameControl')?.value ?? '';
    if (this.taskId) {
      await this.taskService.updateTaskName(this.taskId, this.formGroup.get('nameControl')?.value ?? '');
      this.historyService.getHistoryForRelation(this.taskId);
    }
  }

  async cancelSaveName() {
    this.isEditingName = false;
    this.formGroup.get('nameControl')?.setValue(this.taskName);
  }

  relatedChange(event: RelatedEntity | null) {
    this.isEditingRelated = false;
    if (event === null) {
      this.relationships = [];
    } else if (event.type === 'contacts') {
      this.relationships = [
        {
          relationshipType: event.type,
          relationshipId: event.id,
          contact: this.contactService.contactList().find((contact) => contact.id === event.id) ?? undefined,
          timestamp: dayjs().unix(),
          taskId: this.taskId!
        }
      ]
    } else {
      this.relationships = [
        {
          relationshipType: event.type,
          relationshipId: event.id,
          cs: this.csService.csList().find((cs) => cs.id === event.id) ?? undefined,
          timestamp: dayjs().unix(),
          taskId: this.taskId!
        }
      ]
    }
    if (!this.isNew && event) {
      this.taskService.updateRelationship(this.taskId!, event?.id, event?.type).then(() => {
        this.historyService.getHistoryForRelation(this.taskId!);
      })
    } else if (!this.isNew && !event) {
      this.taskService.clearRelationships(this.taskId!).then(() => {
        this.historyService.getHistoryForRelation(this.taskId!);
      })
    }
  }

  close() {
    this.dialogRef.close({changed: true, isTemp: false});
  }

  fileUpload(event: any) {
    const files: FileList = event.target.files;
    this.uploadFileList = Array.from(files);
  }

  async fileUploadFinished(file: File) {
    this.historyService.getHistoryForRelation(this.taskId!);

  }

  async fileRemoved(file: GmFile) {
    this.historyService.getHistoryForRelation(this.taskId!);

  }


  protected readonly environment = environment;
  protected readonly window = window;
}

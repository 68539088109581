import {Component, computed, input, signal} from '@angular/core';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'app-title-elipisis-last-word',
  imports: [
    MatIcon
  ],
  templateUrl: './title-elipisis-last-word.component.html',
  standalone: true,
  styleUrl: './title-elipisis-last-word.component.scss'
})
export class TitleElipisisLastWordComponent {
  title = input.required<string>();
  icon = input<string>();
  firstPart = computed(()=>{
    return this.title().split(' ').slice(0, -1).join(' ');
  })
  lastWord = computed(()=>{
    return  ' '+ this.title().split(' ').slice(-1).join(' ');
  })
}

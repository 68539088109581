<div class="list-container">
  @for (comment of rows; track comment) {
    @if (type() === 'comment') {
      <div class="list-item">
        <div class="medium-circle animate" style="margin-right: 0.875rem"></div>
        <div class="item-text" style="width: 200px;">
          <div style="display: flex; gap: 5px;flex-direction: row">
            <div class="animate medium-line w-50"></div>
            <div class="animate medium-line w-50"></div>
          </div>
          <div class="big-square animate"></div>
        </div>
      </div>
    } @else if (type() === 'task') {
      <div class="task-item">
        <div class="medium-circle animate" style="margin-right: 0.875rem"></div>
        <div class="item-text" style="width: 200px;">
          <div class="med-square animate"></div>
        </div>
      </div>
    } @else if (type() === 'tramite') {
      <div class="task-item">
<!--        <div class="medium-circle animate" style="margin-right: 0.875rem"></div>-->
        <div class="item-text" style="width: 200px;">
          <div class="med-square animate"></div>
        </div>
      </div>
    }
  }

</div>

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-logo',
  imports: [],
  templateUrl: './logo.component.svg',
  standalone: true,
  styleUrl: './logo.component.scss'
})
export class LogoComponent {
  @Input() fillColor = 'var(--color-secondary)';
}

import {Component, effect, EventEmitter, inject, input, OnInit, Output} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatRipple} from '@angular/material/core';
import {
  uploadBytes,
  ref,
  Storage,
  list,
  deleteObject,
  getDownloadURL,
  getMetadata, listAll,
} from '@angular/fire/storage';
import {GmFile} from '../../../models/gm-file';
import {GMDatePipe} from '../date.pipe';
import dayjs from 'dayjs';
import {AuthService} from '../../services/auth.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {HistoryService} from '../../services/history.service';

@Component({
  selector: 'app-file-handler',
  imports: [
    MatIconButton,
    MatIcon,
    MatRipple,
    GMDatePipe,
    MatProgressSpinner
  ],
  templateUrl: './file-handler.component.html',
  standalone: true,
  styleUrl: './file-handler.component.scss'
})
export class FileHandlerComponent {
  referenceId = input.required<string>();
  entity = input.required<string>();
  uploadFileList = input<File[]>();
  @Output() fileUploaded = new EventEmitter<File>();
  @Output() fileRemoved = new EventEmitter<GmFile>();
  loading = true;

  userId?:string;
  authService = inject(AuthService);
  historyService = inject(HistoryService);
  path?: string;

  files: GmFile[] = [];

  removeFile(file: GmFile) {
    deleteObject(ref(this.storage, `${this.path}/${file.id}`)).then(async() => {
      this.files = this.files.filter((f) => f.id !== file.id);
      await this.historyService.postDocumentHistory(this.entity(), this.referenceId(), file.name, this.userId ?? '', 'delete');
      this.fileRemoved.emit(file);
    } );
  }

  async download(file: GmFile) {
    const url = await getDownloadURL(ref(this.storage, `${this.path}/${file.id}`));
    window.open(url, '_blank');
  }

  storage = inject(Storage);

  constructor() {
    effect(() => {
      this.userId = this.authService.loggedUser()?.id;
      const files = this.uploadFileList();
      this.files.push(...(files ?? []).map((file) => {
        return {
          id: file.name,
          name: file.name,
          url: '', timestamp: new Date().toISOString()
        }
      }));
      if (files && this.userId) {
        files.forEach(async (file) => {
          uploadBytes(ref(this.storage, `${this.path}/${file.name}`), await file.arrayBuffer(),
            { customMetadata:{ uploadedBy: this.userId!}})
          .then(async() => {
            await this.historyService.postDocumentHistory(this.entity(), this.referenceId(), file.name, this.userId ?? '', 'create');
            this.fileUploaded.emit(file);
            console.log('file uploaded');
          });
        });
      }
      this.sort();
      if(this.authService.currentOrgId && !this.path && this.referenceId()){
        this.path = `${this.authService.currentOrgId}/${this.entity()}/${this.referenceId()}`;
        this.initialize()
      }
    });
  }

  sort(){
    this.files = this.files.sort((a, b) => {
      return dayjs(a.timestamp).isBefore(dayjs(b.timestamp)) ? 1 : -1;
    })
  }

  initialize() {
    console.log(this.path);
    listAll(ref(this.storage, `${this.path}`)).then(async(res) => {
      console.log('here');

      if(res.items.length === 0) {
        this.loading = false;
      }
      const promises = [];
      for (const item of res.items) {
        promises.push(
          getMetadata(item).then((metadata) => {
            return {
              id: item.name,
              name: item.name,
              url: '',
              timestamp: metadata.timeCreated
            }
          })
        )
      }
      const result = await Promise.all(promises);
      result.sort((a, b)=> (a.timestamp < b.timestamp) ? 1 : -1);
      this.files = result;
      this.loading = false;
    }).catch((err) => {
      console.error(1111, err);
    }   );
  }
  protected readonly dayjs = dayjs;
}

<div class="main">
  <div class="header">
    <app-delete-confirmation
      [hidden]="!(event && event.id) || authService.loggedUser()?.organizationRelationship?.position === 'ESTAGIARIO'"
      [deleting]="deleteLoading()"
      (deleteConfirmation)="deleteEvent()"/>
    <H3>
      {{ event ? 'Editar Evento' : 'Novo Evento' }}
    </H3>

    <button autofocus class="close" mat-icon-button (click)="close()">
      <mat-icon class="close-icon" fontIcon="close"></mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="info">
      <form [formGroup]="formGroup">
        <div class="info-line">
          <div class="info-label" [highlightError]="'name'">Nome:*</div>
          <mat-form-field style="width: 332px;" subscriptSizing="dynamic">
            <input matInput formControlName="name">
          </mat-form-field>
        </div>
        <div class="info-line">
          <div class="info-label" [highlightError]="'date'">Data:*</div>
          <mat-form-field style="width: 181px;">
            <input formMask="date" matInput [matDatepicker]="picker" formControlName="date">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <div class="all-day">
            <mat-checkbox [formControl]="formGroup.controls.allDay">Dia inteiro</mat-checkbox>
          </div>
        </div>
        <div class="info-line time">
          <div class="info-label" [highlightError]="'startTime'">Horário:*</div>
          @if (!formGroup.controls.allDay.value) {
            <mat-form-field style="width: 145px;">
              <input matInput formMask="time"
                     [matTimepicker]="timepicker1"
                     formControlName="startTime">
              <mat-timepicker [scrollToMiddleTimepicker]="32" interval="15min" #timepicker1/>
              <mat-timepicker-toggle [for]="timepicker1" matSuffix/>
            </mat-form-field>
          } @else {
            <mat-form-field style="width: 145px;">
              <input matInput disabled>
            </mat-form-field>
          }
          <div class="until">
            <span class="title" [highlightError]="'endTime'"> até </span>
          </div>
          @if (!formGroup.controls.allDay.value) {
            <mat-form-field style="width: 145px;">
              <input matInput formMask="time"
                     [matTimepicker]="timepicker2"
                     [matTimepickerMin]="minHour"
                     formControlName="endTime">
              <mat-timepicker [scrollToMiddleTimepicker]="minHour ? 0 : 32" interval="15min" #timepicker2/>
              @if (!formGroup.controls.allDay.value) {
                <mat-timepicker-toggle [for]="timepicker2" matSuffix/>
              }
            </mat-form-field>
          } @else {
            <mat-form-field style="width: 145px;">
              <input matInput disabled>
            </mat-form-field>
          }
        </div>
        <div class="info-line">
          <div class="info-label">Local:</div>
          <mat-form-field style="width:332px;" subscriptSizing="dynamic">
            <input matInput formControlName="location">
          </mat-form-field>
        </div>
        <div class="rich-text-holder">
          <div class="rich-header">
            <div class="info-label">Descrição:</div>
          </div>
          <quill-editor placeholder="Escreva aqui" class="rich-text" [formControl]="richControl" format="object" (onContentChanged)="commentTextChanges($event)"></quill-editor>
        </div>
      </form>
    </div>
    <mat-divider vertical="true"/>
    <div class="invitees-vertical">
      <div class="invitees">
        <div class="invitees-container">
          <mat-form-field style="width: 100%">
            <mat-label>Convidados:</mat-label>
            <input type="text"
                   matInput
                   [formControl]="inviteeSearch"
                   [matAutocomplete]="autoGroup">
            <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="updateInvitees($event)"
                              [displayWith]="displayFn">
              @for (user of users | async; track user.id) {
                <div [matTooltip]="whoCanAddTooltip" [matTooltipDisabled]="user.organizationRelationship?.position !== 'VEREADOR'">
                  <mat-option
                    [value]="user"
                    [disabled]="whoCanAddTooltip && user.organizationRelationship?.position === 'VEREADOR'">
                    <div style=" width:100%; display: flex;align-items: center; gap: 10px;">
                      <app-person-badge [user]="user" [selected]="false"/>
                      {{ user.name }}
                    </div>
                  </mat-option>
                </div>
              }
            </mat-autocomplete>
          </mat-form-field>
          <div class="invitee-list-container">
            @for (invitee of invitees; track invitee.id) {
              <div class="invitee-list">
                <app-person-badge [user]="invitee" [selected]="false"/>
                <span style="width: 225px; overflow: hidden;
                     text-overflow: ellipsis;">{{ invitee.name }}</span>
                <div [ngClass]="{disabled: this.invitees.length === 1}" class="remove"
                     (click)="removeInvitee(invitee.id)">
                  <mat-icon fontIcon="close"></mat-icon>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: row-reverse; margin: 10px; gap: 5px">
        <div class="save" (click)="save()">
          @if (saving()) {
            <mat-spinner diameter="25" color="black"></mat-spinner>
          } @else {
            <div>Salvar</div>
          }
        </div>
        <div class="cancel" (click)="cancel()">
          <div>Cancelar</div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Task} from '../../models/task';
import {Comment} from '../../models/comment';
import {environment} from '../../environments/environment';
import {CommentData} from '../tasks/tasks/create-task/comments/comments.component';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient) {

  }

  getAllTaskComments(taskId: string) {
    return lastValueFrom(this.http.get<Comment[]>(`${environment.backendUrl}/tasks/${taskId}/comments`));
  }

  getAllCsComments(csId: string) {
    return lastValueFrom(this.http.get<Comment[]>(`${environment.backendUrl}/customer_services/${csId}/comments`));
  }

  insertTaskComment(taskId: string, comment: CommentData) {
    return lastValueFrom(this.http.post<{id: string}>(`${environment.backendUrl}/tasks/${taskId}/comments`, {message: comment.message, delta: comment.delta, metadata: ''}));
  }

  insertCsComment(csId: string, comment: CommentData) {
    return lastValueFrom(this.http.post<{id: string}>(`${environment.backendUrl}/customer_services/${csId}/comments`, {message: comment.message, delta: comment.delta, metadata: ''}));
  }
}

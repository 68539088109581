import {inject, Injectable, OnDestroy, signal} from '@angular/core';
import {User} from '../../models/user';
import {lastValueFrom, startWith, Subject, takeUntil, timer} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LoginResponse, RefreshResponse} from '../../models/auth';
import {environment} from '../../environments/environment';
import {UserService} from './user.service';
import {Auth, signOut} from '@angular/fire/auth';
import {RealtimeService} from './realtime.service';
import {OrganizationService} from './organization.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy{
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private realtime: RealtimeService
  ) {
    const authData = localStorage.getItem('auth-data');
    if(authData) {
      this.authData = JSON.parse(authData);
      // this.postAuth();
    }
    this.realtime.users.pipe(startWith(null))
      .subscribe(()=>{
        if(this.loggedUser()?.id){
          this.userService.getUser(this.loggedUser()!.id).then((user) => {
            this.loggedUser.set(user);
          })
        }
      })
  }

  auth = inject(Auth);
  organizationService = inject(OrganizationService);

  loggedUser = signal<User|null|undefined>(null);
  currentOrgId = '01JDBB3NV8RCPJFQ11W6FXER1N'
  orgType = 'individual'
  loggedIn:boolean = false;
  private authData: LoginResponse|null = null;
  private cancelReAuth$ = new Subject<void>(); // Subject to signal cancellation

  getToken() {
    return this.authData?.idToken;
  }

  private saveToLocalStorage(data:LoginResponse) {
    localStorage.setItem('auth-data', JSON.stringify(data));
  }

  async login(data: {email: string, password: string}) {
    this.authData  = await lastValueFrom(this.http.post<LoginResponse>(environment.backendUrl +`/auth/login`, data));
    // this.postAuth();
  }

  async resetPassword(data: {email: string}) {
    await lastValueFrom(this.http.post(environment.backendUrl +`/auth/reset-password`, data));
    return;
  }

  private async refresh() {
    const data = {refreshToken: this.authData?.refreshToken};
    const refreshData = await lastValueFrom(this.http.post<RefreshResponse>(environment.backendUrl +`/auth/refresh`, data));
    this.authData!.refreshToken = refreshData.refreshToken;
    this.authData!.idToken = refreshData.idToken;
    this.authData!.expiresIn = refreshData.expiresIn;
    // this.postAuth();
  }

  async postAuth(userId?: string, decodedToken?: any) {
    if(!userId) return;
    this.loggedIn = true;
    if(decodedToken){
      this.currentOrgId = Array.isArray(decodedToken.organizations) ? decodedToken.organizations[0] : decodedToken.organizations;
      this.userService.setOrg(this.currentOrgId); // todo remove xunxo
      this.organizationService.setCurrentOrganizationId(this.currentOrgId);
    }
    this.realtime.updateAll();
    this.userService.getUser(userId).then((user) => {
      this.orgType = user.organizations?.[0].type ?? 'individual';
      this.loggedUser.set(user);
      this.userService.sortWithLogged(userId);
    }).catch(() => {
      this.logout();
    });
    this.cancelReAuth$.next(); // Emit a signal to cancel the timeout
  }

  async logout() {
    this.loggedIn = false;
    this.loggedUser.set(null);
    this.authData = null;
    localStorage.removeItem('auth-data');
    this.cancelReAuth$.next(); // Emit a signal to cancel the timeout
    signOut(this.auth);
  }

  ngOnDestroy(): void {
    // Cleanup on component destroy
    this.cancelReAuth$.next();
    this.cancelReAuth$.complete();
  }
}

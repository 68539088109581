import {Component, inject, OnInit} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import {ContactService} from '../services/contact.service';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {MatSelect, MatSelectChange, MatSelectModule} from '@angular/material/select';
import {ButtonWLoaderComponent} from '../common/button-w-loader/button-w-loader.component';
import {CSService} from '../services/cs.service';

type Feature = {
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [
      number, number
    ]
  },
  properties: {
    title: string
  }
}

@Component({
  selector: 'app-map',
  imports: [
    MatFormField,
    MatFormFieldModule,
    MatSelect,
    MatSelectModule,
    ButtonWLoaderComponent
  ],
  templateUrl: './map.component.html',
  standalone: true,
  styleUrl: './map.component.scss'
})
export class MapComponent implements OnInit {
  map?: mapboxgl.Map;
  lat = -25.4556693;
  lng = -49.2761908;
  zoom = 10.894

  style = 'mapbox://styles/eduardo-nuvex/cm3xwm0e400az01s37o1j70ed';

  contactService = inject(ContactService);
  csService = inject(CSService);

  selectedType = 'Todas';

  typesOfTasks: string[] = ['Todas', 'Atendimentos', 'Contatos'];

  ngOnInit(): void {
    this.map = new mapboxgl.Map({
      accessToken: 'pk.eyJ1IjoiZWR1YXJkby1udXZleCIsImEiOiJjbTN4dHVuODIxaTZxMmlweDhiMXpuMG1tIn0.ih2ruA7u60SH8DYwfU6EEQ',
      container: 'map',
      style: this.style,
      zoom: this.zoom,
      center: [this.lng, this.lat]
    });

    this.map.on('load', async () => {
      this.map?.resize();

      this.map?.addSource('curitiba', {
        type: 'geojson',
        // Use a URL for the value for the `data` property.
        data: 'https://firebasestorage.googleapis.com/v0/b/nuvex-gm-development.firebasestorage.app/o/feature_20241125235902867337.geojson?alt=media&token=8101d0c7-6ebd-4062-a429-8fd61b02960a'
      });
      await new Promise((resolve, reject) => {
        this.map?.loadImage('assets/images/location-pin.png',
          (error, image) => {
            if (error || image == null) reject(error);

            this.map?.addImage('embassy-15', image!);
            return resolve(null);
          })
      });

      this.map?.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png', (error, image) => {
        if (error || image == null) throw error;

        this.map?.addImage('custom-marker', image);

        this.addPointsSource();

        this.map?.addLayer({
          'id': 'points',
          'type': 'symbol',
          'source': 'points',
          'layout': {
            'icon-image': [
              'match',
              ['get', 'type'],
              'cs', 'embassy-15',
              'custom-marker'
            ],
            'text-field': ['get', 'title'],
            'text-font': [
              'Open Sans Semibold',
              'Arial Unicode MS Bold'
            ],
            'text-offset': [0, 1.25],
            'text-anchor': 'top'
          }
        });

      });
    });


  }

  centerMap() {
    if (this.map) {
      this.map.flyTo({center: [this.lng, this.lat], zoom: this.zoom});
    }


  }

  selecetionChange(event: MatSelectChange) {
    this.selectedType = event.value;
    const config: Record<string, any> = {
      'Todas': null,
      'Atendimentos': 'cs',
      'Contatos': 'contact'
    }
    const type = config[this.selectedType];
    if (type) {
      this.map?.setFilter('points', ['==', 'type', type]);
    } else {
      this.map?.setFilter('points', null);
    }
  }

  addPointsSource() {
    this.map?.addSource('points', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': this.parseFeatures()
      }
    });
  }

  parseFeatures() {
    const features: Feature[] = [];
    features.push(...this.contactService.contactList()
      .filter(contact => !contact.deletedAt && contact.loc)
      .map<Feature>((contact) => {
        const loc = JSON.parse(JSON.parse(contact.loc!));//todo: fix this!!!!
        return {
          type: 'Feature',
          geometry: {
            'type': 'Point',
            'coordinates': [
              loc.lng, loc.lat
            ]
          },
          properties: {
            title: contact.name,
            type: 'contact'
          }
        }
      }));
    features.push(...this.csService.csList()
      .filter(cs => !cs.deletedAt && cs.loc)
      .map<Feature>((cs) => {
        return {
          type: 'Feature',
          geometry: {
            'type': 'Point',
            'coordinates': [
              cs.loc!.lng, cs.loc!.lat
            ]
          },
          properties: {
            title: cs?.subject,
            type: 'cs'
          }
        }
      }))
    return features.filter(f => f !== null);
  }
}

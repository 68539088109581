import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Output, signal,
  ViewChild
} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {User} from '../../../models/user';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {Contact} from '../../../models/contact';
import {ContactCompleteColorPipe ,ContactCompletePipe} from '../contact-complete.pipe';
import {AssignPersonComponent} from '../../common/assign-person/assign-person.component';
import {MatDialog} from '@angular/material/dialog';
import {ContactService} from '../../services/contact.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {toFilter} from '../../utils';

export type ContactData = Contact & {
  loadingAssignee: boolean;
  _status?: string;
  _assignee?: string;
  _name?: string;
  _bairro?: string;
}

@Component({
  selector: 'app-contact-table',
  styleUrl: 'contact-table.component.scss',
  templateUrl: 'contact-table.component.html',
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, MatIcon, MatIconButton, PersonBadgeComponent, ContactCompleteColorPipe, ContactCompletePipe, MatProgressSpinner],
  standalone: true
})
export class ContactTable implements AfterViewInit{
  displayedColumns: string[] = ['_name', '_bairro', '_assignee', '_status', 'open'];
  dataSource: MatTableDataSource<ContactData> = new MatTableDataSource([] as ContactData[]);

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  @Output() openDetails: EventEmitter<string> = new EventEmitter();
  @Output() filteredData: EventEmitter<ContactData[]> = new EventEmitter();

  searchValue = input.required<string>();
  searchBairro = input.required<string>();
  filterUsers = input<User[]>([]);
  tableRows = 10;
  extraRows = signal(0)

  constructor() {
    effect(() => {
      this.dataSource.data = this.contactService.contactList()
        .filter((value) => !value.deletedAt)
        .map((value) => {
        return this.createNewContact(value);
      });
      this.applyFilter();
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = ((data: ContactData): boolean => {
      let r = true;
      if (this.filterUsers().length > 0) {
        if (data.assigneeUser == null) {
          r = false;
        }
        if (data.assigneeUser?.id != this.filterUsers().find((user) => user.id === data.assigneeUser?.id)?.id) {
          r = false;
        }
      }
      if (this.searchBairro() != '__all__') {
        if (data.bairro?.indexOf(this.searchBairro()) === -1) {
          r = false;
        } else if (data.bairro == null) {
          r = false
        }
      }
      if (this.searchValue() != '') {
        if ((data.name.toLowerCase().indexOf(this.searchValue()) === -1) &&
            (data.id.toLowerCase().indexOf(this.searchValue()) === -1)) {
          r = false;
        }
      }
      return r;
    });

    if (this.paginator != null && this.sort != null) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataSource.paginator.page.subscribe((page) => {
        const needExtra = (this.tableRows * (page.pageIndex + 1)) > page.length;
        this.extraRows.set(needExtra ? this.tableRows - (page.length % this.tableRows) : 0);
      })
    }
  }

  readonly dialog = inject(MatDialog);

  contactService = inject(ContactService);

  openDialog(contact: ContactData): void {
    contact.loadingAssignee = true;
    const dialogRef = this.dialog.open(AssignPersonComponent, {
      width: '400px',
      height: '100px',
      data: {},
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.assignee) {
        await this.contactService.updateContactAssignee(contact.id, result.assignee);
        await this.contactService.getAllContacts();
      }
      contact.loadingAssignee = false;
    });
  }

  applyFilter() {
    this.dataSource.filter = this.searchBairro() + this.searchValue() + String(this.filterUsers().length);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.filteredData.emit(this.dataSource.filteredData);
  }
  createNewContact(contact: Contact): ContactData {
    return {
      ...contact,
      loadingAssignee: false,
      _status: new ContactCompletePipe().transform(contact),
      _assignee: toFilter(contact.assigneeUser?.name),
      _name:toFilter(contact.name),
      _bairro: toFilter(contact.bairro)
    };
  }
}


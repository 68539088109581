import {effect, inject, Injectable, signal} from '@angular/core';
import {environment} from '../../environments/environment';
import {lastValueFrom, Observable, tap} from 'rxjs';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {NotificationPutSchema, Notifications} from '../../models/notifications';
import {
  collection,
  Firestore,
  where,
  query,
  onSnapshot,
  Unsubscribe

} from '@angular/fire/firestore';
import {SnackbarService} from './snackbar.service';
import {Router} from '@angular/router';
import {RealtimeService} from './realtime.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private url = environment.backendUrl;
  private userId?: string;
  notifications = signal<Notifications[] | null>(null);
  unredCount = signal<number>(0);
  private update$?: Unsubscribe;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private firestore: Firestore,
    private snackbarService: SnackbarService,
    private router: Router,
    private reatimeService: RealtimeService
  ) {
    this.reatimeService.notifications.subscribe(
      () => {
        this.update$ = undefined;
      }
    );
    effect(() => {
      this.userId = this.authService.loggedUser()?.id;
      if (this.userId && this.update$ == null) {
        const collectionRef = collection(this.firestore, 'users')//TODO: fromRef
        this.update$ = onSnapshot(query(collectionRef, where('__name__', '==', this.userId)), (snapshot) => {
          const change = snapshot.docChanges()[0];
          this.getNotifications()
          if(change?.type === 'modified'){
            this.snackbarService.openSnackBar('notification', 'Você tem uma notificação nova', 'VER', 5000)
              .afterDismissed().subscribe((a) => {
              if(a.dismissedByAction){
                this.router.navigate(['/notificacoes'])
              }
            })
          }
        })
      }
      if (this.notifications()){
        this.unredCount.set(this.notifications()!.filter((notification) => !notification.read).length);
      }
    });
  }

  private updateUnreadCount(notifications?: Notifications[]) {
    notifications = notifications ?? this.notifications() ?? [];
    this.unredCount.set(notifications.filter((notification) => !notification.read).length);
  }

  async getNotifications() {
    this.notifications.set(await lastValueFrom(this.http.get<Notifications[]>(this.url + `/notifications/user`)));
    this.updateUnreadCount();
  }

  async updateNotification(id: string, body: NotificationPutSchema) {
    await lastValueFrom(this.http.put<Notifications[]>(this.url + `/notifications/${id}`, body));
    await this.getNotifications();
  }

  async clearAll() {
    return  lastValueFrom(this.http.put<Notifications[]>(this.url + `/notifications/user/${this.userId}/clear`,{})).then(()=>{
      this.getNotifications();
    })
  }
  async readAll() {
    return  lastValueFrom(this.http.put<Notifications[]>(this.url + `/notifications/user/${this.userId}/read`,{})).then(()=>{
      this.getNotifications();
    })
  }

  async send(notification: {type: string, userId:string, senderId?: string, message: string}) {
    return  lastValueFrom(this.http.post<Notifications>(this.url + `/notifications`,notification))
  }
}

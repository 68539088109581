import {Component, inject} from '@angular/core';
import {DashboardService} from '../services/dashboard.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  imports: [],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  dashboardService = inject(DashboardService);
  sanitizer = inject(DomSanitizer);

  iframeUrl?: SafeResourceUrl;

  constructor() {
    this.dashboardService.getIframeUrl().then((res) => {
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.iframeUrl);
    });
  }
}

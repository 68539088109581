<link rel="stylesheet" href="create-cs-v2.component.scss">
<div class="header">
  <app-delete-confirmation
    [hidden]="isNew || authService.loggedUser()?.organizationRelationship?.position === 'ESTAGIARIO'"
    [deleting]="false"
    (deleteConfirmation)="deleteCS()"/>

  @if (isNew) {
    <div class="task-name">Adicionar Atendimento</div>
  } @else {
    <div class="task-name">{{ formGroup.get('subjectControl')?.value }} - {{ findLabel(csTypeOptions, formGroup.get('typeControl')?.value || '') }}
      - {{ !!formGroup.get('dateControl')?.value ? (formGroup.get('dateControl')?.value! | gm_date) : '' }}
    </div>
  }
  <button mat-icon-button (click)="close()" autofocus>
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
  </button>
</div>
<mat-dialog-content class="container">
  <div class="top">
    <div class="left">
      <div class="content">
        @if (!isEditing()) {
          <mat-icon id="edit-icon" class="edit-icon"
                    fontIcon="edit_square" style="color:#FBC00E"
                    (click)="isEditing.set(true)"></mat-icon>
        }
        <form id="form" [formGroup]="formGroup" class="form">
          <div class="title" [highlightError]="'contactControl'" >Contato*:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="contact-field"
                            [ngStyle]="{'grid-column-end': isCreatingContact ? 3 : 6}">
              <input type="text"
                     matInput
                     formControlName="contactControl"
                     [matAutocomplete]="autoGroup">
              <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="updateContact($event)"
                                [displayWith]="displayFn">
                @for (contact of contactOptions | async; track contact.id) {
                  <mat-option [value]="contact">
                    <mat-icon [fontIcon]="contact.type === 'PF' ? 'person' : 'apartment'"
                              style="font-weight: 800"/>
                    {{ contact.name }}
                  </mat-option>
                }
                @if (formGroup.controls['contactControl'].value ?? '' !== '' && !formGroup.controls['contactControl'].value?.id) {
                  <mat-option [disabled]="isCreatingContact"
                    [value]="{id: '__new_contact__' + (formGroup.controls['contactControl'].value ?? ''), name: (formGroup.controls['contactControl'].value ?? '')}">
                    Novo
                    contato: {{ (formGroup.controls['contactControl'].value?.name ?? formGroup.controls['contactControl'].value ?? '') }}
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          } @else {
            <app-related-contact-cs-la class="contact-field" style="color: black" size="medium" [selected]="formGroup.controls['contactControl'].value?.id" [editable]="false" />
          }

          @if (isCreatingContact) {
            <div class="title phone-label" [highlightError]="'phoneControl'">Telefone*:</div>
            <mat-form-field subscriptSizing="dynamic">
              <input formMask="phone" matInput formControlName="phoneControl">
            </mat-form-field>
          }
          <div class="title subject-label" [highlightError]="'subjectControl'">Assunto*:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="subject-field">
              <input matInput formControlName="subjectControl">
            </mat-form-field>
          } @else {
            <div class="value subject-field ">
              <span >{{ formGroup.controls['subjectControl'].value }}</span>
            </div>
          }
          <div class="title" [highlightError]="'typeControl'">Tipo*:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="typeControl">
              <mat-select [value]="csType" (selectionChange)="updateType($event)" formControlName="typeControl">
                @for (t of csTypeOptions; track t.id) {
                  <mat-option [value]="t.id">
                    <p>{{ t.name }}</p>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <span class="value type-field">{{ findLabel(csTypeOptions,formGroup.controls['typeControl'].value) }}</span>
          }

          <div class="title">Data:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="left-section">
              <input formMask="date" matInput [matDatepicker]="datepicker" formControlName="dateControl">
              <mat-datepicker #datepicker/>
              <mat-datepicker-toggle [for]="datepicker" matSuffix/>
            </mat-form-field>
          } @else {
            <span
              class="value left-section">{{ formGroup.controls['dateControl'].value ? (formGroup.controls['dateControl'].value! | gm_date) : '' }}</span>
          }

          <div class="title right-section">Horario:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic">
              <input matInput formMask="time"
                     [matTimepicker]="timepicker"
                     formControlName="timeControl">
              <mat-timepicker [scrollToMiddleTimepicker]="16" #timepicker/>
              <mat-timepicker-toggle [for]="timepicker" matSuffix/>
            </mat-form-field>
          } @else {
            <span
              class="value">{{ formGroup.controls['timeControl'].value ? (formGroup.controls['timeControl'].value! | gm_date : 'hour-minute') : '' }}</span>
          }

          <div class="title">Endereço:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="left-section">
              <input matInput  formControlName="addressControl">
            </mat-form-field>
          } @else {
            <span class="value left-section">{{ formGroup.controls['addressControl'].value }}</span>
          }

          <div class="title right-section">Número:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic">
              <input matInput formControlName="numberControl">
            </mat-form-field>
          } @else {
            <span class="value ">{{ formGroup.controls['numberControl'].value }}</span>
          }
          <div class="title">Complem.:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="left-section">
              <input matInput formControlName="compControl">
            </mat-form-field>
          } @else {
            <span class="value left-section">{{ formGroup.controls['compControl'].value }}</span>
          }

          <div class="title right-section" [ngClass]="{'error-label': formGroup.controls.addressControl.value && !csBairro && !isEditing()}">
          <span>
            Bairro:
          </span>
          </div>
          <div class="value fixed">
          <span [matTooltip]="'Será atualizado automaticamente ao atualizar o endereço'">
            {{ csBairro ?? '--' }}
          </span>
          </div>
          <div class="title" [ngClass]="{'error-label': formGroup.controls.addressControl.value && !csCity && !isEditing()}">
          <span>
            Cidade:
          </span>
          </div>
          <div class="value fixed">
          <span [matTooltip]="'Será atualizado automaticamente ao atualizar o endereço'">
            {{ csCity ?? '--' }}
          </span>
          </div>
          @if(isEditing()){
            <div class="use-contact-button">
              <app-button-w-loader
                [disabled]="!hasContactAddress"
                [matTooltip]="'Endereço não está cadastrado no Contato.'"
                [matTooltipDisabled]="!!hasContactAddress || !this.csContact || this.isCreatingContact"
                style="width: 100%;"
                padding="0 8px"
                label="Usar Endereço do Contato"
                type="secundary-white"
                (buttonClick)="copyContactAddress()"
              />
            </div>
          }
          <div class="title rich-text-label">Descrição:</div>
          @if (isEditing()) {
            <div class="rich-text-holder">
              <quill-editor placeholder="Escreva aqui" class="rich-text" [formControl]="richControl" [format]="delta == null ? 'html' : 'object'" (onContentChanged)="commentTextChanges($event)"></quill-editor>
            </div>
          } @else {
            @if(delta) {
              <quill-view class="value description-value description-view" format="object" [content]="delta"></quill-view>
            } @else {
              <span class="value description-value" [innerHTML]="data.csData.description"></span>
            }
          }
        </form>
        @if (isEditing() && !isNew) {
          <div class="buttons-cs">
            <app-button-w-loader
              label="Cancelar"
              type="outline"
              (click)="cancel()"
            />
            <app-button-w-loader
              label="Salvar"
              type="yellow"
              [loading]="loading"
              [disabled]="loading"
              (click)="saveAll()"
            />
          </div>
        }
      </div>
      <div class="docs-holder">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <p class="title">Documentos</p>

          <app-button-w-loader
            padding="0 8px"
            prefixIcon="upload"
            label="Adicionar arquivos"
            type="outline-purple"
            (click)="fileInput.click()"
          />
          <input #fileInput type="file" hidden (change)="fileUpload($event)">

        </div>
        @if(csId){
          <app-file-handler
            entity="customer_services"
            [referenceId]="csId"
            (fileUploaded)="docChanges()"
            (fileRemoved)="docChanges()"
            [uploadFileList]="uploadFileList"/>
        }
      </div>
      @if (!isNew) {
        <div class="history-holder">
          <mat-expansion-panel
            class="expansion">
            <mat-expansion-panel-header>
              <mat-panel-title><p class="title">Histórico</p></mat-panel-title>
            </mat-expansion-panel-header>
            <div class="history">
              @for (history of historyList; track history.id) {
                <app-history [history]="history"></app-history>
              }
              @if (historyList.length === 0) {
                <div class="empty-history">Nenhum histórico encontrado.</div>
              }
            </div>
          </mat-expansion-panel>
        </div>
      }
    </div>
    <div class="right">
      <div class="content">
        <section #status class="status-holder">
          <app-status-badge [list]="true" size="large" [nameId]="csStatus" (click)="showOverlay()" [type]="'cs'"></app-status-badge>
        </section>
        <div class="form-2">
          <div class="title">Responsável:</div>
          @if (isEditingAssignee) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-select #assigneeField [value]="csAssignee?.id" (selectionChange)="selectAssignee($event)">
                <mat-select-trigger>
                  @if (csAssignee) {
                    <span>{{ csAssignee.name }}</span>
                  }
                </mat-select-trigger>
                @for (person of allUsers; track person.id) {
                  <mat-option [value]="person.id">
                    <app-person-badge [selected]="false" [user]="person"></app-person-badge>
                    <p>{{ person.name }}</p>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <span class="value editable" (click)="editAssignee()">
              <app-person-badge [selected]="false" [user]="csAssignee"></app-person-badge>
              {{ csAssignee?.name }}
          </span>
          }
          <div class="title" [highlightError]="splInsertControl">Inserir no SPL?*</div>
          @if (isEditingSplInsert) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-select #splInsertField [formControl]="splInsertControl" >
                <mat-select-trigger>
                  {{ splInsertControl.value === true ? 'Sim' : splInsertControl.value === false ? 'Não' : '' }}
                </mat-select-trigger>
                <mat-option [value]="true">
                  <p>Sim</p>
                </mat-option>
                <mat-option [value]="false">
                  <p>Não</p>
                </mat-option>
              </mat-select>
            </mat-form-field>
          } @else {
            <span class="value editable"
                  (click)="editSpl()">{{ splInsertControl.value === true ? 'Sim' : splInsertControl.value === false ? 'Não' : '' }}
            </span>
          }
          @if(splInsertControl.value){
            <div class="title">Protocolo Nº:</div>
            @if (isEditingProtocol) {
              @if(isNew) {
                <mat-form-field subscriptSizing="dynamic" class="left-section">
                  <input matInput [formControl]="protocolControl" mask="999.99999.9999" [dropSpecialCharacters]="false">
                </mat-form-field>
              } @else {
                <app-editable-input formName="protocolo" [value]="csProtocol" mask="999.99999.9999"
                                    (onSave)="updateProtocol($event)" [formSize]="'158px'"
                                    (onCancel)="isEditingProtocol=false"></app-editable-input>
              }
            } @else {
              <span class="value editable" (click)="isEditingProtocol=true">{{ csProtocol }}</span>
            }
          }
        </div>
        <section class="status-holder" style="margin-top: 15px">
          <app-button-w-loader
            [matTooltip]="'Nenhuma tarefa relacionada.'"
            [matTooltipDisabled]="!!relatedTasksCount"
            [disabled]="!relatedTasksCount"
            padding="0 8px"
            type="outline-purple"
            [label]="'Ver todas as tarefas relacionadas' + (relatedTasksCount ? ' (' + relatedTasksCount + ')' : '')"
            sufixIcon="open_in_new"
            (click)="window.open('/tarefas?csId=' + csId, '_blank')"
          />
        </section>
        @if (!isNew) {
          <div class="comments-holder">
          <div style="margin-top: 20px"></div>
          <p class="title" style="margin-top: 0">Comentários:</p>
          <app-comments *ngIf="csId" [relationshipId]="csId" [relationshipType]="'cs'" [users]="allUsers"></app-comments>
          </div>
        }
      </div>
    </div>
  </div>
  @if (isNew) {
    <div class="buttons-cs">
      <app-button-w-loader
        label="Cancelar"
        type="outline"
        (click)="close()"
      />
      <app-button-w-loader
        label="Salvar"
        [loading]="loading"
        type="yellow"
        (click)="saveAll()"
      />
    </div>
  }
</mat-dialog-content>

import {Component, effect, inject, OnInit, signal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DeleteConfirmationComponent} from '../../common/delete-confirmation/delete-confirmation.component';
import {MatIcon} from '@angular/material/icon';
import {MatFormField, MatFormFieldModule, MatSuffix} from '@angular/material/form-field';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption
} from '@angular/material/core';
import {MatIconButton} from '@angular/material/button';
import {MatDivider} from '@angular/material/divider';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInput, MatInputModule} from '@angular/material/input';
import {MatTimepicker, MatTimepickerInput, MatTimepickerToggle} from '@angular/material/timepicker';
import {AsyncPipe, NgClass} from '@angular/common';
import {MatCheckbox} from '@angular/material/checkbox';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import {UserService} from '../../services/user.service';
import {User} from '../../../models/user';
import {Event, eventTypes} from '../../../models/event';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {Observable, of} from 'rxjs';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {AuthService} from '../../services/auth.service';
import dayjs from 'dayjs';
import {EventService} from '../../services/event.service';
import {SnackbarService} from '../../services/snackbar.service';
import {Platform} from '@angular/cdk/platform';
import {DUDU_DATE_FORMATS, sanitizeDelta} from '../../utils';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import {MatTooltip} from '@angular/material/tooltip';
import {HighlightErrorDirective} from '../../common/error-label.directive';
import {FormFieldParserDirective} from '../../common/formFieldParser.directive';
import {ScrollToMiddleTimepickerDirective} from '../../common/scroll-to-option.directice';
import {ContentChange, QuillEditorComponent} from "ngx-quill";
import {convertDeltaToHtml, convertHtmlToDelta} from 'quill-converter';

type EventData = Event & {
  currentDate: Date;
  usersToInvite?: string[]
}

const WHO_CAN_ADD_LABEL = 'Apenas chefes de gabinete e secretários(as) adicionar o vereador(a) em eventos.'

@Component({
  selector: 'app-create-event',
    imports: [
        DeleteConfirmationComponent,
        MatIcon,
        MatFormField,
        MatDatepicker,
        MatIconButton,
        MatDivider,
        MatInput,
        ReactiveFormsModule,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatSuffix,
        MatTimepicker,
        MatTimepickerInput,
        MatTimepickerToggle,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatOption,
        MatCheckbox,
        NgClass,
        AsyncPipe,
        MatAutocomplete,
        MatAutocompleteModule,
        MatAutocompleteTrigger,
        PersonBadgeComponent,
        MatProgressSpinner,
        MatTooltip,
        HighlightErrorDirective,
        FormFieldParserDirective,
        ScrollToMiddleTimepickerDirective,
        QuillEditorComponent,

    ],
  providers: [
      {
        provide: DateAdapter,
        useClass: CustomDateAdapter,
        deps: [MAT_DATE_LOCALE, Platform]
      }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    }],
  templateUrl: './create-event.component.html',
  standalone: true,
  styleUrl: './create-event.component.scss'
})
export class CreateEventComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<CreateEventComponent>);
  deleteLoading = signal(false)
  saving = signal(false);
  event? = inject<EventData>(MAT_DIALOG_DATA);
  html = '';
  users: Observable<User[]> = of([]);
  invitees: User[] = [];
  inviteeSearch = new FormControl('');
  fb = inject(FormBuilder);
  currentUserId?: string;
  minHour?: Date;

  whoCanAddTooltip: string | null = null;

  formGroup = this.fb.group({
    name: new FormControl(this.event?.name ?? '', Validators.required),
    date: new FormControl(this.event?.date ? dayjs.unix(this.event?.date).toDate() : '', Validators.required),
    allDay: new FormControl(this.event?.id ? !this.event.startTime && !this.event.endTime : false),
    startTime: new FormControl(this.event?.startTime ? dayjs.unix(this.event?.startTime).toDate() : '', Validators.required),
    endTime: new FormControl(this.event?.endTime ? dayjs.unix(this.event?.endTime).toDate() : '', Validators.required),
    location: new FormControl(this.event?.location ?? ''),
    description: new FormControl(this.event?.description ?? ''),
  })

  constructor(
    readonly authService: AuthService,
    private userService: UserService,
    private eventService: EventService,
    private snackService: SnackbarService
  ) {
    effect(() => {
      const currentUser = this.authService.loggedUser();
      if (!this.event?.id) {
        this.currentUserId = currentUser!.id;
        for(const user of this.userService.users()){
          if(currentUser?.organizationRelationship?.position !== 'VEREADOR' && currentUser?.organizationRelationship?.position !== 'CHEFE_DE_GABINETE' && currentUser?.organizationRelationship?.position !== 'SECRETARIO'){
            this.whoCanAddTooltip = WHO_CAN_ADD_LABEL;
          }
          if(this.event?.usersToInvite?.includes(user.id)){
            this.invitees.push(user);
          }
        }
        if(!this.invitees.length){
          this.invitees.push(this.authService.loggedUser()!);
        }
        this.updateUserList('');
      } else {
        if(this.userService.users() && this.userService.users().length){
          this.invitees = this.event?.users?.map(u => this.userService.users().find(user => user.id === u.userId)!) ?? [];
          this.currentUserId = this.authService.loggedUser()!.id;
          if(currentUser?.organizationRelationship?.position !== 'VEREADOR' && currentUser?.organizationRelationship?.position !== 'CHEFE_DE_GABINETE' && currentUser?.organizationRelationship?.position !== 'SECRETARIO'){
            this.whoCanAddTooltip = WHO_CAN_ADD_LABEL;
          }
        }
        if (this.event?.delta == null && this.event?.description) {
          this.richControl.setValue(convertHtmlToDelta(this.event?.description));
        } else {
          this.richControl.setValue(this.event?.delta);
        }
      }
      this.users = of(this.userService.activeUsers());
    });
    this.dialogRef.disableClose = true;
    if (this.event?.id) {
      this.updateUserList('');
    }
  }

  ngOnInit(): void {
    this.inviteeSearch.valueChanges.subscribe((value: any) => {
      if (value && typeof value === 'string') {
        this.updateUserList(value);
      } else {
        this.updateUserList('');
      }
    })
    if(this.event?.currentDate){
      this.formGroup.controls.date.setValue(this.event.currentDate.toISOString());
    }
    this.handleAllday(this.formGroup.controls.allDay.value!);
    this.formGroup.controls.allDay.valueChanges.subscribe((value: any) => {
        this.handleAllday(value)
    })

    this.formGroup.controls.startTime.valueChanges.subscribe((value: any) => {
      if (value) {
        this.minHour = dayjs(value).add(15, 'minute').toDate();
      }
    })
  }

  handleAllday(isAllDay: boolean){
    if (isAllDay) {
      this.formGroup.controls.startTime.setValue(null);
      this.formGroup.controls.endTime.setValue(null);
      this.formGroup.controls.startTime.disable();
      this.formGroup.controls.endTime.disable();
    } else {
      this.formGroup.controls.startTime.enable();
      this.formGroup.controls.endTime.enable();
    }
  }

  updateUserList(filter: string) {
    setTimeout(() => {
      let users = this.userService.users();
      users = users.filter(u =>
        !this.invitees.find(i => i.id === u.id) && !u.deleted &&
        u.name.toLowerCase().includes(filter.toLowerCase())
      );
      this.users = of(users);
    }, 1);
  }

  close() {
    this.dialogRef.close()
  }

  displayFn(user?: User): string {
    return user && user.name ? user.name : '';
  }

  updateInvitees(event: MatAutocompleteSelectedEvent) {
    this.invitees.push(event.option.value)
    this.inviteeSearch.setValue('');
  }

  removeInvitee(userId: string) {
    const index = this.invitees.indexOf(this.invitees.find(u => u.id === userId)!)
    this.invitees.splice(index, 1)
    this.updateUserList('');
  }

  richControl: FormControl = new FormControl([]);

  delta: any;
  commentTextChanges(event: ContentChange) {
    this.delta = event.content;
  }

  async save() {
    if(this.saving()) return;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.saving.set(true);
      const formValue = this.formGroup.value;
      const date = dayjs(formValue.date);
      let startTime = null;
      let endTime = null;


      if (formValue.startTime){
        startTime = date
          .add(dayjs(formValue.startTime).hour(), 'hour')
          .add(dayjs(formValue.startTime).minute(), 'minute');
        endTime = date
          .add(dayjs(formValue.endTime).hour(), 'hour')
          .add(dayjs(formValue.endTime).minute(), 'minute');
      }

      const result = {
        ...formValue,
        date: date.unix(),
        startTime: startTime ? startTime.unix() : null,
        endTime: endTime? endTime.unix() : null,
        users: this.invitees.map(u => ({userId: u.id, role: 'invitee'})),
        delta: sanitizeDelta(this.delta),
        description: convertDeltaToHtml(this.delta),
      };
      delete result.allDay;
      if (this.event?.id) {
        this.eventService.update(this.event.id, result as Event).then((created) => {
          this.saving.set(false);
          this.dialogRef.close(created)
        }).catch(() => {
          this.saving.set(false);
          this.snackService.openSnackBar('error', 'Erro ao criar evento', 'OK');
        })
      } else {
        this.eventService.create(result as Event).then((created) => {
          this.saving.set(false);
          this.dialogRef.close(created)
        }).catch(() => {
          this.saving.set(false);
          this.snackService.openSnackBar('error', 'Erro ao criar evento', 'OK');
        })
      }
    }
  }

  cancel() {
    this.dialogRef.close()
  }

  deleteEvent() {
    this.deleteLoading.set(true)
      this.eventService.delete(this.event!.id).then(() => {
        this.snackService.openSnackBar('success', 'Evento deletado', 'OK')
        this.dialogRef.close()
      }).catch(() => {
        this.snackService.openSnackBar('error', 'Erro ao deletar evento', 'OK')
      }).finally(() => {
        this.deleteLoading.set(false)
      })
  }

  protected readonly dayjs = dayjs;
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from "../../../environments/environment";
import {TileEvent} from '../calendar.component';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {MatIcon} from '@angular/material/icon';
import {GMDatePipe} from '../../common/date.pipe';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {MatIconButton} from '@angular/material/button';
import {eventTypeEnum, eventTypes} from '../../../models/event';
import {QuillViewComponent} from 'ngx-quill';

@Component({
  selector: 'app-event-overlay',
  imports: [
    MatIcon,
    GMDatePipe,
    MatIconButton,
    PersonBadgeComponent,
    CdkConnectedOverlay,
    QuillViewComponent
  ],
  templateUrl: './event-overlay.component.html',
  standalone: true,
  styleUrl: './event-overlay.component.scss'
})
export class EventOverlayComponent {
    protected readonly environment = environment;

    @Input({required: true}) eventTile!: TileEvent;
    @Input({required: true}) trigger!: CdkOverlayOrigin;
    @Output() edit = new EventEmitter<void>();


    triggerDetails(){
      this.edit.emit();
      this.eventTile.isOpened = false;
    }


  protected readonly eventTypeEnum = eventTypeEnum;

    getType(){
      for (const type of eventTypes){
        if (type.value === this.eventTile.meta.type){
          return type.label;
        }
      }
      return '--'
    }

  protected readonly window = window;
}

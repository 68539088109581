<div class="main">
  <div style="display:flex; gap: 8px; margin-bottom: 10px">
  <div class="page-title">Calendário</div>
    <app-toogle-select
      [options]="['Mês', 'Dia']"
      [selected]="isDayView"
      (change)="changeView($event)"
    />
  </div>
  <div class="filters">
    <section class="entry">
      <section class="assignee-cell">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Tipo de evento</mat-label>
          <mat-select [value]="selectedType" (selectionChange)="changeType($event)">
            @for (tot of typesOfEvent; track tot) {
              <mat-option [value]="tot.value">
                <p>{{ tot.label }}</p>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </section>
    </section>
    <app-person-picker
      [title]="'Eventos de:'"
      [selectedPeople]="userFilter"
      [people]="activeUsers" (onSelectionChange)="personSelected($event)"></app-person-picker>
    <div class="title-holder">
      <div class="calendar-title" [ngClass]="{day: isDayView}">
        <button mat-icon-button (click)="previous()">
          <mat-icon fontIcon="keyboard_arrow_left"></mat-icon>
        </button>
        @if (isDayView){
          <div class="title day" style="text-transform: capitalize;"> {{ currentDate | gm_date : 'full_date' }} </div>
        } @else {
          <span class="title">{{ currentDate | gm_date : 'MMMM' }} de {{ currentDate.format('YYYY') }}</span>
        }
        <button mat-icon-button (click)="next()">
          <mat-icon fontIcon="keyboard_arrow_right"></mat-icon>
        </button>
      </div>
      <div class="button-holder">
        <app-button-w-loader
          label="Hoje"
          type="outline-purple"
          (click)="setDay()"
        />
        <app-button-w-loader
          label="+ Novo Evento"
          type="yellow"
          (click)="openDialog()"
        />

      </div>
    </div>
  </div>
  @if (!isDayView) {
<!--  <mat-grid-list style="margin-bottom: 2px" cols="7" rowHeight="50px">-->
<!--    @for (tile of columns; track tile) {-->
<!--      <mat-grid-tile-->
<!--        class="tile header"-->
<!--        [ngClass]="{'other-month': tile.otherMonth}"-->
<!--        [colspan]="tile.cols"-->
<!--        [rowspan]="tile.rows">{{ tile.text }}-->
<!--      </mat-grid-tile>-->
<!--    }-->
<!--  </mat-grid-list>-->
    <mat-grid-list cols="7" rowHeight="180px">
    @for (tile of firstRow; track tile.text) {
      <mat-grid-tile
        class="tile header"
        [ngClass]="{'other-month': tile.otherMonth}"
        (dblclick)="goToDay(tile)"
        [colspan]="tile.cols"
        [rowspan]="tile.rows">
        <div class="holder" [ngClass]="{'today-tile': tile.today}">
          <div class="tile-header">
            <span>{{ tile.header }}</span>
            <span>{{ tile.text }}</span>
          </div>
          <div class="task-list">
            @for (event of tile.tileEvents; track event.rId) {
              <div [style.background-color]="event.color" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
                   (click)="event.isOpened = true" class="event">{{ event.title }}
              </div>
              <app-event-overlay (edit)="openDialog(event.id)"  [eventTile]="event" [trigger]="trigger" />
            }
            @if(tile?.overflow! > 0) {
              <div (click)="setDay(tile.date)"> mais {{ tile.overflow }} evento{{ (tile.overflow! >1 ) ? 's' : '' }}
              </div>
            }
          </div>
          <div style="width: 5px;"></div>

        </div>

      </mat-grid-tile>

    }
    </mat-grid-list>
  <mat-grid-list cols="7" [rowHeight]="tiles.length > 28 ? '130px' :'148px'">
    @for (tile of tiles; track tile.text) {
      <mat-grid-tile
        class="tile"
        [ngClass]="{'other-month': tile.otherMonth, 'today-tile': tile.today}"
        (dblclick)="goToDay(tile)"
        [colspan]="tile.cols"
        [rowspan]="tile.rows">
        <div class="holder" [ngClass]="{'today-tile': tile.today}">
          {{ tile.text }}
          <div class="task-list">
            @for (event of tile.tileEvents; track event.rId) {
              <div [style.background-color]="event.color" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
                    (click)="event.isOpened = true" class="event">{{ event.title }}
              </div>
              <app-event-overlay (edit)="openDialog(event.id)"  [eventTile]="event" [trigger]="trigger" />
            }
            @if(tile?.overflow! > 0) {
              <div (click)="setDay(tile.date)"> mais {{ tile.overflow }} evento{{ (tile.overflow! >1 ) ? 's' : '' }}
              </div>
            }
          </div>
          <div style="width: 5px;"></div>

        </div>
      </mat-grid-tile>

    }
  </mat-grid-list>
  } @else {
  <app-day-calendar
    [day]="currentDate"
    [todayEvents]="todayEvents()"
    (edit)="openDialog($event)"
    (dayChange)="setDay($event)"/>
  }
</div>


<div
  [ngStyle]="{'width': list() ? '145px' : '110px', 'background-color': (nameId() | statusParser : type() == 'la').color, 'color': (nameId() | statusParser : type() == 'la').invertTitleColor ? '#FFF' : '#000', 'padding': size() === 'small' ? '5px' : (size() === 'medium' ? '5px' : '10px 10px') }"
 [style.cursor]="clickable() ? 'pointer' : 'unset'"
  [style.box-shadow]="(hovered && clickable()) ? '0 0 5px 1px #00000033' : 'unset'"
 (mouseover)="hovered = true"
  (mouseout)="hovered = false"
  class="holder"
><span
  [ngStyle]="{'font-size': size() === 'small' ? '12px' : (size() === 'medium' ? '14px' : '16px') }"
>{{ (nameId() | statusParser).name }}</span>
  @if(list()) {
    <mat-icon class="icon" fontIcon="keyboard_arrow_down"/>
  }
</div>

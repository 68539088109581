import {Component, effect, inject} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatOption, MatRipple} from '@angular/material/core';
import {Location} from '@angular/common';
import {BadgeUserData, PersonPickerComponent} from '../common/person-picker/person-picker.component';
import {MatDialog} from '@angular/material/dialog';
import {ContactTable} from './contact-table/contact-table.component';
import {Contact} from '../../models/contact';
import {ContactService} from '../services/contact.service';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {ContactDialogData, CreateContactComponent} from './create-contact/create-contact.component';
import {User} from '../../models/user';
import {ItemCounterComponent} from '../common/item-counter/item-counter.component';
import {ContactCompletePipe} from './contact-complete.pipe';
import {StatusGroup} from '../common/status-parser.pipe';
import {ActivatedRoute, Router} from '@angular/router';
import {SnackbarService} from '../services/snackbar.service';
import {toFilter} from '../utils';

@Component({
  selector: 'app-contacts',
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    MatRipple,
    PersonPickerComponent,
    ContactTable,
    MatSelect,
    MatOption,
    ItemCounterComponent
  ],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss'
})
export class ContactsComponent {
  contacts: Contact[] = [];

  statusMap = new Map<string, StatusGroup>([
    ['complete', StatusGroup.complete],
    ['incomplete', StatusGroup.incomplete],
  ]);

  snackBar = inject(SnackbarService);
  activatedRoute = inject(ActivatedRoute);
  contactService = inject(ContactService);
  router = inject(Router);

  bairros?: string[];
  selectedBairro = '__all__'
  usersToShowBadges?: User[];

  cId: string | null = '';


  constructor() {
    this.activatedRoute.paramMap.subscribe((param) => {
      this.cId = param.get('contactId');
      this.update();
    });
    effect(() => {
      this.update();
    });
  }

  static updateUsers(contacts: Contact[]): User[] | undefined {
    if (contacts.length === 0) {
      return;
    }
    let userList: User[] | undefined;
    for (let t of contacts) {
      if (t.assigneeUser && (userList?.findIndex((user) => user.id === t.assigneeUser?.id) === -1 || !userList)) {
        if (!userList) {
          userList = [];
        }
        userList.push(t.assigneeUser);
      }
    }
    return userList;
  }

  setStatusMap(cData: Contact[]) {
    this.statusMap.get('complete')?.setCount(cData.filter((contact) => new ContactCompletePipe().transform(contact) === 'Completo').length);
    this.statusMap.get('incomplete')?.setCount(cData.filter((contact) => new ContactCompletePipe().transform(contact) === 'Pendente').length);
  }

  filteredTableData(contactData: Contact[]) {
    if (contactData.length === 0) {
      contactData = this.contactService.contactList().filter((contact) => !contact.deletedAt);
    }
    this.setStatusMap(contactData);
  }

  searchBairro(event: MatSelectChange) {
    this.selectedBairro = event.value;
  }

  opened = false;

  location = inject(Location);

  async update() {
    this.bairros = [];
    this.contactService.contactList().forEach((contact) => {
      if (contact.bairro && !this.bairros?.includes(contact.bairro)) {
        this.bairros?.push(contact.bairro);
      }
    });
    this.usersToShowBadges = ContactsComponent.updateUsers(this.contactService.contactList());
    this.setStatusMap(this.contactService.contactList().filter((contact) => !contact.deletedAt));
    if (this.cId && this.contactService.contactList().find((c) => c.id === this.cId)) {
      this.openDialog(this.cId);
    }
  }

  readonly dialog = inject(MatDialog);

  openDialog(contactId?: string): void {
    if (this.opened) {
      return;
    }
    this.opened = true;
    const dialogRef = this.dialog.open<CreateContactComponent, ContactDialogData, { id: string } | undefined>(CreateContactComponent, {
      maxWidth: '1100px',
      height: '620px',
      data: {
        contactData: this.contactService.contactList().find((contact) => contact.id === contactId),
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      this.cId = null;
      if (result?.id) {
        const snack = this.snackBar.openSnackBar('success', 'Contato criado com sucesso!', 'Clique para vê-lo',)
        snack.onAction().subscribe(() => {
          this.router.navigate(['contatos', result.id]);
        })
      } else {
        this.location.go('contatos');
      }
      this.opened = false;
    });
  }

  searchValue: string = '';

  newContact() {
    this.openDialog();
  }

  openDetails(contactId: string) {
    this.openDialog(contactId);
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = toFilter(filterValue);
  }

  usersToFilter: User[] = [];
  selectedPeople: string[] = [];
  personSelected(event: BadgeUserData[]) {
    this.usersToFilter = event.filter((user) => user.selected);
    this.selectedPeople = this.usersToFilter.map((user) => user.id);
  }
}

<div
  matRipple
  class="button"
  [matRippleDisabled]="disabled"
  [ngStyle]="{padding: padding}"
  (click)="_click()"
  [ngClass]="[disabled ? 'disabled' : '', type]"
>
  @if(prefixIcon){
    <mat-icon [fontIcon]="prefixIcon" style="font-weight: 800"/>
  }
  <div class="loader-holder">
    <mat-spinner [style.visibility]="!loading ? 'hidden' : 'unset'" diameter="25" color="black"></mat-spinner>
  </div>
    <div [style.visibility]="loading ? 'hidden' : 'unset'" >{{ label }}</div>
  @if(sufixIcon){
    <mat-icon [fontIcon]="sufixIcon" style="font-weight: 800"/>
  }
</div>

import { Component } from '@angular/core';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {OrganizationsComponent} from './organizations/organizations.component';

@Component({
  selector: 'app-admin',
  imports: [
    MatTabGroup,
    MatTab,
    OrganizationsComponent
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent {

}

<div class="task-container">
  <div class="page-header">
    <span class="page-title">Atendimentos</span>
  </div>
  <span class="h2" style="font-weight: bold">Filtros</span>
  <div class="filters">
    <mat-form-field subscriptSizing="dynamic" style="align-self: end;">
      <mat-label>Pesquise por protocolo, contato ou tipo</mat-label>
      <input matInput [value]="filterValue" (keyup)="search($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <app-person-picker [selectedPeople]="selectedPeople" [people]="usersToShowBadges" (onSelectionChange)="personSelected($event)"></app-person-picker>
    <div style="display: flex; flex-direction: column">
    <span class="filter-title">Bairro:</span>
      <mat-form-field subscriptSizing="dynamic" style="width: 223px">
        <mat-select [value]="bairroOptions[0]" (selectionChange)="updateBairro($event)" formControlName="typeControl">
          @for (t of bairroOptions; track t) {
            <mat-option [value]="t">
              <p>{{ t }}</p>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <app-deadline-filter [showUntil]="false"
      [title]="'Data:'" [showLate]="false"
                         (onChange)="timestampChange($event)"
                         (onDateChange)="timestampDateChange($event)"></app-deadline-filter>
    <div mat-ripple class="save" (click)="newTask()">
      <div>+ Novo atendimento</div>
    </div>
  </div>

  <div class="item-counter-list">
    @for (status of statusMap.keys(); track status) {
      <app-item-counter
        [title]="statusMap.get(status)?.pluralName ?? ''"
        [value]="statusMap.get(status)?.count ?? 0"
        [titleColor]="statusMap.get(status)?.color ?? ''"
        [textColor]="(statusMap.get(status)?.invertTitleColor ?? false) ? 'white' : 'black'"
      ></app-item-counter>
      @if (statusMap.get(status)?.showSeparator) {
        <div class="counter-separator"></div>
      }
    }
  </div>
  <app-cs-table [values]="services" [searchValue]="searchValue" [filterToday]="filterToday" [filterDay]="filterUntil ? filterDate : undefined"
                  (openDetails)="openDetails($event)" (filteredData)="filteredTableData($event)"
                  [filterUsers]="usersToFilter" [toBeDeleted]="toBeDeleted" [filterBairro]="filterBairro"></app-cs-table>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="number">
      <th class="number-column" mat-header-cell *matHeaderCellDef mat-sort-header> Proposição Nº</th>
      <td class="number-column"  mat-cell *matCellDef="let row"> {{row.number}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th class="date-column" mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
      <td class="date-column" mat-cell *matCellDef="let row"> {{row.date | gm_date}} </td>
    </ng-container>

    <ng-container matColumnDef="_type">
      <th class="type-column" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
      <td class="type-column" mat-cell *matCellDef="let row"> {{findLabel(typeOptions, row.type)}} </td>
    </ng-container>

    <ng-container matColumnDef="_theme">
      <th class="theme-column" mat-header-cell *matHeaderCellDef mat-sort-header> Tema </th>
      <td class="theme-column" mat-cell *matCellDef="let row"> {{findLabel(themeOptions, row.theme)}} </td>
    </ng-container>

    <ng-container matColumnDef="_subject" class="subject-column">
      <th class="subject-column" mat-header-cell *matHeaderCellDef mat-sort-header> Assunto </th>
      <td  class="subject-column" mat-cell *matCellDef="let row">
        <div #subjectValue class="text-overflow"
             [matTooltip]="row.subject"
             [matTooltipDisabled]="subjectValue.scrollWidth <= subjectValue.offsetWidth"
             style="width: 255px;">{{row.subject}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="_assignee">
      <th class="assignee-column" mat-header-cell *matHeaderCellDef mat-sort-header> Responsável </th>
      <td class="assignee-column" mat-cell *matCellDef="let row">
        @if (row.assigneeUser != null) {
          <section class="assignee-cell">
            <app-person-badge [selected]="false" [user]="row.assigneeUser" [disableTooltip]="true"></app-person-badge>
            <p>{{row.assigneeUser.name}}</p>
          </section>
        } @else {
          @if(row.loadingAssignee) {
            <mat-progress-spinner [diameter]="25" class="loading-spinner" mode="indeterminate"></mat-progress-spinner>
          } @else {
            <p class="add-assignee" (click)="openDialog(row)">+ Clique para adicionar</p>
          }
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th class="status-column" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td class="status-column" mat-cell *matCellDef="let row"> <app-status-badge [nameId]="row.status" [type]="'la'"></app-status-badge></td>
    </ng-container>

    <ng-container matColumnDef="open">
      <th class="open-column"  mat-header-cell *matHeaderCellDef></th>
      <td class="open-column"  mat-cell *matCellDef="let row" > <button mat-icon-button (click)="openDetails.emit(row.id)"> <mat-icon fontIcon="open_in_full"></mat-icon></button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nenhum atendimento encontrado</td>
    </tr>
  </table>
  <!--  <div mat-row class="mat-mdc-row" style="display: none" ></div>-->
  <!--  @for(a of [].constructor(6); let i =$index; track i) {-->
  <!--    <div mat-row  class="mat-mdc-row"></div>-->
  <!--  }-->
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Selecione a página dos atendimentos"></mat-paginator>
</div>

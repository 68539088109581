<div class="main">
  <div class="logo-holder">
    <app-logo fillColor="var(--color-secondary)"/>
    @if(environment.environment !== 'production'){
      <div #flag class="environment-flag" (click)="flag.classList.contains('animated') ? flag.classList.remove('animated') :flag.classList.add('animated')">
        <span>{{ environment.environment }}</span>
      </div>
    }
  </div>
  <div class="title">
    GPC
  </div>
  @if ((authService.loggedUser()?.superAdmin ?? false) && organizationService.isImpersonating) {
    <div class="selected-org">
      Gabinete Selecionado: {{ organizationService.currentOrganization()?.name }}
    </div>
  }

  <div>
    <div routerLink="/notificacoes" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon aria-hidden="false" [matBadgeHidden]="count==0" [matBadge]="count" matBadgeSize="small" fontIcon="notifications"></mat-icon>
      <span>
    Notificações {{ count > 0 ? '(' + count + ')' : '' }}
  </span>
    </div>

<!--    <div routerLink="/relatorios" routerLinkActive="active" mat-ripple class="menu-item">-->
<!--      <mat-icon fontIcon="monitoring"></mat-icon>-->
<!--      <span>-->
<!--    Relatórios-->
<!--  </span>-->
<!--    </div>-->

    <div routerLink="/calendario" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="calendar_month"></mat-icon>
      <span>
    Calendário
  </span>
    </div>

    <div routerLink="/tarefas" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="task"></mat-icon>
      <span>
    Tarefas
  </span>
    </div>

    <div routerLink="/atendimentos" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="support_agent"></mat-icon>
      <span>
    Atendimentos
  </span>
    </div>

    <div routerLink="/mapa" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="map"></mat-icon>
      <span>
    Mapa
  </span>
    </div>

    <div routerLink="/contatos" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="contact_phone"></mat-icon>
      <span>
    Contatos
  </span>
    </div>

    <div routerLink="/acoes-legislativas" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="gavel"></mat-icon>
      <span>
    Ações Legislativas
  </span>
    </div>

    <!--    <div mat-ripple class="menu-item">-->
    <!--      <mat-icon fontIcon="savings"></mat-icon>-->
    <!--      <span>-->
    <!--    Finanças-->
    <!--  </span>-->
    <!--    </div>-->

    <div routerLink="/usuarios" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="group"></mat-icon>
      <span>
    Gerenciar Usuários
  </span>
    </div>
    @if ((this.user?.superAdmin ?? false) && !organizationService.isImpersonating) {
      <div routerLink="/admin" routerLinkActive="active" mat-ripple class="menu-item">
        <mat-icon fontIcon="admin"></mat-icon>
        <span>
    Admin
  </span>
      </div>
    }
    @if (organizationService.isImpersonating) {
      <div mat-ripple class="menu-item" (click)="organizationService.setIsImpersonating(null)">
        <mat-icon fontIcon="exit_to_app"></mat-icon>
        <span>
    Voltar para meu gabinete
  </span>
      </div>
    }
  </div>


  <div class="user-container">
    <div class="line-container">
      <app-person-badge [size]="46" [user]="user" [selected]="false"></app-person-badge>
      <div class="text">
        <span>Olá, {{ user?.name ?? 'User' }}!</span>
        <span class="configuration-link" (click)="openDialog()">Configurações</span>
      </div>
      <div class="logout">
        <button mat-icon-button>
          <mat-icon fontIcon="logout" (click)="logout()"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

import {Component, effect, input} from '@angular/core';

@Component({
  selector: 'app-skeleton',
  imports: [],
  templateUrl: './skeleton.component.html',
  standalone: true,
  styleUrls: ['./skeleton.component.scss', '../comments.component.scss']
})
export class SkeletonComponent {
  rowNumber = input(3);
  type = input('comment');
  rows = Array.from({length: this.rowNumber()}, (_, i) => i);
  constructor() {
    effect(() => {
      this.rows = Array.from({length: this.rowNumber()}, (_, i) => i);
    });
  }
}

<div style="padding-right: 13px; width: fit-content;" [matBadge]="selectedPeople()?.length" [matBadgeHidden]="!selectedPeople()?.length">
  <span  >{{ title || 'Responsável:' }}</span>
</div>
<div class="badge-container">
  @if (people()?.length === 0) {
    @for (person of [1, 2, 3, 4]; track person) {
      <div class="circle size animate"></div>
    }
  }
  @if (!people()) {
    <div class="no-user">Nenhum responsável encontrado</div>
  }
  @for (person of peopleToShow.slice(0, 4); track person.id) {
    <app-person-badge [user]="person" [selected]="person.selected"
                      [size]="person.selected ? 32 : 30"
                      (click)="selectBadge(person)"></app-person-badge>
  }
  @if ((people()?.length ?? 0) > 4) {
    <app-person-badge cdkOverlayOrigin #trigger="cdkOverlayOrigin" #plus [user]=""
                      [placeholderNumber]="(people()?.length ?? 0) - 4"
                      [selected]="hiddenSelected()"
                      (click)="showOverlay()"></app-person-badge>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayHasBackdrop]="true"
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      (backdropClick)="isOpen = false"
    >
      <ul class="example-list">
        @for (person of peopleToShow.slice(4); track person.id) {
          <li>
            <mat-checkbox (change)="selectBadge(person)" [checked]="person.selected" style="width: 100%;">
              <div class="list-item">
                <app-person-badge [user]="person" [selected]="false"></app-person-badge>
                <div style="width: 120px; overflow: hidden;
                     text-overflow: ellipsis;">{{ person.name }}
                </div>
              </div>
            </mat-checkbox>
          </li>
        }
      </ul>
    </ng-template>
  }
</div>

import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter, inject,
  Input,
  input,
  Output,
  ViewChild
} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {User} from '../../../models/user';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {GMDatePipe} from '../../common/date.pipe';
import {UserService} from '../../services/user.service';
import {toFilter} from '../../utils';
import {RolePositionsPipe} from '../../common/roles_and_positions.pipe';


@Component({
  selector: 'app-user-table',
  styleUrl: 'user-table.component.scss',
  templateUrl: 'user-table.component.html',
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, MatIcon, MatIconButton, PersonBadgeComponent, GMDatePipe, RolePositionsPipe],
  standalone: true
})
export class UserTable implements AfterViewInit{
  displayedColumns: string[] = ['_name', 'email', 'role', 'position', 'phone', 'birthday', 'open'];
  dataSource: MatTableDataSource<User> = new MatTableDataSource([] as User[]);

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  @Input() values: User[] = []

  @Output() openDetails: EventEmitter<string> = new EventEmitter();
  @Output() filteredData: EventEmitter<User[]> = new EventEmitter();

  initial = true;

  searchValue = input.required<string>();
  disableDetails = input<boolean>(false);
  usersService = inject(UserService);

  constructor() {
    this.dataSource = new MatTableDataSource(this.usersService.users());
    effect(() => {
      this.dataSource.data = this.usersService.users()
        .filter((u) => !u.deleted)
        .map((user) => {
        return {
          ...user,
          _name: toFilter(user.name),
      }});
      this.applyFilter();
    });
  }

  applyFilter() {
    this.dataSource.filter = this.searchValue();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    if (!this.initial) {
      this.filteredData.emit(this.dataSource.filteredData);
    } else {
      this.initial = false;//gambi
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = ((data: User): boolean => {
      const filterMeta = data.name + data.email + data.phone;
      let r = true;
      if (this.searchValue() != '') {
        if (filterMeta.toLowerCase().indexOf(this.searchValue()) === -1) {
          r = false;
        }
      }
      return r;
    });

    if (this.paginator != null && this.sort != null) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      setTimeout(() => {
        if(this.sort){
          this.sort.sort(({id: 'name', start: 'asc'}) as any);
        }
      },0)
    }
  }
}


import {Injectable} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

export type DashboardResponse = {
  iframeUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private url = environment.backendUrl;
  constructor(
    // private realtime: RealtimeService,
    private http: HttpClient,
  ) {
    // this.realtime.users.pipe(startWith(null))
    //   .subscribe(()=>{
    //     this.updateList();
    //   })
  }

  async getIframeUrl() {
    return await lastValueFrom(this.http.get<DashboardResponse>(this.url+`/metabase/iframeUrl`));
  }
}

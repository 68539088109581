import { Pipe, PipeTransform } from '@angular/core';

export interface IStatusGroup {
  name: string;
  color: string
}

export class StatusGroup implements IStatusGroup {
  count: number = 0;
  showSeparator: boolean = false;
  invertTitleColor: boolean = false;
  pluralName: string = '';
  constructor(public id: string, public name: string, public color: string, pluralName?: string) {
    this.pluralName = pluralName ?? name;
  }

  toFemme(){
    this.name = this.name.slice(0, -1) + 'a';
  }

  setShowSeparator(showSeparator: boolean): StatusGroup {
    this.showSeparator = showSeparator;
    return this;
  }

  setCount(count: number): StatusGroup {
    this.count = count;
    return this;
  }

  setInvertTitleColor(invertTitleColor: boolean): StatusGroup {
    this.invertTitleColor = invertTitleColor;
    return this;
  }

  static total = new StatusGroup('total', 'Total', 'rgba(107,71,159,0.2)');
  static open = new StatusGroup('open', 'Aberto', '#D9D9D9', 'Abertos');
  static onHold = new StatusGroup('onHold', 'Em espera', '#FF7926');
  static inProgress = new StatusGroup('inProgress', 'Em progresso', '#00AAD999');
  static approved = new StatusGroup('approved', 'Aprovada', '#EE81BF', 'Aprovadas');
  static sanctioned = new StatusGroup('sanctioned', 'Sancionada', '#4DBF8E', 'Sancionadas');
  static archived = new StatusGroup('archived', 'Arquivada', '#C83131', 'Arquivadas').setInvertTitleColor(true);
  static deferred = new StatusGroup('deferred', 'Deferido', '#4DBF8E');
  static delivered = new StatusGroup('delivered', 'Entregue', '#4DBF8E', 'Entregues');
  static rejected = new StatusGroup('rejected', 'Rejeitada', '#FF7926', 'Rejeitadas').setInvertTitleColor(true);
  static denied = new StatusGroup('denied', 'Negado', '#DA2727', 'Negados').setInvertTitleColor(true);
  static late = new StatusGroup('rejected', 'Atrasado', '#DA2727').setInvertTitleColor(true);
  static incomplete = new StatusGroup('incomplete', 'Incompletos', '#DA2727').setInvertTitleColor(true);
  static done = new StatusGroup('done', 'Concluído', '#4DBF8E');
  static complete = new StatusGroup('complete', 'Completos', '#4DBF8E');
  static ok = new StatusGroup('ok', 'Em dia', '#4DBF8E');

  static all = [
    StatusGroup.open, StatusGroup.onHold, StatusGroup.inProgress,
    StatusGroup.deferred, StatusGroup.rejected, StatusGroup.done,
    StatusGroup.denied, StatusGroup.delivered, StatusGroup.approved, StatusGroup.sanctioned, StatusGroup.archived
  ];
}

@Pipe({
  standalone: true,
  name: 'statusParser'
})
export class StatusParserPipe implements PipeTransform {

  transform(value: string, ...args: any[]): StatusGroup {
    const status =  StatusGroup.all.find((status) => status.id === value) ?? new StatusGroup(value, value, '#000');
    if(!!args[0] && ['open'].includes(value)) { // list of status that need to be in feminine
      status.toFemme();
    }
    return status;
  }

}

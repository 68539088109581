import {User} from './user';
import {Coordinate} from './coordinate';
import {Contact} from './contact';

export interface CS {
  id: string;
  contactId: string;
  subject: string;
  contact?: Contact;
  contactPhone?: string;
  protocol?: string;
  type: string;
  status: string;
  timestamp: number;
  attendDate?: number;
  attendTime?: number;
  description?: string;
  bairroId?: string;
  address?: string;
  entity?: string;
  assigneeUser?: User;
  assigneeUserId?: string;
  deletedAt?: number;
  loc?: Coordinate;
  addressNumber?: string;
  addressComp?: string;
  city?: string;
  bairro?: string;
  splInsert?: boolean | null;
  delta?: any;
}

export const csTypeOptions = [
  { id: 'administrativo', name: 'Administrativo' },
  { id: 'alvara', name: 'Alvará' },
  { id: 'asfalto', name: 'Asfalto' },
  { id: 'assistenciaSocial', name: 'Assistência Social' },
  { id: 'calcadaMeioFio', name: 'Calçada / Meio Fio' },
  { id: 'cortePodaArvore', name: 'Corte / Poda de Árvore' },
  { id: 'cultura', name: 'Cultura' },
  { id: 'denuncia', name: 'Denúncia' },
  { id: 'doacao', name: 'Doação' },
  { id: 'educacao', name: 'Educação' },
  { id: 'emendaParlamentar', name: 'Emenda Parlamentar' },
  { id: 'emprego', name: 'Emprego' },
  { id: 'esportes', name: 'Esportes' },
  { id: 'fiscalizacao', name: 'Fiscalização' },
  { id: 'habitacao', name: 'Habitação' },
  { id: 'iluminacao', name: 'Iluminação' },
  { id: 'juridico', name: 'Jurídico' },
  { id: 'lombada', name: 'Lombada' },
  { id: 'obras', name: 'Obras' },
  { id: 'politica', name: 'Política' },
  { id: 'projetoDeLei', name: 'Projeto de Lei' },
  { id: 'retiradaRestos', name: 'Retirada de Restos de Vegetais/ Materiais/ Entulhos' },
  { id: 'rocada', name: 'Roçada' },
  { id: 'saude', name: 'Saúde' },
  { id: 'saudeAnimal', name: 'Saúde Animal' },
  { id: 'sanepar', name: 'Sanepar' },
  { id: 'seguranca', name: 'Segurança' },
  { id: 'tapaBuraco', name: 'Tapa Buraco' },
  { id: 'transito', name: 'Trânsito' },
  { id: 'urbanismo', name: 'Urbanismo' },
  { id: 'urbs', name: 'URBS' },
  { id: 'outrosAssuntos', name: 'Outros Assuntos' }
];



import {Component, inject, OnInit, signal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {badgeColors, NewUser, User} from '../../../models/user';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption, MatRipple} from '@angular/material/core';
import {Storage} from '@angular/fire/storage';
import {MatError, MatFormField, MatSuffix} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {AdminUpdateUser, UserService} from '../../services/user.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {SnackbarService} from '../../services/snackbar.service';
import {NgClass} from '@angular/common';
import {user} from '@angular/fire/auth';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {Platform} from '@angular/cdk/platform';
import {DUDU_DATE_FORMATS, findLabel, noWhitespaceValidator, parseFormErrors} from '../../utils';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import dayjs from 'dayjs';
import {ButtonWLoaderComponent} from '../../common/button-w-loader/button-w-loader.component';
import {DeleteConfirmationComponent} from '../../common/delete-confirmation/delete-confirmation.component';
import {AuthService} from '../../services/auth.service';
import {HighlightErrorDirective} from '../../common/error-label.directive';
import {FormFieldParserDirective} from '../../common/formFieldParser.directive';
import {GMDatePipe} from '../../common/date.pipe';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {OrganizationPositions, OrganizationRoles} from '../../../models/organizations';
import {MatTooltip} from '@angular/material/tooltip';
import {RolePositionsPipe} from '../../common/roles_and_positions.pipe';

@Component({
  selector: 'app-user-details',
  imports: [
    MatIcon,
    MatDialogContent,
    MatIconButton,
    PersonBadgeComponent,
    MatRipple,
    ReactiveFormsModule,
    MatProgressSpinner,
    MatFormField,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatInput,
    NgClass,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    ButtonWLoaderComponent,
    MatError,
    DeleteConfirmationComponent,
    HighlightErrorDirective,
    FormFieldParserDirective,
    GMDatePipe,
    MatOption,
    MatSelect,
    MatTooltip,
    RolePositionsPipe
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    }],
  templateUrl: './user-details.component.html',
  standalone: true,
  styleUrl: './user-details.component.scss'
})
export class UserDetailsComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<UserDetailsComponent>);
  usersService: UserService = inject(UserService);
  readonly snackBar = inject(SnackbarService);
  authService = inject(AuthService);
  data? = inject<{ admin: boolean, user?: User | null }>(MAT_DIALOG_DATA);
  userData?:User;
  fb = inject(FormBuilder);
  roleSelected? = 'NORMAL';
  positionSelected? = 'ASSESSOR';
  formGroup = this.fb.group({
    name: new FormControl(this.data?.user?.name, [Validators.required, noWhitespaceValidator]),
    email: new FormControl(this.data?.user?.email, [Validators.required, Validators.email]),
    phone: new FormControl(this.data?.user?.phone),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    roleControl: new FormControl<string | null>('NORMAL', [Validators.required]),
    positionControl: new FormControl<string | null>('ASSESSOR', [Validators.required]),
  })

  selectColor(color: string) {
    if(this.userData){
      this.userData.badgeColor = color;
    }
    if (this.data && this.data.user) {
      this.data.user.badgeColor = color;
      this.usersService.updateUserBadgeColor(this.data.user.id, color);
    }
  }

  // isEditing = false;
  newUser = false;
  saving = false;
  resetingPassword = signal(false);
  deletingUser = signal(false);
  isEditing = signal(false);

  dataPoints = signal([
    {
      label: 'Nome',
      id: 'name',
      editable: true,
      editing: false,
      required: true,
      control: this.formGroup.controls.name,
      validators: [Validators.required]
    },
    {
      label: 'Email',
      id: 'email',
      editable: false,
      editing: false,
      required: true,
      control: this.formGroup.controls.email,
      validators: [Validators.required, Validators.email]
    },
    {
      label: 'Telefone',
      id: 'phone',
      editable: true,
      editing: false,
      required: false,
      control: this.formGroup.controls.phone,
      validators: []
    },
  ]);

  datePicker = new FormControl<Date | null>(null)

  storage = inject(Storage);

  ngOnInit() {
    this.newUser = !this.data?.user;
    this.userData = {...this.data?.user} as User;
    if (this.newUser) {
      this.isEditing.set(true);
      this.userData =
        {
          id: '',
          name: '',
          email: '',
          phone: '',
          badgeColor: badgeColors[Math.floor(Math.random() * badgeColors.length)],
          superAdmin: false,
        }
      this.dataPoints().push(
        {
          id: 'password',
          label: 'Senha',
          editable: true,
          editing: false,
          required: true,
          control: this.formGroup.controls.password,
          validators: []
        });
    } else {
      this.formGroup.controls.password.disable();
      this.formGroup.controls.email.disable();
      if (this.authService.loggedUser()?.id === this.data?.user?.id) {
        this.formGroup.controls.roleControl.disable();
      }
      this.roleSelected = this.data?.user?.organizationRelationship?.role ?? 'NORMAL';
      this.formGroup.controls.roleControl.setValue(this.roleSelected);
      this.positionSelected = this.data?.user?.organizationRelationship?.position ?? 'ASSESSOR';
      this.formGroup.controls.positionControl.setValue(this.positionSelected);
      // if (this.data?.user?.organizationRelationship?.position === 'VEREADOR') {
      //   this.formGroup.controls.positionControl.disable();
      //   this.formGroup.controls.roleControl.disable();
      // }
    }
    if (this.data && this.data?.user?.birthday) {
      this.datePicker.setValue(dayjs.unix(this.data.user.birthday).toDate());
    }
    this.formGroup.controls.name.valueChanges.subscribe((value)=>{
      if(typeof value === 'string' && this.userData){
        this.userData.name = value;
      }
    })
  }

  close() {
    this.dialogRef.close();
  }

  deleteUser() {
    this.deletingUser.set(true);
    if (!this.data?.user) {
      this.deletingUser.set(false);
      return;
    }
    this.usersService.deleteUser(this.data?.user?.id).then(() => {
      this.deletingUser.set(false);
      this.dialogRef.close();
    });
  }

  resetPassword() {
    if (this.resetingPassword() || !this.data) return;
    this.resetingPassword.set(true);
    if (!this.data.user) {
      this.resetingPassword.set(true);
      return;
    }
    this.usersService.resetPassword(this.data.user.email).then(() => {
      this.resetingPassword.set(false);
      this.snackBar.openSnackBar('success', 'Email de redefinição de senha enviado', 'Ok');
    }).catch(() => {
      this.resetingPassword.set(false);
      this.snackBar.openSnackBar('error', 'Erro ao redefinir senha', 'Ok');
    });
  }

  save() {
    const form = this.dataPoints();
    if (this.saving) {
      return;
    }
    let invalid = false;
    for (const field of form) {
      if (field.control.invalid) {
        field.control.markAsTouched();
        invalid = true;
      }
    }
    if (invalid) return;
    this.saving = true;
    const data: NewUser = {
      name: form[0].control.value!,
      email: form[1].control.value!,
      phone: form[2].control.value!,
      password: form[3]?.control.value!,
      badgeColor: this.userData!.badgeColor! ,
      birthday: dayjs(this.datePicker.value).unix(),
      role: this.roleSelected!,
      position: this.positionSelected!,
    }
    form.forEach(field => field.control.disable())
    this.datePicker.disable();
    this.usersService.createUser(data).then(() => {
      this.saving = false;
      this.dialogRef.close();
    }).catch(() => {
      this.saving = false;
      form.forEach(field => field.control.enable())
      this.datePicker.enable();
      this.snackBar.openSnackBar('error', 'Erro ao criar usuário', 'Ok');
    });
  }

  update() {
    const form = this.dataPoints();
    if (this.saving) {
      return;
    }
    let invalid = false;
    for (const field of form) {
      if (field.control.invalid) {
        field.control.markAsTouched();
        invalid = true;
      }
    }
    if (invalid) return;
    this.saving = true;
    const data: User = {
      ...this.data,
      name: form[0].control.value!,
      email: form[1].control.value!,
      phone: form[2].control.value!,
      birthday: dayjs(this.datePicker.value).unix(),
    } as User;
    form.forEach(field => field.control.disable())
    this.datePicker.disable();
    this.usersService.updateUser(this.data?.user?.id!, data).then(() => {
      if (this.data && this.data.user) {
        this.data.user = data;
      }
      this.snackBar.openSnackBar('success', 'Usuario alterado com sucesso', 'Ok');
    }).catch(() => {
      this.snackBar.openSnackBar('error', 'Erro ao criar usuário', 'Ok');
    }).finally(() => {
      this.userData = {...this.data} as User;
      form.forEach(field => field.control.enable())
      this.datePicker.enable();
      this.saving = false;
      this.isEditing.set(false);
    });
  }

  updateAdmin() {
    const form = this.dataPoints();
    if (this.saving) {
      return;
    }
    let invalid = false;
    for (const field of form) {
      if (field.control.invalid) {
        field.control.markAsTouched();
        invalid = true;
      }
    }
    if (invalid) return;
    this.saving = true;
    const data: AdminUpdateUser = {
      name: form[0].control.value!,
      phone: form[2].control.value!,
      birthday: dayjs(this.datePicker.value).unix(),
      role: this.roleSelected ?? '',
      position: this.positionSelected ?? '',
    };
    form.forEach(field => field.control.disable())
    this.datePicker.disable();
    this.usersService.adminUpdateUser(this.data?.user?.id!, data).then(() => {
      this.snackBar.openSnackBar('success', 'Usuario alterado com sucesso', 'Ok');
    }).catch(() => {
      this.snackBar.openSnackBar('error', 'Erro ao criar usuário', 'Ok');
    }).finally(() => {
      this.userData = {...this.data} as User;
      form.forEach(field => field.control.enable())
      this.datePicker.enable();
      this.saving = false;
      this.isEditing.set(false);
    });
  }

  cancel() {
    if (this.newUser) {
      this.dialogRef.close();
    } else {
      this.formGroup.setValue({
        name: this.data?.user?.name ?? null,
        email: this.data?.user?.email ?? null,
        phone: this.data?.user?.phone ?? null,
        password: '',
        roleControl: this.data?.user?.organizationRelationship?.role ?? null,
        positionControl: this.data?.user?.organizationRelationship?.position ?? null,
      });
      if (this.data && this.data.user) {
        this.userData = this.data.user;
        this.datePicker.setValue( this.data?.user?.birthday ? dayjs.unix(this.data?.user?.birthday!).toDate() : null);
        this.isEditing.set(false);
      }
    }
  }

  updateRole(selectionChange: MatSelectChange) {
    this.roleSelected = selectionChange.value;
  }

  updatePosition(selectionChange: MatSelectChange) {
    this.positionSelected = selectionChange.value;
  }

  disablePositionSelected(id: string): string | null {
    if (id === 'VEREADOR') {
      const vereador = this.usersService.users().find(user => user.organizationRelationship?.position === 'VEREADOR');
      if (vereador) {
        return 'Não é possível adicionar mais de um(a) vereador(a) por gabinete';
      }
    } else if (id === 'CHEFE_DE_GABINETE') {
      const chiefOfStaff = this.usersService.users().find(user => user.organizationRelationship?.position === 'CHEFE_DE_GABINETE');
      if (chiefOfStaff) {
        return `Já existe um(a) Chefe de Gabinete no gabinete.`;
      }
    }
    return null;
  }

  protected readonly event = event;
  protected readonly badgeColors = badgeColors;
  protected readonly user = user;
  protected readonly parseFormErrors = parseFormErrors;
  protected readonly findLabel = findLabel;
  protected readonly OrganizationRoles = Object.values(OrganizationRoles);
  protected readonly OrganizationPositions = Object.values(OrganizationPositions);
}

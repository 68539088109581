import {Component, effect, input} from '@angular/core';
import {StatusParserPipe} from '../status-parser.pipe';
import {NgStyle} from '@angular/common';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'app-status-badge',
  imports: [
    StatusParserPipe,
    NgStyle,
    MatIcon
  ],
  templateUrl: './status-badge.component.html',
  standalone: true,
  styleUrl: './status-badge.component.scss'
})
export class StatusBadgeComponent {
  size = input<'small' | 'medium' | 'large'>('medium');
  type = input.required<'task' | 'cs' | 'la'>();
  nameId = input.required<string>();
  clickable = input(true);
  list = input(false);

  hovered = false;
}

<div style="display: flex; flex-direction: row; gap: 10px; align-items: center;">
  @switch (type()) {
    @case ('date') {
      <mat-form-field [style]="{width: formSize}">
        <mat-label>{{ formName() }}</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="control">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    }
    @case ('select') {
      <mat-form-field [style]="{width: formSize}">
        <mat-label>{{ formName() }}</mat-label>
        <mat-select [formControl]="control">
          @for (option of _options; track option) {
            <mat-option [value]="option.value">{{ option.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    @default {
      <mat-form-field subscriptSizing="dynamic" [style]="{width: formSize}">
        <mat-label>{{ formName() }}</mat-label>
        <input [type]="type()" matInput [formControl]="control" [placeholder]="_placeHolder" #input [mask]="mask()" [dropSpecialCharacters]="false">
      </mat-form-field>
    }
  }
  <div style="display: flex; flex-direction: row; gap: 5px">
    <div mat-ripple class="save-small" (click)="saveClick()">
      <mat-icon fontIcon="check"></mat-icon>
    </div>
    <div mat-ripple class="cancel-small" (click)="cancelClick()">
      <mat-icon fontIcon="close"></mat-icon>
    </div>
  </div>
</div>

import {Injectable, signal} from '@angular/core';
import {lastValueFrom, startWith} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Contact} from '../../models/contact';
import {environment} from '../../environments/environment';
import {RealtimeService} from './realtime.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  contactList = signal<Contact[]>([])

  constructor(
    private realtime: RealtimeService,
    private http: HttpClient
  ) {
    this.realtime.contacts.pipe(startWith(null))
      .subscribe(()=>{
        this.getAllContacts();
      })
  }

  async getAllContacts(): Promise<Contact[]> {
    const contacts = await lastValueFrom(this.http.get<Contact[]>(`${environment.backendUrl}/contacts`));
    this.contactList.set(contacts);
    return contacts;
  }

  async updateContactAssignee(id: string, assigneeUserId: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/assigneeUserId`, {assigneeUserId}));
    await this.getAllContacts();
  }

  async createContact(name: string, type: string, address?: string, email?: string,
                      phone?: string, birthday?: number, gender?: string, metadata?: string,
                      assigneeUserId?: string, pictureId?: string, cnpj?: string, lineOfWork?: string,
                      addressNumber?: string, addressComp?: string, city?: string, site?: string, profession?: string, delta?: any
                      ) {
    const c = await lastValueFrom(this.http.post<Contact>(`${environment.backendUrl}/contacts`, {
      name, type, bairro: address, email, phone, birthday, gender, metadata, assigneeUserId, address, pictureId,
      cnpj, lineOfWork, addressNumber, addressComp, city, site, profession, delta
    }));
    await this.getAllContacts();
    return c;
  }
  async updateContact(id: string, name: string, type: string, address?: string, email?: string,
                      phone?: string, birthday?: number, gender?: string, metadata?: string,
                      assigneeUserId?: string, pictureId?: string, cnpj?: string, lineOfWork?: string,
                      addressNumber?: string, addressComp?: string, city?: string, site?: string, profession?: string, delta?: any
  ) {
    const c = await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}`, {
      name, type, bairro: address, email, phone, birthday, gender, metadata, assigneeUserId, address, pictureId,
      cnpj, lineOfWork, addressNumber, addressComp, city, site, profession, delta
    }));
    await this.getAllContacts();
    return c;
  }

  async deleteContact(id: string) {
    await lastValueFrom(this.http.delete<{id: string}>(`${environment.backendUrl}/contacts/${id}`));
    await this.getAllContacts();
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {Tramite} from '../../models/la';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TramiteService {

  constructor(
    private http: HttpClient
  ) {}

  async getTramites(laId: string){
    const result = await lastValueFrom(this.http.get<Tramite[]>(`${environment.backendUrl}/lesgislative_actions/${laId}/tramites`));
    return result;
  }

  async post(id: string, tramite: {date: number, description: string, delta: any}) {
    const response = await lastValueFrom(this.http.post<{ id: string }>(`${environment.backendUrl}/lesgislative_actions/${id}/tramites`, tramite));
    await this.getTramites(id);
    return response;
  }

}

@if (isEditingAssignee) {
  <mat-form-field subscriptSizing="dynamic" style="width: 100%">
    <mat-select #assigneeField [value]="assignee?.id" (selectionChange)="select($event)">
      <mat-select-trigger>
        @if (assignee) {
          <span>{{ assignee.name }}</span>
        }
      </mat-select-trigger>
      @for (person of users; track person.id) {
        <mat-option [value]="person.id">
          <app-person-badge [selected]="false" [user]="person"></app-person-badge>
          <p>{{ person.name }}</p>
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
} @else {
  <span class="value" [ngClass]="{editable}" (click)="editAssignee()">
              <app-person-badge [selected]="false" [user]="assignee"></app-person-badge>
    {{ assignee?.name }}
          </span>
}

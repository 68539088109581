import {Component, effect, inject} from '@angular/core';
import {DeadlineFilterChange, DeadlineFilterComponent} from '../common/deadline-filter/deadline-filter.component';
import {ItemCounterComponent} from '../common/item-counter/item-counter.component';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatRipple} from '@angular/material/core';
import {BadgeUserData, PersonPickerComponent} from '../common/person-picker/person-picker.component';
import {User} from '../../models/user';
import {MatDialog} from '@angular/material/dialog';
import {CsTableComponent} from './cs-table/cs-table.component';
import {CS} from '../../models/cs';
import {CSService} from '../services/cs.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusGroup} from '../common/status-parser.pipe';
import {Location} from '@angular/common';
import {ContactService} from '../services/contact.service';
import {CreateCsV2Component} from './create-cs-v2/create-cs-v2.component';
import {SnackbarService} from '../services/snackbar.service';
import {MatSelectChange, MatSelectModule} from '@angular/material/select';
import {toFilter} from '../utils';

@Component({
  selector: 'app-customer-service',
  imports: [
    DeadlineFilterComponent,
    ItemCounterComponent,
    MatFormField,
    MatInput,
    MatLabel,
    MatRipple,
    PersonPickerComponent,
    CsTableComponent,
    MatSelectModule,
  ],
  templateUrl: './customer-service.component.html',
  styleUrl: './customer-service.component.scss'
})
export class CustomerServiceComponent {
  services: CS[] = [];

  statusMap = new Map<string, StatusGroup>([
    ['total', StatusGroup.total.setShowSeparator(true)],
    ['open', StatusGroup.open],
    ['inProgress', StatusGroup.inProgress],
    ['delivered', StatusGroup.delivered],
    ['denied', StatusGroup.denied],
  ]);

  filterToday: boolean = false;
  filterUntil: boolean = false;

  filterDate?: Date;
  filterValue: string = '';
  filterBairro?: string;

  csId?: string | null;
  contactId?: string

  usersToShowBadges?: User[];
  activatedRoute = inject(ActivatedRoute);
  location = inject(Location);
  contactService = inject(ContactService);
  snackService = inject(SnackbarService);
  router = inject(Router);
  bairroOptions: string[]= [];

  constructor(private csService: CSService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.contactId = params['contactId'];
      this.location.go('/atendimentos')
    });
    this.activatedRoute.paramMap.subscribe((param) => {
      this.csId = param.get('csId') ?? undefined;
      this.update();
    });
    effect(() => {
      this.bairroOptions = ['Todos'];
      this.csService.csList().forEach((cs) => {
        if(cs.bairro && !this.bairroOptions?.includes(cs.bairro)) {
          this.bairroOptions?.push(cs.bairro);
        }
        if(!cs.deletedAt){
          this.services.push(cs);
        }
      });

      if(this.contactId && this.contactService.contactList().length) {
        this.filterValue = this.contactService.contactList()
          .find((contact) => contact.id === this.contactId)?.name ?? '';
        this.searchValue = toFilter(this.filterValue);
      }
      this.update();
    });
  }

  updateBairro(event: MatSelectChange) {
    this.filterBairro = event.value;
  }

  static updateUsers(css: CS[]): User[] | undefined {
    if (css.length === 0) {
      return;
    }
    let userList: User[] | undefined;
    for (let t of css) {
      if (t.assigneeUser && (userList?.findIndex((user) => user.id === t.assigneeUser?.id) === -1 || !userList)) {
        if (!userList) {
          userList = [];
        }
        userList.push(t.assigneeUser);
      }
    }
    return userList;
  }

  async update() {
    this.services = this.csService.csList().filter((cs) => !cs.deletedAt);
    this.usersToShowBadges = CustomerServiceComponent.updateUsers(this.services);
    this.setStatusMap(this.services);
    if(this.csId && this.services.length) {
      this.openDialog(this.csId);
      this.csId = null;
    }
  }

  filteredTableData(taskData: CS[]) {
    if (taskData.length < this.services.length) {
      this.setStatusMap(taskData);
    } else {
      this.setStatusMap(this.services);
    }
  }

  setStatusMap(taskData: CS[]) {
    this.statusMap.get('total')?.setCount(taskData.length);
    this.statusMap.get('open')?.setCount(taskData.filter((task) => task.status === 'open').length);
    this.statusMap.get('inProgress')?.setCount(taskData.filter((task) => task.status === 'inProgress').length);
    this.statusMap.get('delivered')?.setCount(taskData.filter((task) => task.status === 'delivered').length);
    this.statusMap.get('denied')?.setCount(taskData.filter((task) => task.status === 'denied').length);
  }

  readonly dialog = inject(MatDialog);
  usersToFilter: User[] = []
  toBeDeleted: string = '';

  openDialog(csId?: string): void {
    const dialogRef = this.dialog.open(CreateCsV2Component, {
      minWidth: '1199px',
      maxHeight: '823px',
      data: {
        csData: csId ?  this.csService.csList().find((cs) => cs.id === csId) : undefined,
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.delete) {
        this.toBeDeleted = result.delete;
      }
      if(result && result.new){
        const snack = this.snackService.openSnackBar('success',
          'Atendimento salvo com sucesso!',
          'Clique para vê-lo'
        );
        snack.onAction().subscribe(() => {
          this.router.navigate(['atendimentos', result.new]);
        });
      }
      this.location.go('atendimentos');
      // this.update();
    });

  }

  timestampChange(event: DeadlineFilterChange) {
    this.filterToday = event.today ?? false;
    this.filterUntil = event.until ?? false;
  }

  timestampDateChange(event?: Date) {
    this.filterDate = event;
  }

  searchValue: string = '';

  newTask() {
    this.openDialog();
  }

  openDetails(taskId: string) {
    this.openDialog(taskId);
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = toFilter(filterValue);
  }
  selectedPeople: string[] = [];
  personSelected(event: BadgeUserData[]) {
    this.usersToFilter = event.filter((user) => user.selected);
    this.selectedPeople= this.usersToFilter.map((user) => user.id);
  }
}

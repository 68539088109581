import {Component, effect, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {User} from '../../../models/user';
import {MatFormField} from '@angular/material/form-field';
import {MatOption, MatSelect, MatSelectChange, MatSelectModule} from '@angular/material/select';
import {PersonBadgeComponent} from '../person-picker/person-badge/person-badge.component';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-assignee-selector',
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    PersonBadgeComponent,
    MatSelectModule,
    NgClass
  ],
  templateUrl: './assignee-selector.component.html',
  standalone: true,
  styleUrl: './assignee-selector.component.scss'
})
export class AssigneeSelectorComponent {
  isEditingAssignee = false;
  users: User[] = [];
  @Input() assignee?: User;
  @Input() editable = true;
  @Output() selectAssignee = new EventEmitter<string>();

  constructor( private userService: UserService) {
    effect(() => {
      this.users = this.userService.activeUsers();
      if (!this.assignee && this.editable) {
        this.isEditingAssignee = true;
      }
    });
  }

  select(selected: MatSelectChange){
    this.isEditingAssignee = false;
    this.selectAssignee.emit(selected.value);
  }

  @ViewChild('assigneeField') assigneeField!: MatSelect;
  editAssignee() {
    if(!this.editable){
      return
    }
    this.isEditingAssignee = true;
    setTimeout(
      () => {
        this.assigneeField.focus()
        this.assigneeField.open()
      },
      10
    );
  }
}

import {Component, effect, ElementRef, inject, signal, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {LA, themeOptions, Tramite, typeOptions} from '../../../models/la';
import {User} from '../../../models/user';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {StatusBadgeComponent} from '../../common/status-badge/status-badge.component';
import {Overlay, OverlayModule} from '@angular/cdk/overlay';
import {FileHandlerComponent} from '../../common/file-handler/file-handler.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import {MatSelect, MatSelectChange, MatSelectModule} from '@angular/material/select';
import {HistoryComponent, HistoryData} from '../../common/history/history.component';
import {DeleteConfirmationComponent} from '../../common/delete-confirmation/delete-confirmation.component';
import {EditableInputComponent} from '../../common/editable-input/editable-input.component';

import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {GMDatePipe} from '../../common/date.pipe';
import {ButtonWLoaderComponent} from '../../common/button-w-loader/button-w-loader.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {UserService} from '../../services/user.service';
import {ComponentPortal} from '@angular/cdk/portal';
import {StatusBadgeListComponent} from '../../common/status-badge-list/status-badge-list.component';
import dayjs from 'dayjs';
import {AuthService} from '../../services/auth.service';
import {DUDU_DATE_FORMATS, findLabel, noWhitespaceValidator, sanitizeDelta} from '../../utils';
import {LaService} from '../../services/la.service';
import {ulid} from 'ulid';
import {HistoryService} from '../../services/history.service';
import {Platform} from '@angular/cdk/platform';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import {FormFieldParserDirective} from '../../common/formFieldParser.directive';
import {TramiteService} from '../../services/tramite.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {SkeletonComponent} from '../../tasks/tasks/create-task/comments/skeleton/skeleton.component';
import {NgxMaskDirective} from 'ngx-mask';
import {MatTooltip} from '@angular/material/tooltip';
import {TaskService} from '../../services/task.service';
import {QuillEditorComponent, QuillViewComponent} from 'ngx-quill';
import {convertDeltaToHtml} from 'quill-converter';

export type CreateLAData = {
  laData: LA,
}

@Component({
  selector: 'app-create-la',
  imports: [
    MatFormField,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogContent,
    MatIcon,
    StatusBadgeComponent,
    OverlayModule,
    ReactiveFormsModule,
    FileHandlerComponent,
    MatOption,
    MatSelectModule,
    HistoryComponent,
    DeleteConfirmationComponent,
    EditableInputComponent,
    MatDatepickerInput,
    MatDatepicker,
    MatDatepickerToggle,
    PersonBadgeComponent,
    GMDatePipe,
    ButtonWLoaderComponent,
    MatExpansionModule,
    HistoryComponent,
    FormFieldParserDirective,
    MatProgressSpinner,
    SkeletonComponent,
    NgxMaskDirective,
    MatTooltip,
    QuillEditorComponent,
    QuillViewComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    },
  ],
  templateUrl: './create-la.component.html',
  standalone: true,
  styleUrl: './create-la.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CreateLaComponent {
  readonly dialogRef = inject(MatDialogRef<CreateLaComponent, { changed: boolean, isTemp: boolean }>);
  readonly data = inject<CreateLAData>(MAT_DIALOG_DATA);
  readonly tramiteService = inject(TramiteService);
  isNew: boolean = true;
  isEditingAssignee: boolean = false;
  isEditingNumber: boolean = false;

  laId?: string;
  laStatus: string = 'open';
  laAssignee?: User;
  laNumber?: string;
  richControl: FormControl = new FormControl([]);
  tramitesExpanded = false;
  showNewTramites = false;

  relatedTasksCount = 0;

  isEditing = signal(!this.data?.laData);

  isLoading = false;

  fb = inject(FormBuilder);
  formGroup = this.fb.group({
    type: new FormControl<string | null>(null, [Validators.required]),
    subject: new FormControl<string | null>(null, [Validators.required, noWhitespaceValidator]),
    theme: new FormControl<string | null>(null, [Validators.required]),
    date: new FormControl<Date | null>(null),
    proposition: new FormControl<string | null>(null),
  })

  tramitesDateFormControl = new FormControl<Date | null>(null, [Validators.required]);

  userService = inject(UserService);
  authService = inject(AuthService);
  laService = inject(LaService);
  historyService = inject(HistoryService);

  loadingNewTramite = false;
  tramiteList: Tramite[] = [];
  tasksService = inject(TaskService);

  constructor(
    private overlay: Overlay,
  ) {
    if (this.data.laData) {
      this.laId = this.data.laData.id;
      this.isNew = false;
      this.laNumber = this.data.laData.number;
      this.laStatus = this.data.laData.status;
      this.laAssignee = this.data.laData.assigneeUser;
      this.fillForm();
      this.tramitesDateFormControl.setValue(new Date());
      this.updateTramites();
      this.historyService.getHistoryForRelation(this.laId).then(() => {
        this.updateHistory();
      })
      setTimeout(() => {
        this.relatedTasksCount = this.tasksService.taskList().filter(task => task.relationships?.find(rel => rel.relationshipId === this.laId)).length;
      }, 200);
    } else {
      this.laId = ulid();
      this.formGroup.get('date')?.setValue(new Date());
      this.formGroup.get('proposition')?.valueChanges.subscribe((value) => {
        this.updateNumber((value ?? undefined)?.toString());
      });
    }
    effect(() => {
      this.allUsers = this.userService.activeUsers();
      if (!this.laAssignee && this.isNew && this.authService.loggedUser()) {
        this.laAssignee = this.authService.loggedUser()!;
      }
    });
  }

  async updateTramites() {
    this.tramiteList = await this.tramiteService.getTramites(this.laId!);
  }

  @ViewChild('assigneeField') assigneeField!: MatSelect;
  allUsers: User[] = []

  editAssignee() {
    this.isEditingAssignee = true;
    setTimeout(
      () => {
        this.assigneeField.focus()
        this.assigneeField.open()
      },
      10
    );
  }

  expandTramites() {
    this.tramitesExpanded = !this.tramitesExpanded;
  }

  setShowNewTramites() {
    this.showNewTramites = true;
  }

  setHideNewTramites() {
    this.showNewTramites = false;
    this.richControl.setValue([]);
    this.tramitesDateFormControl.setValue(new Date());
  }

  async saveTramite() {
    if(this.loadingNewTramite){
      return;
    }
    this.loadingNewTramite = true;
    if (!this.tramitesDateFormControl.valid) {
      return;
    }
    if (!this.tramitesDateFormControl.value || !this.richControl.value) return;

    this.tramitesExpanded = false;

    await this.tramiteService.post(this.laId!, {
      date: dayjs(this.tramitesDateFormControl.value).unix(),
      description: (this.richControl.value != null) ? convertDeltaToHtml(this.richControl.value): '',
      delta: sanitizeDelta(this.richControl.value)
    });
    await this.updateTramites();
    this.setHideNewTramites();
    this.loadingNewTramite = false;
  }

  fillForm() {
    if (!this.data) return;
    this.formGroup.setValue({
      type: this.data.laData.type,
      subject: this.data.laData.subject,
      theme: this.data.laData.theme,
      date: this.data.laData.date ? dayjs.unix(this.data.laData.date).toDate() : null,
      proposition: this.data.laData.number ?? null,
    });
  }

  addTramite() {
    this.setShowNewTramites();
  }

  @ViewChild('status') status!: ElementRef;

  showOverlay() {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.status)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: 115
      }]);
    const overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
    const ref = overlayRef.attach(new ComponentPortal(StatusBadgeListComponent));
    ref.instance.statusList.set(['open', 'inProgress', 'approved', 'sanctioned', 'rejected', 'archived']);
    ref.instance.closePanel.subscribe(async (value) => {
      overlayRef.detach();
      if (this.laStatus !== value) {
        this.laStatus = value;
        if (this.data == null) {
          return;
        }
        await this.laService.updateStatus(this.laId!, value);
        await this.historyService.getHistoryForRelation(this.laId!).then(
          () => {
            this.updateHistory();
          }
        )
      }
    });
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }

  uploadFileList?: File[];

  fileUpload(event: any) {
    const files: FileList = event.target.files;
    this.uploadFileList = Array.from(files);
  }

  updateNumber(number?: string) {
    this.isEditingNumber = false;
    this.laNumber = number;
    if (!this.isNew) {
      this.laService.upsertLa(this.laId!, {
        ...this.data.laData,
        number: this.laNumber
      })
    }
  }

  selectAssignee(selection: MatSelectChange) {
    this.isEditingAssignee = false;
    this.laAssignee = this.userService.users().find(user => user.id === selection.value);
    if (!this.isNew) {
      this.data.laData.assigneeUserId = selection.value;
      this.laService.upsertLa(this.laId!, {
        ...this.data.laData,
        assigneeUserId: selection.value,
      })
    }
  }

  deleteLA() {
    if (this.laId) {
      this.laService.delete(this.laId);
    }
    this.dialogRef.close({delete: this.laId});
  }

  close() {
    this.dialogRef.close();
  }

  historyList: HistoryData[] = [];

  updateHistory() {
    if (this.data.laData?.id == null) {
      return;
    }
    this.historyList = [];
    this.historyService.historyList().get(this.data.laData!.id!)?.forEach(value => {
      if (value != null) {
        let assigneeChange, contactChange;
        if (value.data.dataChanged === 'assigneeUserId') {
          assigneeChange = {
            oldAssignee: this.userService.users().find(user => user.id === value.data.oldData),
            newAssignee: this.userService.users().find(user => user.id === value.data.newData)
          }
        }
        this.historyList.push({
          ...value,
          user: this.userService.users().find(user => user.id === value.data.callerUserId),
          assigneeChange,
        });
      }
    });
  }

  async save() {
    if (this.isLoading) return;
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      console.log(this.formGroup);
      return;
    }

    this.isLoading = true;
    const formValue = this.formGroup.value;

    await this.laService.upsertLa(this.laId!, {
      status: this.laStatus,
      assigneeUserId: this.laAssignee?.id,
      theme: formValue.theme!,
      subject: formValue.subject!,
      type: formValue.type!,
      number: this.laNumber ?? undefined,
      date: this.formGroup.value.date ? dayjs(this.formGroup.value.date).unix() : undefined,
    }).then(() => {
      this.isNew = false;
      this.isEditingNumber = false;
      this.isEditingAssignee = false
      this.data.laData = {
        ...this.data.laData,
        ...formValue,
        status: this.laStatus,
        assigneeUserId: this.laAssignee?.id,
        date: this.formGroup.value.date ? dayjs(this.formGroup.value.date).unix() : undefined,
      } as LA;
      this.isLoading = false;
      this.isEditing.set(false);
      this.historyService.getHistoryForRelation(this.laId!).then(() => {
        this.updateHistory();
      })

    }).catch((e) => {
      console.log(e);
    })
  }

  protected readonly window = window;
  protected readonly findLabel = findLabel;
  protected readonly themeOptions = themeOptions;
  protected readonly typeOptions = typeOptions;
}

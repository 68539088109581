export const environment = {
  environment: 'staging',
  production: false,
  disableAuth: false,
  backendUrl: "https://api-staging.gpc.app.br",
  frontendUrl: "https://gm-staging.nuvex.dev",
  firebaseConfig: {
    apiKey: "AIzaSyDCSKXYva5GLfYqMtx1ss7zPNRI6eQGz30",
    authDomain: "nuvex-gm-staging.firebaseapp.com",
    projectId: "nuvex-gm-staging",
    storageBucket: "nuvex-gm-staging.firebasestorage.app",
    messagingSenderId: "766036401950",
    appId: "1:766036401950:web:2ed2fe6e1f6ed34fd0730f"
  },
};

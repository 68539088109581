import {Component, Input, input} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {MatOption} from '@angular/material/core';
import {MatBadge} from '@angular/material/badge';
import {MatSelect} from '@angular/material/select';

interface Option {
  value: string;
  label: string;
}


@Component({
  selector: 'app-select-filter',
  imports: [
    MatFormField,
    MatFormFieldModule,
    MatOption,
    ReactiveFormsModule,
    MatBadge,
    MatSelect
  ],
  templateUrl: './select-filter.component.html',
  standalone: true,
  styleUrl: './select-filter.component.scss'
})
export class SelectFilterComponent {
  @Input() title = 'Status';
  options =  input.required<Option[]>()
  @Input() control = new FormControl<string[]>([]);
}

import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[scrollToMiddleTimepicker]',
  standalone: true
})
export class ScrollToMiddleTimepickerDirective {
  @Input('scrollToMiddleTimepicker') optionIndex!: number;

  @HostListener('opened', ['$event'])
  onTimepickerOpen(isOpen: boolean): void {
    if (this.optionIndex) {
      const optionHeight = 48; // Default height of a time option (adjust if customized)
      const scrollPosition = this.optionIndex * optionHeight;

      setTimeout(() => {
        // Use querySelector to find the overlay container
        const overlayContainer = document.querySelector('.mat-timepicker-panel');
        if (overlayContainer) {
          overlayContainer.scrollTop = scrollPosition;
        }
      });
    }
  }
}

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

<!--    <ng-container matColumnDef="id">-->
<!--      <th class="id-column" mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>-->
<!--      <td class="id-column"  mat-cell *matCellDef="let row"> {{row.id}} </td>-->
<!--    </ng-container>-->

    <ng-container matColumnDef="_name">
      <th class="name-column" mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
      <td class="name-column" mat-cell *matCellDef="let row">
        <div style="display: flex; gap: 10px; align-items: center">
          <mat-icon style="font-weight: 800" [fontIcon]="row.type === 'PF' ? 'person': 'business'"></mat-icon>
          {{row.name}}
        </div>
      </td>
    </ng-container>


    <ng-container matColumnDef="_bairro">
      <th class="bairro-column" mat-header-cell *matHeaderCellDef mat-sort-header> Bairro </th>
      <td class="bairro-column" mat-cell *matCellDef="let row"> {{row.bairroEntity?.name ?? row.bairro}} </td>
    </ng-container>

    <ng-container matColumnDef="_assignee">
      <th class="assignee-column" mat-header-cell *matHeaderCellDef mat-sort-header> Responsável </th>
      <td class="assignee-column" mat-cell *matCellDef="let row">
        @if (row.assigneeUser != null) {
          <section class="assignee-cell">
            <app-person-badge [selected]="false" [user]="row.assigneeUser" [disableTooltip]="true"></app-person-badge>
            <p>{{row.assigneeUser.name}}</p>
          </section>
        } @else {
          @if(row.loadingAssignee) {
            <mat-progress-spinner [diameter]="25" class="loading-spinner" mode="indeterminate"></mat-progress-spinner>
          } @else {
            <p class="add-assignee" (click)="openDialog(row)">+ Clique para adicionar</p>
          }
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="_status">
      <th class="status-column" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td class="status-column" mat-cell *matCellDef="let row" [style.color]="row | contactCompleteColor"> {{row | contactComplete}} </td>
    </ng-container>

    <ng-container matColumnDef="open">
      <th class="open-column"  mat-header-cell *matHeaderCellDef></th>
      <td class="open-column"  mat-cell *matCellDef="let row" > <button mat-icon-button (click)="openDetails.emit(row.id)"> <mat-icon fontIcon="open_in_full"></mat-icon></button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nenhum contato encontrado</td>
    </tr>
  </table>
<!--  <div mat-row class="mat-mdc-row" style="display: none" ></div>-->
<!--  @for(a of [].constructor(extraRows()); let i =$index; track i) {-->
<!--    <div mat-row  class="mat-mdc-row"></div>-->
<!--  }-->
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Selecione a página dos contatos"></mat-paginator>
</div>


<div class="holder">
  <div class="title-holder">
    <div class="button-holder">
      <app-button-w-loader
        label="+ Novo Gabinete"
        type="yellow"
        (click)="openDialog()"
      />

    </div>
  </div>
  <app-organizations-table [searchValue]="''"></app-organizations-table>
</div>

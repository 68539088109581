import {Component, inject, OnInit} from '@angular/core';
import {OrganizationsTable} from './organizations-table/organizations-table.component';
import {OrganizationService} from '../../services/organization.service';
import {ButtonWLoaderComponent} from '../../common/button-w-loader/button-w-loader.component';

@Component({
  selector: 'app-organizations',
  imports: [
    OrganizationsTable,
    ButtonWLoaderComponent,
  ],
  templateUrl: './organizations.component.html',
  styleUrl: './organizations.component.scss'
})
export class OrganizationsComponent implements OnInit {

  organizationService = inject(OrganizationService);
  ngOnInit() {
    this.organizationService.getAllOrganizations().then(() => {});
  }

  openDialog() {

  }
}

import {User} from './user';

export type LA = {
  id: string;
  type: string;
  subject: string;
  status: string
  number?: string;
  theme: string;
  date?: number;
  createdByUserId: string;
  assigneeUserId: string | null;
  assigneeUser?: User;
  deletedAt?: number;
  createdAt?: number;
  tramites?: Tramite[];
}

export type Tramite = {
  id: string;
  description: string;
  createdByUserId: string;
  date: number;
  deletedAt: number | null;
  laId: string;
  delta: any;
}

export const typeOptions = [
  { id: 'emenda_orcamentaria', name: 'Emenda Orçamentária Aditiva – LOA' },
  { id: 'emenda_outros', name: 'Emenda (outros)' },
  { id: 'indicacao_premio', name: 'Indicação de Prêmio' },
  { id: 'indicacao_sugestao_legislativo', name: 'Indicação de Sugestão ao Poder Legislativo de Ato Administrativo ou de Gestão (204)' },
  { id: 'indicacao_sugestao_executivo', name: 'Indicação de Sugestão ao Poder Executivo (205)' },
  { id: 'projeto_lei_complementar', name: 'Projeto de Lei Complementar' },
  { id: 'projeto_lei_ordinaria', name: 'Projeto de Lei Ordinária' },
  { id: 'projeto_outros', name: 'Projeto (Outros)' },
  { id: 'requerimento_outros_orgaos', name: 'Requerimento a Outros Órgãos (043)' },
  { id: 'requerimento_prefeitura', name: 'Requerimento à Prefeitura de Curitiba (044)' },
  { id: 'requerimento_informacoes_municipio', name: 'Requerimento de Pedido de Informações Oficiais ao Município (062)' },
  { id: 'requerimento_informacoes_outros', name: 'Requerimento de Solicitação de Informações de Outros Órgãos Públicos ou Privados (074)' },
  { id: 'requerimento_congratulacoes', name: 'Requerimento de Votos de Congratulações e Aplausos (077)' },
  { id: 'requerimento_ingresso_proposicao', name: 'Requerimento de Ingresso em Autoria de Proposição (414)' },
  { id: 'requerimento_outros', name: 'Requerimento (outros)' }
];

export const themeOptions = [
  { id: 'asfalto', name: 'Asfalto' },
  { id: 'causa_animal', name: 'Causa Animal' },
  { id: 'causa_feminina', name: 'Causa Feminina' },
  { id: 'comercio', name: 'Comércio' },
  { id: 'cultura', name: 'Cultura' },
  { id: 'educacao', name: 'Educação' },
  { id: 'emenda_orcamentaria', name: 'Emenda Orçamentária' },
  { id: 'emprego', name: 'Emprego' },
  { id: 'esporte', name: 'Esporte' },
  { id: 'fiscalizacao', name: 'Fiscalização' },
  { id: 'homenagem', name: 'Homenagem' },
  { id: 'legislativo', name: 'Legislativo' },
  { id: 'meio_ambiente', name: 'Meio Ambiente' },
  { id: 'mobilidade', name: 'Mobilidade' },
  { id: 'parques_pracas', name: 'Parques e Praças' },
  { id: 'restaurantes_similares', name: 'Restaurantes e similares' },
  { id: 'saude', name: 'Saúde' },
  { id: 'seguranca', name: 'Segurança' },
  { id: 'social', name: 'Social' },
  { id: 'tecnologia', name: 'Tecnologia' },
  { id: 'transporte', name: 'Transporte' },
  { id: 'turismo', name: 'Turismo' },
  { id: 'utilidade_publica', name: 'Utilidade Pública' }
];

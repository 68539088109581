<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>


    <ng-container matColumnDef="_name">
      <th class="name-column" mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
      <td class="name-column" mat-cell *matCellDef="let row">
        <span>
          <app-person-badge [selected]="false" [user]="row" [disableTooltip]="true"></app-person-badge>
          {{row.name}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th class="email-column" mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td class="email-column"  mat-cell *matCellDef="let row"> {{row.email}} </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th class="email-column" mat-header-cell *matHeaderCellDef mat-sort-header> Atribuição </th>
      <td class="email-column"  mat-cell *matCellDef="let row"> {{row.organizationRelationship?.role | roles_positions}} </td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th class="email-column" mat-header-cell *matHeaderCellDef mat-sort-header> Cargo </th>
      <td class="email-column"  mat-cell *matCellDef="let row"> {{row.organizationRelationship?.position | roles_positions}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th class="phone-column" mat-header-cell *matHeaderCellDef mat-sort-header> Telefone </th>
      <td class="phone-column"  mat-cell *matCellDef="let row"> {{row.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="birthday">
      <th class="phone-column" mat-header-cell *matHeaderCellDef mat-sort-header> Aniversário </th>
      <td class="phone-column"  mat-cell *matCellDef="let row"> {{row.birthday | gm_date }} </td>
    </ng-container>

    <ng-container matColumnDef="open">
      <th class="open-column"  mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td class="open-column"  mat-cell *matCellDef="let row" > <button [hidden]="disableDetails()" mat-icon-button (click)="openDetails.emit(row.id)"> <mat-icon fontIcon="open_in_full"></mat-icon></button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nenhum Usuário encontrads</td>
    </tr>
  </table>

  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Selecione a página das tarefas"></mat-paginator>
</div>

<div class="header">
  <app-delete-confirmation
    [hidden]="isNew || authService.loggedUser()?.organizationRelationship?.position === 'ESTAGIARIO'"
    [deleting]="deleteLoading"
    (deleteConfirmation)="deleteContact()"/>

  @if (isNew) {
    <div class="task-name container">Adicionar Contato</div>
  } @else {
    <div class="task-name container">
      <app-title-elipisis-last-word
        [title]="formGroup.controls.nameControl.value!"
        [icon]="contactType === 'PF' ? 'person' : 'store'"
      />
    </div>
  }

  <button mat-icon-button (click)="close()">
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
  </button>
</div>

<div class="contact-modal">
  <div class="info">
    @if (!isEditing()) {
      <mat-icon id="edit-icon" class="edit-icon"
                fontIcon="edit_square" style="color:#FBC00E"
                (click)="isEditing.set(true)"></mat-icon>
    }
    <div [class]="isEditing() ? 'holder' : 'holder-2'">
      <form [formGroup]="formGroup" id="form" class="form">
        <span class="title" [highlightError]="'nameControl'">Nome:* </span>
        @if (isEditing()) {
          <mat-form-field subscriptSizing="dynamic" class="three-span">
            <input matInput formControlName="nameControl">
          </mat-form-field>
        } @else {
          <span class="value three-span">{{ formGroup.controls.nameControl.value }}</span>
        }
        @if (isNew) {
          <app-toogle-select class="toggle" [options]="['PF', 'PJ']" [selected]="contactType==='PJ'"
                             (change)="changeType($event)"/>
        }

        <span class="title first-column"  [highlightError]="'phoneControl'">Telefone:* </span>
        @if (isEditing()) {
          <mat-form-field subscriptSizing="dynamic">
            <input formMask="phone" matInput formControlName="phoneControl">
          </mat-form-field>
        } @else {
          <span class="value">{{ formGroup.controls.phoneControl.value }}</span>
        }
        @if (!isPJ()) {
          <span class="title title-space">Nascimento: </span>
          @if (isEditing()) {
            <mat-form-field>
              <input formMask="date" matInput [matDatepicker]="picker"
                     formControlName="birthdayControl">
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          } @else {
            <span class="value">{{ formGroup.controls.birthdayControl.value ? (formGroup.controls.birthdayControl.value | gm_date) : null }}</span>
          }
          <span class="title title-space">Sexo: </span>
          @if (isEditing()) {
            <mat-form-field>
              <mat-select formControlName="genderControl">
                @for (option of genderOptions; track option.id) {
                  <mat-option [value]="option.id">{{ option.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <span class="value">{{ findLabel(genderOptions, formGroup.controls.genderControl.value) }}</span>
          }
        } @else {
          <span class="title title-space">CNPJ: </span>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic">
              <input formMask="cnpj" matInput formControlName="cnpjControl">
            </mat-form-field>
          } @else {
            <span class="value">{{ formGroup.controls.cnpjControl.value }}</span>
          }
          <span class="title title-space">Ramo: </span>
          @if (isEditing()) {
            <mat-form-field>
              <mat-select formControlName="lineOfWorkControl">
                @for (option of lineOfWorkOptions; track option.id) {
                  <mat-option [value]="option.id">{{ option.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <span class="value">{{ findLabel(lineOfWorkOptions, formGroup.controls.lineOfWorkControl.value) }}</span>
          }
        }
        <span class="title">Endereço: </span>
        @if (isEditing()) {
          <mat-form-field subscriptSizing="dynamic" class=" three-span">
            <input matInput formControlName="addressControl">
          </mat-form-field>
        } @else {
          <span class="value three-span">{{ formGroup.controls.addressControl.value }}</span>
        }
        <span class="title title-space">Número: </span>
        @if (isEditing()) {
          <mat-form-field subscriptSizing="dynamic">
            <input matInput formControlName="addressNumberControl">
          </mat-form-field>
        } @else {
          <span class="value">{{ formGroup.controls.addressNumberControl.value }}</span>
        }
        <span class="title">Complem.: </span>
        @if (isEditing()) {
          <mat-form-field subscriptSizing="dynamic">
            <input matInput  formControlName="addressCompControl">
          </mat-form-field>
        } @else {
          <span class="value">{{ formGroup.controls.addressCompControl.value }}</span>
        }

        <span class="title title-space" [ngClass]="{'error-label': formGroup.controls.addressControl.value && !data.contactData?.bairro && !isEditing()}">Bairro: </span>
        <span [matTooltip]="'Será atualizado automaticamente ao atualizar o endereço'"
              class="value fixed">{{ data.contactData?.bairro ?? '--' }}</span>
        <span class="title title-space" [ngClass]="{'error-label': formGroup.controls.addressControl.value && !data.contactData?.city && !isEditing()}">Cidade: </span>
        <span [matTooltip]="'Será atualizado automaticamente ao atualizar o endereço'"
              class="value fixed">{{ data.contactData?.city ?? '--' }}</span>
        <span class="title first-column">Email: </span>
        @if (isEditing()) {
          <mat-form-field subscriptSizing="dynamic" class="three-span">
            <input matInput formControlName="emailControl">
          </mat-form-field>
        } @else {
          <span class="value three-span">{{ formGroup.controls.emailControl.value }}</span>
        }
        @if (!isPJ()) {
          <span class="title title-space">Profissão: </span>
          @if (isEditing()) {
            <mat-form-field>
              <mat-select formControlName="professionControl">
                @for (option of professionOptions; track option.id) {
                  <mat-option [value]="option.id">{{ option.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <span class="value">{{findLabel(professionOptions, formGroup.controls.professionControl.value) }}</span>
          }
        }
        @if (isPJ()) {
          <span class="title first-column">Site: </span>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="three-span">
              <input matInput formControlName="siteControl">
            </mat-form-field>
          } @else {
            <span class="value three-span">{{ formGroup.controls.siteControl.value }}</span>
          }
        }

        <span class="title first-column rich-text-label">Anotações: </span>
        @if (isEditing()) {
          <quill-editor placeholder="Escreva aqui" class="rich-text" [format]="objectType" [formControl]="richControl" (onContentChanged)="commentTextChanges($event)"></quill-editor>
        } @else {
          @if(delta) {
            <quill-view class="value description-value description-view rich-value" [content]="delta" [format]="objectType"></quill-view>
          } @else {
            <span class="value description-value" [innerHTML]="formGroup.controls['metadataControl'].value"></span>
          }
        }
      </form>
    </div>
    @if (isEditing()) {
      <div class="buttons">
        <app-button-w-loader
          label="Cancelar"
          type="outline"
          (click)="cancel()"
        />
        <app-button-w-loader
          label="Salvar"
          type="yellow"
          [loading]="loading"
          (click)="saveAll()"
        />
      </div>
    }
  </div>
  @if (!isNew && !isEditing()) {
    <div [class]="isEditing() ? 'more-data' : 'more-data more-data-margin'">
      <div class="assignee">
        <span class="title">Responsável: </span>
        <section class="assignee-cell">
          <app-assignee-selector [assignee]="contactAssignee" (selectAssignee)="selectAssignee($event)"/>
        </section>
        @if(createdByUser){
        <span class="title">Criado por: </span>
        <section class="assignee-cell">
          <app-assignee-selector [assignee]="createdByUser" [editable]="false"/>
        </section>
        }
      </div>
      <app-button-w-loader
        [matTooltip]="'Este contato não possui atendimentos.'"
        [matTooltipDisabled]="!!csCount()"
        [disabled]="!csCount()"
        padding="0 8px"
        type="outline-purple"
        [label]="'Ver atendimentos relacionados' + (csCount() ? ' (' + csCount() + ')' : '')"
        sufixIcon="open_in_new"
        (click)="openCs()"
      />
    </div>
  }
</div>

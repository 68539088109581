@if (isEditing()) {
  <mat-form-field  class="example-full-width" >
    <input matInput
           placeholder="Contato/Atendimento"
           aria-label="State"
           #input
           [matAutocomplete]="auto"
           [formControl]="control">
    <mat-icon matIconSuffix fontIcon="close" [style.color]="'#DA2727'" (click)="clear()"/>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      @for (entity of filtered | async; track entity) {
        <mat-option [value]="entity">
          <mat-icon [fontIcon]="entity.icon"/>
          <div>{{ entity.name }}</div>
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
} @else {
  @if (selectedEntity) {
    <div class="relation-div" [ngClass]="{'relation-editable': editable, medium: size === 'medium'}" (click)="edit()">
      <mat-icon class="entity-icon"
        [fontIcon]="selectedEntity.icon"></mat-icon>
      <a #textContainer [matTooltip]="(textContainer.scrollWidth > textContainer.offsetWidth) ? selectedEntity.name : '' + (selectedEntity.deleted ? 'Deletado' : '')"
         [matTooltipDisabled]="!selectedEntity.deleted && !(textContainer.scrollWidth > textContainer.offsetWidth)"
         class="name text-overflow">{{selectedEntity.name}}</a>
      @if(!selectedEntity.deleted) {
        <mat-icon class="open_new_new_icon" fontIcon="open_in_new"
                  (click)="openEntity()"
        ></mat-icon>
      }
    </div>
  } @else {
    @if (editable) {
      <span class="relation-div relation-editable" (click)="edit()">+ Clique para adicionar</span>
    } @else {
      <div class="empty">---</div>
    }
  }
}

import {ChangeDetectionStrategy, Component, effect, EventEmitter, inject, input, OnInit, output, Output, ViewChild} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {User} from '../../../../models/user';
import {PersonBadgeComponent} from '../../../common/person-picker/person-badge/person-badge.component';
import {Task} from '../../../../models/task';
import dayjs from 'dayjs';
import {StatusBadgeComponent} from '../../../common/status-badge/status-badge.component';
import {GMDatePipe} from '../../../common/date.pipe';
import {TaskRelationship} from '../../../../models/task-relationship';
import {Contact} from '../../../../models/contact';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {AssignPersonComponent} from '../../../common/assign-person/assign-person.component';
import {TaskService} from '../../../services/task.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {ContactService} from '../../../services/contact.service';
import {Router} from '@angular/router';
import {CS} from '../../../../models/cs';
import {RelateContactCsComponent} from '../../../common/relate-contact-cs/relate-contact-cs.component';
import {NgStyle} from '@angular/common';
import {toFilter} from '../../../utils';

export type TaskRelation = TaskRelationship & {
  contact?: Contact;
  cs?: CS;
}

export type TaskData = Task & {
  relationships?: TaskRelation[];
  loadingAssignee?: boolean;
  _assignee?: string;
  _relationship?: string;
  _id?: string;
  _name?: string;
  isLate?: boolean;
}

@Component({
  selector: 'app-task-table',
  styleUrl: 'task-table.component.scss',
  templateUrl: 'task-table.component.html',
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule,
    MatPaginatorModule, MatIcon, MatIconButton, PersonBadgeComponent,
    StatusBadgeComponent, GMDatePipe, MatProgressSpinner,
    RelateContactCsComponent,NgStyle
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskTable implements OnInit {
  displayedColumns: string[] = ['_id', '_name', '_relationship', '_assignee', 'status', 'deadline', 'open'];
  dataSource: MatTableDataSource<TaskData> = new MatTableDataSource([] as TaskData[]);

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  filterUsers = input<User[]>([]);

  @Output() openDetails: EventEmitter<string> = new EventEmitter();
  @Output() filteredData: EventEmitter<TaskData[]> = new EventEmitter();

  initial = true;

  newTask = output();

  toBeDeleted = input<string>();

  searchValue = input.required<string>();

  filterToday = input<boolean>(false);
  filterLate = input<boolean>(false);
  filterUntil = input<Date>();
  filterStaus = input<string[]>([]);
  filterCs = input<string|undefined>();

  taskService = inject(TaskService);
  contactService = inject(ContactService);
  router = inject(Router);

  constructor() {
    effect(() => {
      this.update();
      this.applyFilter();
    });
  }

  // ngOnInit(): void {
  //   // this.sort?.sort({id: 'id', start: "asc", disableClear: false});
  // }

  openRelation(relationshipRoute: string, newTab: boolean = false) {
    if (newTab) {
      window.open(window.location.origin + relationshipRoute, '_blank');
      return;
    }
    this.router.navigate([relationshipRoute]);
  }

  update() {
    this.dataSource.data = this.taskService.taskList().filter((value) => value.id !== this.toBeDeleted()).map((value) => {
      return this.createNewTask(value);
    });


  }

  applyFilter() {
    this.dataSource.filter =
      String(this.filterToday()) +
      String(this.filterLate()) +
      String(this.filterUntil()) +
      this.searchValue() +
      String(this.filterUsers().length);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    if (!this.initial) {
      this.filteredData.emit(this.dataSource.filteredData);
    } else {
      this.initial = false;//gambi
    }
  }

  createNewTask(task: Task): TaskData {
    const relatitionships = task.relationships?.map((relationship) => {
      return {
        ...relationship,
        contact: this.contactService.contactList().find((contact) => contact.id === relationship.relationshipId)
      }
    });
    // closedAt is the date when the task status was set to done, if it is not closed, it is the current date
    const referenceDate = task.closedAt ? dayjs.unix(task.closedAt) : dayjs();
    return {
      ...task,
      relationships: relatitionships,
      loadingAssignee: false,
      _name: toFilter(task.name),
      _id: toFilter(task.alias),
      _assignee: toFilter(task.assigneeUser?.name.toLowerCase() ?? 'Unassigned'),
      _relationship: toFilter(relatitionships?.[0]?.contact?.name.toLowerCase() ?? 'zzz'),
      isLate: task.deadline == null ? false : dayjs.unix(task.deadline).isBefore(referenceDate, 'day')
    };
  }

  readonly dialog = inject(MatDialog);

  openDialog(task: TaskData): void {
    task.loadingAssignee = true;
    const dialogRef = this.dialog.open(AssignPersonComponent, {
      width: '400px',
      height: '100px',
      data: {},
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.assignee) {
        await this.taskService.updateTaskAssignee(task.id, result.assignee);
        await this.taskService.getAllTasks();
      }
      task.loadingAssignee = false;
    });
  }

  protected readonly environment = environment;
  protected readonly window = window;

  ngOnInit(): void {
    this.dataSource.filterPredicate = ((data: TaskData): boolean => {
      let r = true;
      if (this.filterUsers().length > 0) {
        if (data.assigneeUser == null) {
          r = false;
        }
        if (data.assigneeUser?.id != this.filterUsers().find((user) => user.id === data.assigneeUser?.id)?.id) {
          r = false;
        }
      }
      if (this.filterLate() || this.filterUntil() || this.filterToday()) {
        if (data.deadline == null) {
          r = false;
        } else if (
          (!this.filterLate() || !dayjs.unix(data.deadline).isBefore(dayjs(), 'day')) &&
          (!this.filterToday() || !dayjs().isSame(dayjs.unix(data.deadline), 'day')) &&
          (!this.filterUntil() || dayjs(this.filterUntil()).diff(dayjs.unix(data.deadline), 'days') < 0)) {
          r = false;
        }
      }
      if (this.searchValue() != '') {
        if (
          data._name?.indexOf(this.searchValue()) === -1 &&
          data._id?.indexOf(this.searchValue()) === -1 &&
          data._assignee?.toLowerCase().indexOf(this.searchValue()) === -1 &&
          data._relationship?.toLowerCase().indexOf(this.searchValue()) === -1) {
          r = false;
        }
      }

      if (this.filterStaus().length > 0) {
        if (!this.filterStaus().includes(data.status)) {
          r = false;
        }
      }

      if (this.filterCs() != null) {
        if (data.relationships?.find((relationship) => relationship.relationshipId === this.filterCs()) == null) {
          r = false;
        }
      }

      return r;
    });
    setTimeout(() => {
      this.dataSource.paginator = this.paginator ?? null;
      this.dataSource.sort = this.sort ?? null;
    }, 10);

  }
}



import {Component, effect, input} from '@angular/core';
import {History} from '../../../models/history';
import {PersonBadgeComponent} from '../person-picker/person-badge/person-badge.component';
import {UlidPipe} from '../ulid.pipe';
import {GMDatePipe} from '../date.pipe';
import {StatusBadgeComponent} from '../status-badge/status-badge.component';
import {User} from '../../../models/user';
import {MatTooltip} from '@angular/material/tooltip';
import {Contact} from '../../../models/contact';
import {findLabel} from '../../utils';
import {csTypeOptions} from '../../../models/cs';
import {RelateContactCsComponent} from '../relate-contact-cs/relate-contact-cs.component';
import {themeOptions, typeOptions} from '../../../models/la';
import {QuillViewComponent} from 'ngx-quill';

export type HistoryData = History & {
  user?: User;
  assigneeChange?: {
    oldAssignee?: User;
    newAssignee?: User;
  }
  contactChange?: {
    oldContact?: Contact;
    newContact?: Contact;
  }
  data:{
    parsedOld?: string;
    parsedNew?: string;
  }
}

@Component({
  selector: 'app-history',
  imports: [
    PersonBadgeComponent,
    UlidPipe,
    GMDatePipe,
    StatusBadgeComponent,
    MatTooltip,
    RelateContactCsComponent,
    QuillViewComponent,
  ],
  templateUrl: './history.component.html',
  standalone: true,
  styleUrl: './history.component.scss'
})
export class HistoryComponent {

  history = input.required<HistoryData>();
  type?: string;
  changeType?: 'change' | 'removed' | 'added';
  oa = 'o'

  protected readonly String = String;

  normalFields: Record<string, string> = {
    name: 'Nome',
    type: 'Tipo',
    address: 'Endereço',
    // status: 'Status',
    addressNumber: 'Número',
    addressComp: 'Complemento',
    protocol: 'Protocolo',
    subject: 'Assunto',
    contactId: 'Contato',
    assigneeUserId: 'Responsável'
  }
  dateFields: Record<string, string>=  {
    deadline: 'Prazo',
    attendDate: 'Data'
  };
  timeFields: Record<string, string>=  {
    attendTime: 'Hora'
  };
  userFields: Record<string, string> = {
    assigneeUserId: 'Responsável',
    contactId: 'Contato',
  }
  entityFields: Record<string, string> = {
    'tasks': ' esta tarefa',
    'customer_services': 'este atendimento',
    'legislative_actions': 'esta ação legislativa'
  }

  constructor() {
    effect(() => {
      const {data} =  this.history();
      console.log(data);
      if(data.dataChanged === 'created'){
        data.parsedNew = this.entityFields[data.entityType];
      }
      if(data.dataChanged === 'description'){
        data.parsedOld = data.oldData !== '<p></p>' ? data.oldData?.toString().trim() ?? '' : '';
        data.parsedNew =data.newData !== '<p></p>' ? data.newData?.toString().trim() ?? '' : '';
      }
      // if(data.dataChanged === 'delta'){
      //   data.parsedOld = data.
      //   data.parsedNew = data.newData !== '<p></p>' ? data.newData?.toString().trim() ?? '' : '';
      // }
      if(data.dataChanged === 'type'){
        data.parsedOld = findLabel([...csTypeOptions, ...typeOptions], data.oldData as string) ?? '';
        data.parsedNew = findLabel([...csTypeOptions, ...typeOptions], data.newData as string) ?? '';
      }
      if(data.dataChanged === 'theme'){
        data.parsedOld = findLabel(themeOptions, data.oldData as string) ?? '';
        data.parsedNew = findLabel(themeOptions, data.newData as string) ?? '';
      }
      if(data.dataChanged === 'contactId') {
        data.parsedOld = this.history().contactChange?.oldContact?.name!
        data.parsedNew = this.history().contactChange?.newContact?.name!
      }
      if(data.dataChanged === 'assigneeUserId') {
        data.parsedOld = this.history().assigneeChange?.oldAssignee?.name!
        data.parsedNew = this.history().assigneeChange?.newAssignee?.name!
      }
      if (data.oldData && data.newData) {
        this.changeType = 'change';
      } else if (!data.oldData && data.newData) {
        this.changeType = 'added';
      } else if (data.oldData && !data.newData) {
        this.changeType = 'removed';
      }
      if(this.normalFields[data.dataChanged]) {
        this.type = 'normal';
        return;
      }
      if(this.dateFields[data.dataChanged]) {
        this.type = 'date';
        if(data.dataChanged === 'attendDate'){
          this.oa = 'a';
        }
        return;
      }
      if(this.timeFields[data.dataChanged]) {
        this.type = 'time';
        if(data.dataChanged === 'attendTime'){
          this.oa = 'a';
        }
        return;
      }

      this.type = data.dataChanged;
    });
  }
}

import {AfterViewInit, ChangeDetectionStrategy, Component, effect, EventEmitter, inject, input, OnInit, Output, ViewChild} from '@angular/core';
import {GMDatePipe} from "../../common/date.pipe";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {PersonBadgeComponent} from "../../common/person-picker/person-badge/person-badge.component";
import {StatusBadgeComponent} from "../../common/status-badge/status-badge.component";
import {User} from '../../../models/user';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import dayjs from 'dayjs';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {AssignPersonComponent} from '../../common/assign-person/assign-person.component';
import {MatDialog} from '@angular/material/dialog';
import {CSService} from '../../services/cs.service';
import {environment} from '../../../environments/environment';
import {LA, themeOptions, typeOptions} from '../../../models/la';
import {findLabel, toFilter} from '../../utils';
import {MatTooltip} from '@angular/material/tooltip';

export type LAData = LA & {
  _type?: string;
  _subject?: string;
  _theme?: string;
  _assignee?: string;
}

@Component({
  selector: 'app-la-table',
  imports: [
    MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule,
    MatPaginatorModule, MatIcon, MatIconButton, PersonBadgeComponent,
    StatusBadgeComponent, GMDatePipe, MatProgressSpinner, MatTooltip],
  templateUrl: './la-table.component.html',
  standalone: true,
  styleUrl: './la-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LATableComponent implements OnInit {
  displayedColumns: string[] = ['number', 'date', '_type', '_subject', '_theme', '_assignee', 'status', 'open'];
  dataSource: MatTableDataSource<LA> = new MatTableDataSource([] as LAData[]);

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  @Output() openDetails: EventEmitter<string> = new EventEmitter();
  @Output() filteredData: EventEmitter<LAData[]> = new EventEmitter();

  values = input.required<LA[]>();
  filterUsers = input<User[]>([]);
  toBeDeleted = input<string>();
  searchValue = input.required<string>();
  filterToday = input<boolean>(false);
  filterUntil = input<Date>();

  initial = true;

  constructor() {
    effect(() => {
      this.update();
      this.applyFilter();
    });
  }

  update() {
    this.dataSource.data = this.values().map((value) => {
      return this.creatLAData(value);
    }).filter((value) => value.id !== this.toBeDeleted());
  }

  csService = inject(CSService);
  readonly dialog = inject(MatDialog);

  openDialog(cs: LAData): void {
    const dialogRef = this.dialog.open(AssignPersonComponent, {
      width: '400px',
      height: '100px',
      data: {},
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.assignee) {
        await this.csService.updateAssignee(cs.id, result.assignee);
        await this.csService.getAllServices();
      }
    });
  }

  ngOnInit() {
    this.update();
    this.dataSource.filterPredicate = ((data: LAData): boolean => {
      let r = true;
      if (this.filterUsers().length > 0) {
        if (data.assigneeUser == null) {
          r = false;
        }
        if (data.assigneeUser?.id != this.filterUsers().find((user) => user.id === data.assigneeUser?.id)?.id) {
          r = false;
        }
      }
      if (this.filterUntil() || this.filterToday()) {
        if (data.date == null) {
          r = false;
        } else if (
          (!this.filterToday() || !dayjs().isSame(dayjs.unix(data.date), 'day')) &&
          (!this.filterUntil() || !dayjs(this.filterUntil()).isSame(dayjs.unix(data.date), 'day'))) {
          r = false;
        }
      }

      if (this.searchValue() != '') {
        if ((data.subject?.toLowerCase().indexOf(this.searchValue()) === -1) &&
          toFilter(findLabel(themeOptions, data.theme))?.toLowerCase().indexOf(this.searchValue()) === -1 &&
          toFilter(findLabel(typeOptions, data.type))?.toLowerCase().indexOf(this.searchValue()) === -1 &&
          (data.number?.toLowerCase().split('.').join('').indexOf(this.searchValue().split('.').join('')) === -1 || data.number == null)) {
          r = false;
        }
      }
      return r;
    });

    setTimeout(() => {
      this.dataSource.paginator = this.paginator ?? null;
      this.dataSource.sort = this.sort ?? null;
      this.sort?.sort({id: 'date', start: 'desc', disableClear: false});
    }, 0);
  }

  applyFilter() {
    this.dataSource.filter = String(this.filterToday()) + String(this.filterUntil()) + toFilter(this.searchValue()) + String(this.filterUsers().length);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    if (!this.initial) {
      this.filteredData.emit(this.dataSource.filteredData);
    } else {
      this.initial = false;//gambi
    }
  }

  creatLAData(la: LA): LAData {
    return {
      ...la,
      _assignee: toFilter(la.assigneeUser?.name.toLowerCase() ?? ''),
      _subject: toFilter(la.subject.toLowerCase()),
      _theme: toFilter(la.theme.toLowerCase()),
      _type: toFilter(la.type.toLowerCase()),
    };
  }

  protected readonly environment = environment;
  protected readonly window = window;
  protected readonly findLabel = findLabel;
  protected readonly themeOptions = themeOptions;
  protected readonly typeOptions = typeOptions;
}

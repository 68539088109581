  <span class="filter-title">{{ title() }}</span>
<section class="filter-container" [formGroup]="toppings">
  @if(showToday()){
    <p><mat-checkbox formControlName="today">Hoje</mat-checkbox></p>
  }
  @if (showLate()) {
    <p><mat-checkbox formControlName="late">Atrasado</mat-checkbox></p>
  }
  <p class="until"><mat-checkbox formControlName="until">{{ showUntil() ?'Até' :'' }}</mat-checkbox></p>
  <mat-form-field subscriptSizing="dynamic" class="date-filter">
    <input formMask="date" [formControl]="dateControl" matInput [matDatepicker]="picker" placeholder="-- / -- / --">
    <mat-datepicker-toggle matIconSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</section>

import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {map, merge, Observable, startWith} from 'rxjs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AsyncPipe, NgClass} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ContactService} from '../../services/contact.service';
import {CSService} from '../../services/cs.service';
import {Contact} from '../../../models/contact';
import {CS} from '../../../models/cs';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {LaService} from '../../services/la.service';

export interface RelatedEntity {
  name: string,
  icon: string,
  id: string,
  type: string,
  deleted?: boolean
}

@Component({
  selector: 'app-related-contact-cs-la',
  templateUrl: 'relate-contact-cs.component.html',
  styleUrl: 'relate-contact-cs.component.scss',
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    AsyncPipe,
    MatIcon,
    NgClass,
    MatTooltip,
  ],
  standalone: true
})
export class RelateContactCsComponent {
  @Input() selected?: string;
  @Input() size?: 'small' | 'medium' = 'small';
  @Input() editable: boolean = true;
  @Output() selectedChange = new EventEmitter<RelatedEntity|null>();

  control = new FormControl<RelatedEntity| string |null>(null);
  selectedEntity: RelatedEntity | null = null;
  entityList: RelatedEntity[] = [];
  filtered: Observable<RelatedEntity[]>;
  listUpdated = new EventEmitter();
  nameToLong = false;
  tooltipText = 'Deletado';

  isEditing = signal(false);
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  @ViewChild('textContainer', { static: false }) textContainer!: ElementRef;

  constructor(
    private contactService: ContactService,
    private cstService: CSService,
    private laService: LaService
  ) {
    this.filtered =
      merge(this.control.valueChanges,
        this.listUpdated
      ).pipe(
        startWith(''),
        map(state => {
          return (state && typeof state === 'string') ? this._filter(state) : this.entityList.slice().filter((entity) => !entity.deleted);
        }),
      );
    effect(() => {
      this.entityList = [];
      const contacts = this.contactService.contactList();
      const cs = this.cstService.csList();
      const la = this.laService.laList();
      contacts.forEach((contact: Contact) => {
        const icon = contact.type === 'PF' ? 'person' : 'business';
        this.entityList.push({name: contact.name, icon: icon, id: contact.id, type: 'contacts', deleted: !!contact.deletedAt});
      })
      cs.forEach((cs: CS) => {
        this.entityList.push({name: cs.subject, icon: 'support_agent', id: cs.id, type: 'cs', deleted: !!cs.deletedAt});
      })
      la.forEach((la) => {
        if (la.number == null || la.number == '') {
          this.entityList.push({name: la.subject, icon: 'gavel', id: la.id, type: 'la', deleted: !!la.deletedAt});
        } else {
          this.entityList.push({name: `${la.number} - ${la.subject}`, icon: 'gavel', id: la.id, type: 'la', deleted: !!la.deletedAt});
        }
      });
      this.listUpdated.emit();
      if (this.selected) {
        let entity = this.entityList.find((entity) => entity.id === this.selected) ?? null;
        this.control.setValue(entity, {emitEvent: false});
        this.selectedEntity = entity;
      }
      this.checkTextOverflow();
    })

    this.control.valueChanges.subscribe((value) => {
      if(typeof value !== 'string') {
        this.selectedEntity = value;
        this.selectedChange.emit(value);
        this.isEditing.set(false);
      }
    })
  }

  displayFn(entity: RelatedEntity){
    return entity ? entity.name : '';
  }

  clear(){
    this.control.setValue(null);
  }

  edit(){
    if(this.editable){
      this.isEditing.set(true);
      setTimeout(() => {
        this.input.nativeElement.focus();
      },100)
    }
  }

  private _filter(value: string): RelatedEntity[] {
    const filterValue = value.toLowerCase();

    return this.entityList.filter(state =>
      state.name.toLowerCase().includes(filterValue) && !state.deleted
    );
  }

  checkTextOverflow(): void {
    if(!this.textContainer || !this.selectedEntity) return;
    const element = this.textContainer.nativeElement;
    this.nameToLong = element.scrollWidth > element.offsetWidth;
    this.tooltipText = '';
    if (this.nameToLong) {
      this.tooltipText += this.selectedEntity.name
      if (this.selectedEntity.deleted) {
        this.tooltipText += ' - Deletado';
      }
    } else {
      this.tooltipText = 'Deletado';
    }
  }
  openEntity(){
    if (this.selectedEntity) {
      if (this.selectedEntity.type === 'contacts') {
        window.open('/contatos/' + this.selectedEntity.id)
      } else if (this.selectedEntity.type === 'cs') {
        window.open('/atendimentos/' + this.selectedEntity.id)
      } else if (this.selectedEntity.type === 'la') {
        window.open('/acoes-legislativas/' + this.selectedEntity.id)
      }
    }
  }
}

<div class="status-filter">
  <div style="padding-right: 13px; width: fit-content;"
       [matBadgeHidden]="!control.value?.length"
       [matBadge]="control.value?.length">
    <span class="title">{{ title }}</span></div>
  <mat-form-field subscriptSizing="dynamic" style="width: 242px;">
    <mat-select [formControl]="control" multiple>
      @for (option of options(); track option) {
        <mat-option [value]="option.value">{{ option.label }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

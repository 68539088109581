<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <th class="name-column" mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
      <td class="name-column" mat-cell *matCellDef="let row">
        <div style="display: flex; gap: 10px; align-items: center">
          {{row.name}}
        </div>
      </td>
    </ng-container>


    <ng-container matColumnDef="description">
      <th class="bairro-column" mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
      <td class="bairro-column" mat-cell *matCellDef="let row"> {{row.description}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th class="bairro-column" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
      <td class="bairro-column" mat-cell *matCellDef="let row"> {{row.type}} </td>
    </ng-container>

    <ng-container matColumnDef="domain">
      <th class="status-column" mat-header-cell *matHeaderCellDef mat-sort-header> Domínio </th>
      <td class="bairro-column" mat-cell *matCellDef="let row"> {{row.domain}} </td>
    </ng-container>

    <ng-container matColumnDef="impersonate">
      <th class="open-column"  mat-header-cell *matHeaderCellDef></th>
      <td class="open-column"  mat-cell *matCellDef="let row" > <button [matTooltip]="'Impersonate Organization'" mat-icon-button (click)="impersonateOrg(row.id)"> <mat-icon fontIcon="start"></mat-icon></button> </td>
    </ng-container>

    <ng-container matColumnDef="open">
      <th class="open-column"  mat-header-cell *matHeaderCellDef></th>
      <td class="open-column"  mat-cell *matCellDef="let row" > <button mat-icon-button (click)="openDetails.emit(row.id)"> <mat-icon fontIcon="open_in_full"></mat-icon></button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nenhum contato encontrado</td>
    </tr>
  </table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Selecione a página dos contatos"></mat-paginator>
</div>

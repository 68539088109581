<div class="task-container">
  <div class="page-header">
    <span class="page-title">Ações Legislativas</span>
  </div>
  <span class="h2" style="font-weight: bold">Filtros</span>
  <div class="filters">
    <mat-form-field subscriptSizing="dynamic" style="align-self: end;">
      <mat-label>Pesquise por assunto, tipo, tema ou protocolo</mat-label>
      <input matInput [value]="filterValue" (keyup)="search($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <app-person-picker [people]="usersToShowBadges" (onSelectionChange)="personSelected($event)"></app-person-picker>

    <app-deadline-filter [showUntil]="false"
                         [title]="'Data:'" [showLate]="false"
                         (onChange)="timestampChange($event)"
                         (onDateChange)="timestampDateChange($event)"></app-deadline-filter>
    <app-button-w-loader
      label="+ Nova proposição"
      type="yellow"
      (click)="newLA()"
    />
  </div>

  <div class="item-counter-list">
    @for (status of statusMap.keys(); track status) {
      <app-item-counter
        [title]="statusMap.get(status)?.pluralName ?? ''"
        [value]="statusMap.get(status)?.count ?? 0"
        [titleColor]="statusMap.get(status)?.color ?? ''"
        [textColor]="(statusMap.get(status)?.invertTitleColor ?? false) ? 'white' : 'black'"
      ></app-item-counter>
      @if (statusMap.get(status)?.showSeparator) {
        <div class="counter-separator"></div>
      }
    }
  </div>

  <app-la-table
    [values]="laList"
    [filterToday]="filterToday"
    [searchValue]="searchValue"
    [filterUntil]="filterUntil ? filterDate : undefined"
    [filterUsers]="usersToFilter"
    (filteredData)="filteredTableData($event)"
    (openDetails)="openDetails($event)"
  />
  </div>

import {Component, effect, inject, input, OnDestroy, OnInit, ViewChild} from '@angular/core';
// import "quill-mention/autoregister";

import {PersonBadgeComponent} from '../../../../common/person-picker/person-badge/person-badge.component';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommentService} from '../../../../services/comment.service';
import {Comment} from '../../../../../models/comment';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {GMDatePipe} from '../../../../common/date.pipe';
import {AuthService} from '../../../../services/auth.service';
import {User} from '../../../../../models/user';
import {ulid} from 'ulid';
import dayjs from 'dayjs';
import {SkeletonComponent} from './skeleton/skeleton.component';
import {RealtimeService} from '../../../../services/realtime.service';
import {SuggestionRenderer} from '../../../../common/ngx-editor-pluggins/suggestion-renderer';
import {ContentChange, QuillEditorComponent, QuillViewComponent, QuillModule} from 'ngx-quill';
import Quill from 'quill';
import {Mention, MentionBlot} from "quill-mention";

export type CommentData = Comment & {
  user?: User;
  loading: boolean;
}
Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });

class StyledMentionBlot extends (MentionBlot as any) {
  blotName: string = "styled-mention";
  static render(data: any) {
    const element = document.createElement('span');
    element.innerText = data.value;
    // element.style.backgroundColor = data.badgeColor;
    return element;
  }
}
StyledMentionBlot['blotName'] = "styled-mention";

Quill.register(StyledMentionBlot);

function createUserDisplay(name: string, backgroundColor: string): HTMLElement {
  // Create the container div
  const userDisplay = document.createElement('div');
  userDisplay.classList.add('user-display');
  userDisplay.style.display = 'flex';
  userDisplay.style.alignItems = 'center';
  userDisplay.style.fontFamily = 'Arial, sans-serif';
  userDisplay.style.padding = '7px';

  // Create the circle for initials
  const userCircle = document.createElement('div');
  userCircle.classList.add('user-circle');
  userCircle.style.display = 'flex';
  userCircle.style.justifyContent = 'center';
  userCircle.style.alignItems = 'center';
  userCircle.style.width = '40px';
  userCircle.style.height = '40px';
  userCircle.style.borderRadius = '50%';
  userCircle.style.backgroundColor = backgroundColor;
  userCircle.style.color = 'white';
  userCircle.style.fontWeight = 'bold';
  userCircle.style.fontSize = '17px';
  userCircle.style.marginRight = '10px';

  // Extract initials from the name
  const initials = name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('');
  userCircle.textContent = initials;

  // Create the name element
  const userName = document.createElement('div');
  userName.classList.add('user-name');
  userName.style.fontSize = '18px';
  userName.style.color = '#333';
  userName.textContent = name;

  // Append the circle and name to the container
  userDisplay.appendChild(userCircle);
  userDisplay.appendChild(userName);

  return userDisplay;
}

@Component({
  selector: 'app-comments',
  imports: [
    PersonBadgeComponent,
    ReactiveFormsModule,
    MatProgressSpinner,
    GMDatePipe,
    SkeletonComponent,
    FormsModule,
    QuillEditorComponent,
    QuillViewComponent,
  ],
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss'
})
export class CommentsComponent implements OnInit, OnDestroy {
  gmAuthService = inject(AuthService);

  currentUser?: User;

  html = '';
  delta: any;
  @ViewChild(QuillEditorComponent, { static: true }) quillEditor: QuillEditorComponent | undefined

  richControl: FormControl = new FormControl();

  loading = false;
  commentsService: CommentService = inject(CommentService);
  relationshipId = input.required<string>();
  relationshipType = input.required<'task' | 'cs'>();
  users = input.required<User[]>();
  comments: CommentData[] = [];
  suggestionsHandler = inject(SuggestionRenderer);

  modules: any  = null;

  constructor(
    private realtime: RealtimeService
  ) {
    effect(() => {
      this.currentUser = this.gmAuthService.loggedUser() ?? undefined;
      this.modules = {
        mention: {
          dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target', 'disabled', 'color', 'badgeColor'],
          blotName: 'styled-mention',
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            onSelect: (item: any, insertItem: any) => {
            const editor = this.quillEditor!.quillEditor
            insertItem(item)
            // necessary because quill-mention triggers changes as 'api' instead of 'user'
            editor.insertText(editor.getLength() - 1, '', 'user')
          },
            renderItem(item: any) {
            return createUserDisplay(item.value, item.badgeColor);
          },
          source: (searchTerm: any, renderList: any) => {
            const values = this.users().filter((u) => !u.deleted).map((user) => {
              return {
                id: user.id,
                value: user.name,
                badgeColor: user.badgeColor
              }
            })

            if (searchTerm.length === 0) {
              console.log(renderList);
              renderList(values, searchTerm)
            } else {
              const matches: any[] = []

              values.forEach((entry) => {
                if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(entry)
                }
              })
              renderList(matches, searchTerm)
            }
          }
        },
        toolbar: [['bold', 'italic',],[ 'underline', 'strike'], [{ 'color': [] }, { 'background': [] }]]
      };
    })
    this.realtime.comments.subscribe(() => {
      this.updateComments();
    })
  }

  ngOnInit(): void {
    this.loading = true;
    this.updateComments();
  }

  cancelComment() {
    this.richControl.setValue('');
    this.html = '';
    this.delta = null;
  }

  async saveComment() {
    const comment: CommentData = {
      id: ulid(),
      relationshipId: this.relationshipId(),
      userId: this.currentUser?.id ?? '',
      message: this.html,
      metadata: this.html,
      timestamp: dayjs().unix(),
      loading: true,
      delta: this.delta,
      user: this.currentUser
    }
    this.cancelComment();
    this.comments.unshift(comment);
    this.commentsService[this.relationshipType() === 'task' ? 'insertTaskComment' : 'insertCsComment'](this.relationshipId(), comment).then(() => {
      this.updateComments();
    });
  }

  updateComments() {
    this.commentsService[this.relationshipType() === 'task' ? 'getAllTaskComments' : 'getAllCsComments'](this.relationshipId()).then((comments) => {
      // this.quillService.
      this.comments = comments.reverse().map((comment) => {
        return {
          ...comment,
          user: this.users().find((user) => user.id === comment.userId),
          loading: false
        };
      });
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.suggestionsHandler.close()
  }

  commentTextChanges(event: ContentChange) {
    this.html = event.text ?? '';
    this.delta = event.content;
  }
}

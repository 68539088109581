<div class="task-container">
  <div class="page-header">
    <span class="page-title">Tarefas</span>
  </div>
  <span class="h2" style="font-weight: bold">Filtros</span>
  <div class="filters">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Pesquise por nome ou identificador</mat-label>
      <input [mentions]="[]" matInput (keyup)="search($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <app-person-picker [selectedPeople]="selectedPeople" [people]="usersToShowBadges" (onSelectionChange)="personSelected($event)"></app-person-picker>
    <app-deadline-filter (onChange)="deadlineChange($event)"
                         (onDateChange)="deadlineDateChange($event)"></app-deadline-filter>
    <app-select-filter
      [control]="statusFilter"
      [options]="statusOptions"
    />
    <app-button-w-loader
      type="yellow"
      label="+ Nova tarefa"
      (buttonClick)="newTask()"
    />
  </div>

  <div class="item-counter-list">
    @for (status of statusMap.keys(); track status) {
      <app-item-counter
        [title]="statusMap.get(status)?.name ?? ''"
        [value]="statusMap.get(status)?.count ?? 0"
        [titleColor]="statusMap.get(status)?.color ?? ''"
        [cardColor]="null"
        [textColor]="(statusMap.get(status)?.invertTitleColor ?? false) ? 'white' : 'black'"
      ></app-item-counter>
      @if (statusMap.get(status)?.showSeparator) {
        <div class="counter-separator"></div>
      }
    }
  </div>
  <app-task-table [toBeDeleted]="toBeDeleted" [searchValue]="searchValue" [filterCs]="csId ?? laId"
                  [filterToday]="filterToday" [filterStaus]="statusFilter.value!"
                  [filterLate]="filterLate" [filterUntil]="filterUntil ? filterDate : undefined"
                  (openDetails)="openDetails($event)" (filteredData)="filteredTableData($event)"
                  [filterUsers]="usersToFilter" (newTask)="newTask()"></app-task-table>
</div>

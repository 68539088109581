import {Bairro} from './bairro';
import {User} from './user';

export type Contact = {
  id: string;
  name: string;
  type: string;
  bairro?: string;
  bairroEntity?: Bairro;
  assigneeUser?: User;
  assigneeUserId?: string;
  createdByUserId?: string;
  shortName?: string;
  email?: string;
  phone?: string;
  birthday?: number
  gender?: string;
  pictureId?: string;
  metadata?: string;
  deletedAt?: number;
  loc?: string;
  address?: string;
  cnpj?: string;
  lineOfWork?: string;
  addressNumber?: string;
  addressComp?: string;
  city?: string;
  site?: string;
  profession?: string;
  delta?: any;
}


export const genderOptions = [
  { id: 'masculino', name: 'Masculino' },
  { id: 'feminino', name: 'Feminino' },
  { id: 'nao_informado', name: 'Prefere não informar' }
];
export const professionOptions =  [
  { id: 'administrador', name: 'Administrador' },
  { id: 'advogado', name: 'Advogado' },
  { id: 'analista_financeiro', name: 'Analista Financeiro' },
  { id: 'arquiteto', name: 'Arquiteto' },
  { id: 'assistente_social', name: 'Assistente Social' },
  { id: 'atendente', name: 'Atendente' },
  { id: 'auxiliar_administrativo', name: 'Auxiliar Administrativo' },
  { id: 'baba', name: 'Babá' },
  { id: 'biologo', name: 'Biólogo' },
  { id: 'cientista_dados', name: 'Cientista de Dados' },
  { id: 'cozinheiro', name: 'Cozinheiro' },
  { id: 'contabilista', name: 'Contabilista' },
  { id: 'costureira', name: 'Costureira' },
  { id: 'designer', name: 'Designer' },
  { id: 'desempregado', name: 'Desempregado' },
  { id: 'desenvolvedor', name: 'Desenvolvedor' },
  { id: 'eletricista', name: 'Eletricista' },
  { id: 'encanador', name: 'Encanador' },
  { id: 'enfermeiro', name: 'Enfermeiro' },
  { id: 'esteticista', name: 'Esteticista' },
  { id: 'farmaceutico', name: 'Farmacêutico' },
  { id: 'fisioterapeuta', name: 'Fisioterapeuta' },
  { id: 'frentista', name: 'Frentista' },
  { id: 'garcom', name: 'Garçom' },
  { id: 'gerente', name: 'Gerente' },
  { id: 'marceneiro', name: 'Marceneiro' },
  { id: 'mecanico', name: 'Mecânico' },
  { id: 'medico', name: 'Médico' },
  { id: 'motorista', name: 'Motorista' },
  { id: 'nutricionista', name: 'Nutricionista' },
  { id: 'operador_maquinas', name: 'Operador de Máquinas' },
  { id: 'pedreiro', name: 'Pedreiro' },
  { id: 'porteiro', name: 'Porteiro' },
  { id: 'professor', name: 'Professor' },
  { id: 'psicologo', name: 'Psicólogo' },
  { id: 'publicitario', name: 'Publicitário' },
  { id: 'quimico', name: 'Químico' },
  { id: 'recepcionista', name: 'Recepcionista' },
  { id: 'secretaria', name: 'Secretária' },
  { id: 'seguranca', name: 'Segurança' },
  { id: 'tecnico_informatica', name: 'Técnico em Informática' },
  { id: 'vendedor', name: 'Vendedor' },
  { id: 'veterinario', name: 'Veterinário' },
  { id: 'outra', name: 'Outra' }

];

export const lineOfWorkOptions = [
  { id: 'alimentacao', name: 'Alimentação' },
  { id: 'comercio', name: 'Comércio' },
  { id: 'comunicacao', name: 'Comunicação' },
  { id: 'educacao', name: 'Educação' },
  { id: 'entretenimento', name: 'Entretenimento' },
  { id: 'industria', name: 'Indústria' },
  { id: 'saude', name: 'Saúde' },
  { id: 'software', name: 'Software' },
  { id: 'transporte', name: 'Transporte' },
  { id: 'turismo', name: 'Turismo' },
  { id: 'outro', name: 'Outro' }
];

<section class="type-comment">
  <app-person-badge [user]="currentUser" [selected]="false"></app-person-badge>
  <div style="width: 100%;">
    <quill-editor [modules]="modules" placeholder="Escreva aqui" class="rich-text" [formControl]="richControl" (onContentChanged)="commentTextChanges($event)"></quill-editor>
  </div>
</section>
@if (html.length > 0) {
  <div class="buttons">
    <div type="submit" (keyup.enter)="saveComment()" class="save" (click)="saveComment()">
      <div>Salvar</div>
    </div>
    <div class="cancel" (click)="cancelComment()">
      <div>Cancelar</div>
    </div>
  </div>
}
@if (loading) {
  <app-skeleton></app-skeleton>
}
<div class="list-container" [style.max-height]="html.length > 7 ? '160px' : '230px'">
  @for (comment of comments; track comment.id) {
    <div class="list-item">
      <app-person-badge [user]="comment.user" [selected]="false"></app-person-badge>
      <div class="item-text">
        <span class="item-title">{{ comment.user?.name }}
          @if (comment.loading) {
            <mat-spinner class="date" diameter="20" color="black"></mat-spinner>
          } @else {
            <span class="date">{{ comment.timestamp | gm_date:'timestamp' }}</span>
          }
        </span>
        @if(comment.delta) {
          <quill-view class="view" format="object" [content]="comment.delta"></quill-view>
        } @else {
          <span class="item-description" [innerHTML]="comment.message"></span>
        }
      </div>
    </div>
  }
</div>

import {Component, effect, inject} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {DeadlineFilterChange, DeadlineFilterComponent} from '../common/deadline-filter/deadline-filter.component';
import {BadgeUserData, PersonPickerComponent} from '../common/person-picker/person-picker.component';
import {User} from '../../models/user';
import {StatusGroup} from '../common/status-parser.pipe';
import {ItemCounterComponent} from '../common/item-counter/item-counter.component';
import {ButtonWLoaderComponent} from '../common/button-w-loader/button-w-loader.component';
import {MatInput} from '@angular/material/input';
import {LaService} from '../services/la.service';
import {UserService} from '../services/user.service';
import {LA} from '../../models/la';
import {LATableComponent} from './la-table/la-table.component';
import {MatDialog} from '@angular/material/dialog';
import {CreateLaComponent} from './create-la/create-la.component';
import {SnackbarService} from '../services/snackbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {toFilter} from '../utils';
import {Location} from '@angular/common';

@Component({
  selector: 'app-legislative-action',
  imports: [
    MatFormField,
    PersonPickerComponent,
    DeadlineFilterComponent,
    ItemCounterComponent,
    ButtonWLoaderComponent,
    MatLabel,
    MatInput,
    LATableComponent,

  ],
  templateUrl: './legislative-action.component.html',
  standalone: true,
  styleUrl: './legislative-action.component.scss'
})
export class LegislativeActionComponent {
  filterToday: boolean = false;
  filterUntil: boolean = false;

  filterDate?: Date;
  filterValue: string = '';
  laId: string | null = null;
  usersToFilter: BadgeUserData[] = [];
  usersToShowBadges?: User[];
  laList: LA[] = [];

  statusMap = new Map<string, StatusGroup>([
    ['total', StatusGroup.total.setShowSeparator(true)],
    ['inProgress', StatusGroup.inProgress],
    ['approved', StatusGroup.approved],
    ['sanctioned', StatusGroup.sanctioned],
    ['rejected', StatusGroup.rejected],
    ['archived', StatusGroup.archived],
  ]);

  activatedRoute = inject(ActivatedRoute);
  location = inject(Location);

  constructor(
    private laService: LaService,
    private snackService: SnackbarService,
    private router: Router

  ) {
    this.activatedRoute.paramMap.subscribe((param) => {
      this.location.go('/acoes-legislativas')
      this.laId = param.get('laId') ?? null;
      this.update();
    });
    effect(() => {
      this.laList = this.laService.laList();
      this.usersToShowBadges = LegislativeActionComponent.updateUsers(this.laList);
      this.update();
    });
  }

  static updateUsers(la: LA[]): User[] | undefined {
    if (la.length === 0) {
      return;
    }
    let userList: User[] | undefined;
    for (let t of la) {
      if (t.assigneeUser && (userList?.findIndex((user) => user.id === t.assigneeUser?.id) === -1 || !userList)) {
        if (!userList) {
          userList = [];
        }
        userList.push(t.assigneeUser);
      }
    }
    return userList;
  }

  async update() {
    this.setStatusMap(this.laList);
    if(this.laId && this.laList.length) {
      this.openDialog(this.laId);
      this.laId = null;
    }
  }

  filteredTableData(laData: LA[]) {
    if (laData.length < this.laList.length) {
      this.setStatusMap(laData);
    } else {
      this.setStatusMap(this.laList);
    }
  }

  setStatusMap(laData: LA[]) {
    this.statusMap.get('total')?.setCount(laData.length);
    this.statusMap.get('inProgress')?.setCount(laData.filter((task) => task.status === 'inProgress').length);
    this.statusMap.get('approved')?.setCount(laData.filter((task) => task.status === 'approved').length);
    this.statusMap.get('sanctioned')?.setCount(laData.filter((task) => task.status === 'sanctioned').length);
    this.statusMap.get('rejected')?.setCount(laData.filter((task) => task.status === 'rejected').length);
    this.statusMap.get('archived')?.setCount(laData.filter((task) => task.status === 'archived').length);
  }

  timestampChange(event: DeadlineFilterChange) {
    this.filterToday = event.today ?? false;
    this.filterUntil = event.until ?? false;
  }

  timestampDateChange(event?: Date) {
    this.filterDate = event;
  }
  personSelected(event: BadgeUserData[]) {
    this.usersToFilter = event.filter((user) => user.selected);
  }

  searchValue: string = '';

  newLA() {
    this.openDialog();
  }

  openDetails(taskId: string) {
    this.openDialog(taskId);
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = toFilter(filterValue);
  }

  readonly dialog = inject(MatDialog);
  openDialog(laId?: string): void {
    const dialogRef = this.dialog.open(CreateLaComponent, {
      minWidth: '1199px',
      maxHeight: '823px',
      data: {
        laData: laId ?  this.laList.find((la) => la.id === laId) : undefined
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(result && result.new){
        const snack = this.snackService.openSnackBar('success',
          'Proposição salva com sucesso!',
          'Clique para vê-la'
        );
        snack.onAction().subscribe(() => {
          this.router.navigate(['acoes-legislativas', result.new]);
        });
      }
    });
  }
}

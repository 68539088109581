import {CanActivate, Routes} from '@angular/router';
import {TasksComponent} from './tasks/tasks/tasks.component';
import {ContactsComponent} from './contacts/contacts.component';
import {UsersComponent} from './users/users.component';
import {LoginComponent} from './login/login.component';
import {CalendarComponent} from './calendar/calendar.component';
import {CustomerServiceComponent} from './customer-service/customer-service.component';
import {MapComponent} from './map/map.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {AuthGuard} from '@angular/fire/auth-guard';
import {LegislativeActionComponent} from './legislative-action/legislative-action.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AdminComponent} from './admin/admin.component';
import {inject, Injectable} from '@angular/core';
import {AuthService} from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
class AuthAdminGuard implements CanActivate {
  authService = inject(AuthService);
  canActivate() {
    return this.authService.loggedUser()?.superAdmin ?? false;
  }
}

export const routes: Routes = [
  { path: 'tarefas', component: TasksComponent, canActivate: [AuthGuard]},
  { path: 'tarefas/:taskId', component: TasksComponent, canActivate: [AuthGuard]},
  { path: 'contatos', component: ContactsComponent, canActivate: [AuthGuard] },
  { path: 'contatos/:contactId', component: ContactsComponent, canActivate: [AuthGuard] },
  { path: 'usuarios', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'calendario', component: CalendarComponent, canActivate: [AuthGuard] },
  { path: 'calendario/:eventId', component: CalendarComponent, canActivate: [AuthGuard] },
  { path: 'atendimentos', component: CustomerServiceComponent, canActivate: [AuthGuard] },
  { path: 'atendimentos/:csId', component: CustomerServiceComponent, canActivate: [AuthGuard] },
  { path: 'acoes-legislativas', component: LegislativeActionComponent, canActivate: [AuthGuard] },
  { path: 'acoes-legislativas/:laId', component: LegislativeActionComponent, canActivate: [AuthGuard] },
  { path: 'mapa', component: MapComponent, canActivate: [AuthGuard] },
  { path: 'notificacoes', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard, AuthAdminGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'calendario', pathMatch: 'full' },
];

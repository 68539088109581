import {HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {Auth, idToken} from '@angular/fire/auth';
import {inject} from '@angular/core';
import {switchMap, take} from 'rxjs';
import {OrganizationService} from './services/organization.service';

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const auth = inject(Auth);
  const organizationService = inject(OrganizationService);

  return idToken(auth).pipe(
    take(1), // <-------------- Only emit the first value!

    switchMap((token: any) => {
      if (token) {
        const newReq = req.clone({
          setHeaders: {
            'selected-organization': organizationService.currentOrganizationId,
            Authorization: `Bearer ${token}`
          }
        });
        return next(newReq);
      }
      return next(req);
    })

  );
}

import {APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {getStorage, provideStorage} from '@angular/fire/storage';
import {getRemoteConfig, provideRemoteConfig} from '@angular/fire/remote-config';
import {MatIconRegistry} from '@angular/material/icon';
import {authInterceptor} from './http-interceptor';
import {environment} from '../environments/environment';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {provideQuillConfig} from 'ngx-quill';

export const appConfig: ApplicationConfig = {
  providers: [provideEnvironmentNgxMask(), provideZoneChangeDetection({eventCoalescing: true}), provideRouter(routes), provideAnimationsAsync(), provideHttpClient(
    withInterceptors([authInterceptor])
  ), provideFirebaseApp(() => initializeApp(environment.firebaseConfig)), provideAuth(() => getAuth()), provideAnalytics(() => getAnalytics()), ScreenTrackingService, UserTrackingService, provideFirestore(() => getFirestore()), provideMessaging(() => getMessaging()), provideStorage(() => getStorage()), provideRemoteConfig(() => getRemoteConfig()),
    provideQuillConfig({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic',],[ 'underline', 'strike'], [{ 'color': [] }, { 'background': [] }],  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ]
      }
    }),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (iconRegistry: MatIconRegistry) => () => {
        const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
        const outlinedFontSetClasses = defaultFontSetClasses
          .filter((fontSetClass) => fontSetClass !== 'material-icons')
          .concat(['material-symbols-outlined']);
        iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
      },
      deps: [MatIconRegistry]
    }
  ]
};

// {
//   provide: APP_INITIALIZER,
//     multi: true,
//   useFactory: (iconRegistry: MatIconRegistry) => () => {
//   const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
//   const outlinedFontSetClasses = defaultFontSetClasses
//     .filter((fontSetClass) => fontSetClass !== 'material-icons')
//     .concat(['material-symbols-outlined']);
//   iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
// },
//   deps: [MatIconRegistry]
// }

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="_id">
      <th class="id-column" mat-header-cell *matHeaderCellDef mat-sort-header> Id</th>
      <td class="id-column" mat-cell *matCellDef="let row">
        <div class="inner-element">{{ row.alias }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="_name">
      <th class="name-column" mat-header-cell *matHeaderCellDef mat-sort-header> Título da tarefa</th>
      <td class="name-column" mat-cell *matCellDef="let row">
        <div class="inner-element">{{ row.name }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="_relationship">
      <th class="relationship-column" mat-header-cell *matHeaderCellDef mat-sort-header> Relacionado com</th>
      <td class="relationship-column" mat-cell *matCellDef="let row">
        <div class="inner-element">
          <app-related-contact-cs-la style="display: block;width: 303px;" [editable]="false" [selected]="row.relationships[0]?.relationshipId"/>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="_assignee">
      <th class="assignee-column" mat-header-cell *matHeaderCellDef mat-sort-header> Responsável</th>
      <td class="assignee-column" mat-cell *matCellDef="let row">
        <div class="inner-element">
          @if (row.assigneeUser != null) {
            <section class="assignee-cell">
              <app-person-badge [selected]="false" [user]="row.assigneeUser" [disableTooltip]="true"></app-person-badge>
              <p>{{ row.assigneeUser.name }}</p>
            </section>
          } @else {
            @if (row.loadingAssignee) {
              <mat-progress-spinner [diameter]="25" class="loading-spinner"
                                    mode="indeterminate"></mat-progress-spinner>
            } @else {
              <p class="add-assignee" (click)="openDialog(row)">+ Clique para adicionar</p>
            }
          }
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th class="status-column" mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
      <td class="status-column" mat-cell *matCellDef="let row">
        <div class="inner-element">
          <app-status-badge [clickable]="false" [nameId]="row.status" [type]="'task'"></app-status-badge>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="deadline">
      <th class="deadline-column" mat-header-cell *matHeaderCellDef mat-sort-header> Prazo</th>
      <td class="deadline-column" mat-cell *matCellDef="let row">
        <div class="inner-element" [ngStyle]="{color: row.isLate ? 'var(--color-warning)' : 'unset'}">{{ row.deadline | gm_date }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="open">
      <th class="open-column" mat-header-cell *matHeaderCellDef></th>
      <td class="open-column" mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="openDetails.emit(row.id)">
          <mat-icon fontIcon="open_in_full"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nenhuma tarefa encontrada</td>
    </tr>
  </table>

  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
                 aria-label="Selecione a página das tarefas"></mat-paginator>
</div>

<script src="user-details.component.ts"></script>
<div class="header">
  <app-delete-confirmation
    [hidden]="newUser || authService.loggedUser()?.id === data?.user?.id"
    [deleting]="deletingUser()"
    (deleteConfirmation)="deleteUser()"
  />
  <span class="title">
    {{ newUser ? 'Novo Usuário' : userData?.name }}
  </span>

  <button class="close" mat-icon-button (click)="close()">
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
  </button>
  <div>
  </div>
</div>
<mat-dialog-content>

  <div class="content">
    <div class="picture">
      <app-person-badge [profilePicture]="data?.user?.picture" [size]="171" [selected]="false"
                        [user]="userData"></app-person-badge>
      <!--      @if (this.data) {-->
      <!--        <div mat-ripple class="upload" (click)="fileInput.click()">-->
      <!--          <mat-icon fontIcon="upload"></mat-icon>-->
      <!--          <div>{{ newUser ? 'Adicionar Imagem' : 'Substituir' }}</div>-->
      <!--        </div>-->
      <!--      }-->

      <!--      <input #fileInput type="file" hidden (change)="fileUpload($event)" accept=".png,.jpg,.jpeg">-->
      <div class="color-picker">
        <div class="color-grid">
          @for (color of badgeColors; track color) {
            <div class="color-holder">
              <div class="color-option" (click)="selectColor(color)"
                   [style]="{background: color, 'border-color': color}"
                   [ngClass]="{'color-selected': data?.user?.badgeColor === color}"></div>
            </div>
          }
        </div>
      </div>
    </div>

    <div class="info">
      @if (!isEditing()) {
        <mat-icon id="edit-icon" class="edit-icon"
                  fontIcon="edit_square" style="color:#FBC00E"
                  (click)="isEditing.set(true)"></mat-icon>
      }
      <form id="form" class="form" [formGroup]="formGroup">
        @for (dataPoint of dataPoints(); track dataPoint.label) {
          <div class="info-line" [style.height]="data?.user?.id ? '55px': '76px'">
            <div class="info-label" [highlightError]="dataPoint.control">{{ dataPoint.label + (dataPoint.required ? '*' : '') }}:</div>
            @if (isEditing()) {
              <div class="form-field-holder">
                <mat-form-field>
                  <input [formMask]="dataPoint.id === 'phone' ? 'phone' : undefined" matInput
                         [formControl]="dataPoint.control" #input
                         [required]="dataPoint.required">
                  @for (error of parseFormErrors(dataPoint.control.errors); track error) {
                    <mat-error>
                      {{ error }}
                    </mat-error>
                  }
                </mat-form-field>
              </div>
            } @else {
              <div class="info-value"
                   [style]="{color: dataPoint.editable ? 'black' : '#8F9295'}">{{ dataPoint?.control?.value || '--' }}
              </div>
            }
          </div>
        }
        @if (data?.admin ?? false) {
          <div class="info-line" style="height: 73px">
            <div class="info-label" [highlightError]="'roleControl'" [matTooltip]="'A atribuição define se um usuário pode criar outros usuários ou não.'">Atribuição*:</div>
            @if (isEditing()) {
              <div class="form-field-holder">
              <mat-form-field subscriptSizing="dynamic" class="roleControl">
                <mat-select [value]="roleSelected" (selectionChange)="updateRole($event)" formControlName="roleControl">
                  @for (t of OrganizationRoles; track t.id) {
                    <mat-option [value]="t.id">
                      <p>{{ t.name }}</p>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              </div>
            } @else {
              <div class="info-value"
                   [style]="{color: 'black'}">{{ formGroup.controls['roleControl'].value | roles_positions }}
              </div>
            }
          </div>
        }
        @if (data?.admin ?? false) {
          <div class="info-line" style="height: 73px">
            <div class="info-label" [highlightError]="'positionControl'" [matTooltip]="'O cargo do assessor.'">Cargo*:</div>
            @if (isEditing()) {
              <div class="form-field-holder">
              <mat-form-field subscriptSizing="dynamic" class="positionControl">
                <mat-select [value]="positionSelected" (selectionChange)="updatePosition($event)" formControlName="positionControl">
                  @for (t of OrganizationPositions; track t.id) {
                    <div [matTooltip]="disablePositionSelected(t.id)">
                      <mat-option [disabled]="disablePositionSelected(t.id) !== null" [value]="t.id">
                        <p>{{ t.name }}</p>
                      </mat-option>
                    </div>
                  }
                </mat-select>
              </mat-form-field>
              </div>
            } @else {
              <div class="info-value"
                   [style]="{color: 'black'}">{{ formGroup.controls['positionControl'].value | roles_positions }}
              </div>
            }
          </div>
        }
        <div class="info-line" style="height: 73px">
          <div class="info-label">Aniversário:</div>
          @if (isEditing()) {
            <div class="form-field-holder">
              <mat-form-field>
                <input formMask="date" matInput [matDatepicker]="picker" [formControl]="datePicker">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          } @else {
            <div class="info-value">{{ datePicker.value ? (datePicker.value! | gm_date) : '--' }}
            </div>
          }
        </div>
      </form>
      @if (!isEditing()) {
        <div mat-ripple class="reset-password" (click)="resetPassword()">
          @if (resetingPassword()) {
            <mat-spinner diameter="25" color="black"></mat-spinner>
          } @else {
            <mat-icon class="icon" fontIcon="lock_reset"></mat-icon>
          }
          <div>Resetar Senha</div>
        </div>
      }

      @if (isEditing()) {
        <div style="display: flex; flex-direction: row-reverse; margin: 20px; gap: 5px">
          <app-button-w-loader
            [disabled]="!formGroup.valid"
            [loading]="saving"
            (click)="newUser ? save() : ((data?.admin ?? false) ? updateAdmin() : update())"
            type="yellow" label="Salvar"
          />
          <app-button-w-loader
            (click)="cancel()"
            type="outline" label="Cancelar"
          />
        </div>
      }
    </div>
  </div>

</mat-dialog-content>
